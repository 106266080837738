





























































import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import gsap from 'gsap'
import { ScrollTriggerComponentMixin } from '@/mixins/ScrollTriggerComponentMixin'
import SplitText from '@/utils/SplitText'

@Component
export default class AboutBio extends mixins(ScrollTriggerComponentMixin) {
  tlCollection: gsap.core.Timeline[] = []
  scrollTriggerCollection: ScrollTrigger[] = []

  initScrollAnimation() {
    const cover: Element = this.$el.querySelector('.about-bio-cover')
    const img: Element = this.$el.querySelector('.about-bio-cover-img')
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: cover,
            scrub: 1,
            start: 'top center'
          }
        })
        .fromTo(
          img,
          {
            scale: 1.2
          },
          {
            scale: 1,
            duration: 1.25,
            ease: 'quint.out'
          }
        )
    )

    const title: Element = this.$el.querySelector('.about-bio-cover-title')
    const splitTitle = new SplitText(title, { type: 'chars' })
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: title
            // start: 'bottom center'
          }
        })
        .fromTo(
          splitTitle.chars,
          {
            opacity: 0
          },
          {
            opacity: 1,
            stagger: 0.03,
            duration: 0.85,
            snap: {
              opacity: 1
            },
            ease: 'quint.out'
          }
        )
    )

    const description: Element = this.$el.querySelector('.about-bio-cover-description')
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: description
            // start: 'bottom center'
          }
        })
        .fromTo(
          description,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.85,
            ease: 'quint.out'
          }
        )
    )

    const bioContent: Element = this.$el.querySelector('.about-bio-body-content')
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: bioContent
            // start: 'bottom center'
          }
        })
        .fromTo(
          bioContent,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.85,
            ease: 'quint.out'
          }
        )
    )

    // const aboutCTA = this.$el.querySelector('.about-cta')
    // gsap
    //   .timeline({
    //     scrollTrigger: {
    //        scroller: '#app',
    //       trigger: aboutCTA
    //       // start: 'bottom center'
    //     }
    //   })
    //   .fromTo(
    //     aboutCTA,
    //     {
    //       opacity: 0,
    //       yPercent: 100
    //     },
    //     {
    //       opacity: 1,
    //       yPercent: 0,
    //       duration: 0.85,
    //       ease: 'quint.out'
    //     }
    //   )

    const figureCollection = Array.from((<Element>this.$el).querySelectorAll('.about-bio-body-puzzle-figure'))
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: figureCollection[0]
            // start: 'bottom center'
          }
        })
        .fromTo(
          figureCollection,
          {
            yPercent: 100
          },
          {
            yPercent: 0,
            duration: 0.85,
            stagger: 0.1,
            ease: 'quint.out'
          }
        )
    )
  }
}

import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class Menu extends VuexModule {
  opened: boolean = false

  @Action
  setOpen(opened: boolean) {
    this._SET_OPEN(opened)
  }

  @Mutation
  private _SET_OPEN(opened: boolean) {
    this.opened = opened
  }
}

const menu = new Menu({ store, name: 'menu' })

export default menu
import Vue, { CreateElement, VNode } from 'vue'
import { VueConstructor } from "vue/types/umd"

/**
 * load all svg file using vue-svg-loader
 */
const _modules = require.context( '!vue-svg-loader?{"svgo": {"plugins": [{ "prefixIds": true }, { "removeViewBox": false }]}}!./', false, /.*\.svg$/i )


/**
 * load all png in folder, should return data-url if file are < 4ko
 */
const _rasters = require.context( './', false, /.*\.png$/i )


const _names: string[] = []
const _lib: Record<string, VueConstructor<Vue>> = {}

/**
 * register all svg module to lib
 */
_modules.keys().forEach( k=>{
  const id = k.substring(2, k.length-4)
  _lib[id] = _modules(k)
  _names.push(id)
})



/**
 * create <img srcset="src+' 2x"/> functional Vue component
 * @src string img src path or datauri
 */
function createImgIcon( src: string ): VueConstructor<Vue> {
  return Vue.extend({
    functional: true,
    render(h: CreateElement ): VNode {
      return h( 'img', {attrs:{srcset:src+' 2x'}} )
    }
  })
}

/**
 * register all png module to lib
 */
_rasters.keys().forEach( k=>{
  const id = k.substring(2, k.length-4)
  _lib[id] = createImgIcon(_rasters(k))
  _names.push(id)
})


/**
 * Return icon component by its name
 * @param icon 
 */
function icons( icon: string ){
  if( _lib[icon] === undefined ){
    throw `Icon "${icon}" not found`
  }
  return _lib[icon]
}


icons.names = function(){
  return _names.concat()
}


/**
 * register all icon components globally 
 * <icon-{name}/>
 */
icons.register = function(){
  for (const key in _lib) {
    Vue.component( `icon-${key}`, _lib[key] )
  }
}

export default icons;


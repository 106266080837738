








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class Wallpapers extends Vue {
  @Prop({ type: String, required: true }) slug: string
}



import GLView   from './glview'
import Scene    from './scene'
import Quality  from './lib/quality'

// import {gui} from './dev'
// console.log( gui );


// TODO: move this somwhere else
import { TextureCodecs } from './assets/TextureCodec'
import ResourceGroup from './assets/ResourceGroup'
import TextureCodecStd, { TextureCodecWebp } from './assets/TextureCodecStd'

import { TextureCodecDxt, TextureCodecEtc, TextureCodecPvr } from './assets/TextureCodecBBC'

TextureCodecs.registerCodec( new TextureCodecStd() );
TextureCodecs.registerCodec( new TextureCodecWebp() );
TextureCodecs.registerCodec( new TextureCodecDxt() );
TextureCodecs.registerCodec( new TextureCodecEtc() );
TextureCodecs.registerCodec( new TextureCodecPvr() );


export default class App{
  
  private _loadPromise: Promise<void> = null

  private static _instance: App = null
  static getInstance(): App {
    if( this._instance === null ){
      this._instance = new App()
    }
    return this._instance
  }


  glview: GLView
  scene: Scene

  readonly canvas: HTMLCanvasElement

  constructor(){
                  
    this.canvas = document.createElement( 'canvas' )

    this.glview   = new GLView( this.canvas )
    this.scene    = new Scene()

    this.scene.ilayer = this.canvas

    Quality.setMode( 'high' )

    this.glview.scene = this.scene
    this.scene.init( this.glview )

  }



  /**
    start loading assets
    @return a (when.js) promise 
  **/
  load() : Promise<void> {
    if( this._loadPromise === null ){
      this._loadPromise = this._doLoad()
    }
    return this._loadPromise
  }

  async _doLoad(){
    await this.scene.load();
  }
  
  start(){
    this.glview.play();
  }
  
  stop(){
    this.glview.stop();
  }



  setCurrentArtwork(currentArtwork: number) {
    this.scene.gallery.povs.activateFrame( currentArtwork )
  }



}



/** @typedef {import('gl/ibl').default} Ibl */

import Input              from 'nanogl-pbr/Input'
import Flag               from 'nanogl-pbr/Flag'
import Enum               from 'nanogl-pbr/Enum'
import Precision          from 'nanogl-pbr/ShaderPrecision'
import Version            from 'nanogl-pbr/ShaderVersion'
import { GammaModes, GammaModeEnum } from 'nanogl-pbr/GammaModeEnum'
import mat4               from 'gl-matrix/src/gl-matrix/mat4'
import vShader            from './shader.vert'
import fShader            from './shader.frag'
import MaterialPass from 'nanogl-pbr/MaterialPass'
import Program from 'nanogl/program'
import Camera from 'nanogl-camera'
import Node from 'nanogl-node'

const M4 = mat4.create();

const MAT_ID = 'unlit';

/**
 * @extends {BaseMaterial}
 */
export default class UnlitPass extends MaterialPass {
  
  
  version: Version
  precision: Precision
  shaderid: Flag
  iTexCoord0: any
  iColor: Input
  iAlpha: Input
  iAlphaFactor: Input
  iGamma: Input
  iExposure: Input
  alphaBlending: Enum<readonly ["ALPHA_CUTOUT", "ALPHA_STD"]>
  gammaMode: GammaModeEnum;
  _vertSrc: string
  _fragSrc: string
  prg: any

  constructor(){

    super( {
      uid  : MAT_ID,
      vert : vShader(),
      frag : fShader(),
    } );


    const inputs = this.inputs;

    inputs.add( this.version         = new Version( '100' ) );
    inputs.add( this.precision       = new Precision( 'highp' ) );
    inputs.add( this.shaderid        = new Flag ( 'id_'+MAT_ID,  true  ) );


    inputs.add( this.iColor       = new Input( 'color'      , 3 ) );
    inputs.add( this.iAlpha       = new Input( 'alpha'      , 1 ) );
    inputs.add( this.iAlphaFactor = new Input( 'alphaFactor', 1 ) );


    inputs.add( this.alphaBlending      = new Enum( 'alphaBlending', [
      'ALPHA_CUTOUT',
      'ALPHA_STD'
    ] as const ));
    
    
    inputs.add( this.iGamma          = new Input( 'gamma',           1 ) );
    inputs.add( this.iExposure       = new Input( 'exposure',        1 ) );
    inputs.add( this.gammaMode       = new Enum( 'gammaMode', GammaModes ));


    this._vertSrc = vShader();
    this._fragSrc = fShader();


/////////////////
/////////////////////////
//////////////

  }

  
  prepare( prg:Program, node : Node, camera : Camera ){

    // matrices
    
    if( prg.uMVP ){
      camera.modelViewProjectionMatrix( M4, node._wmatrix );
      prg.uMVP(          M4            );
    }
    
    if( prg.uWorldMatrix )
      prg.uWorldMatrix( node._wmatrix );
    
    if( prg.uVP )
      prg.uVP( camera._viewProj );
    
  

  }


};






/////////////

/////////////////////////////////////////////

////////////////////////////

/////////////////
/////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
 

//////////



















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import gsap from 'gsap'
import SplitText from '@/utils/SplitText'

import menu from '@/store/modules/menu'

import BrushSprites from '@/components/BrushSprites/BrushSprites.vue'

@Component({
  components: {
    BrushSprites
  }
})
export default class AppNav extends Vue {
  navLinks: Element[] = []
  dividers: Element[] = []
  navLinksTL: gsap.core.Timeline[] = []

  get showCookieSettings() {
    return process.env.VUE_APP_ENVCONFIG !== 'development'
  }

  @Watch('$route')
  watchRoute() {
    this.close()
  }

  mounted() {
    this.initNavLinks()
    // this.addEvents()
  }

  beforeDestroy() {
    // this.removeEvents()
  }

  initNavLinks() {
    const split = new SplitText(Array.from((<Element>this.$refs.primaryNav).querySelectorAll('.menu-link-label')), {
      type: 'chars',
      charsClass: 'char'
    })
    this.dividers = Array.from((<Element>this.$refs.primaryNav).querySelectorAll('.menu-item-divider'))
    this.navLinks = Array.from((<Element>this.$refs.primaryNav).querySelectorAll('.menu-link'), (link) => {
      const chars = Array.from((<Element>link).querySelectorAll('.char'))
      const threshold: number = chars.length / 2
      const tl: gsap.core.Timeline = gsap.timeline()
      tl.addLabel('start')
      tl.fromTo(
        chars,
        { x: 0 },
        {
          x: (index, target) => {
            return (index - threshold) * 2.5
          },
          duration: 1,
          ease: 'quint.inOut'
        },
        'start'
      )
      tl.pause(0)
      this.navLinksTL.push(tl)
      return link
    })
  }

  // addEvents() {
  //   this.navLinks.forEach((link, i) => {
  //     link.addEventListener('mouseenter', this.onNavLinkEnter)
  //     link.addEventListener('mouseleave', this.onNavLinkLeave)
  //   })
  // }

  // removeEvents() {
  //   this.navLinks.forEach((link) => {
  //     link.removeEventListener('mouseenter', this.onNavLinkEnter)
  //     link.removeEventListener('mouseleave', this.onNavLinkLeave)
  //   })
  // }

  onNavLinkEnter(index: number) {
    this.navLinksTL[index].play()
    const borders: Element[] = [this.dividers[index], this.dividers[index + 1]]
    const tl: gsap.core.Timeline = gsap.timeline()
    tl.addLabel('start')
    tl.to(
      borders,
      {
        rotate: (idx: number) => {
          const angle: number = gsap.utils.random(1, 3)
          return `${index % 2 === 0 ? (idx % 2 === 0 ? -angle : angle) : angle}deg`
        },
        yPercent: (idx: number) => {
          return idx % 2 === 0 ? -100 : 100
        },
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
  }

  onNavLinkLeave(index: number) {
    this.navLinksTL[index].reverse()
    const tl: gsap.core.Timeline = gsap.timeline()
    const borders: Element[] = [this.dividers[index], this.dividers[index + 1]]
    tl.addLabel('start')
    tl.to(
      borders,
      {
        rotate: 0,
        yPercent: 0,
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
  }

  enter(el: Element, onComplete: gsap.Callback) {
    const chars = Array.from((<Element>this.$refs.primaryNav).querySelectorAll('.char'))
    const tl: gsap.core.Timeline = gsap.timeline({
      autoRemoveChildren: true,
      onComplete
    })
    gsap.set(this.$refs.inner, { clearProps: 'opacity' })
    gsap.set(this.$refs.backdrop, { clearProps: 'opacity' })
    tl.add((this.$refs.background as BrushSprites).enter(), 0)
    tl.from(
      this.$refs.backdrop,
      {
        opacity: 0,
        duration: 1,
        ease: 'linear'
      },
      0
    )
    tl.addLabel('start')
    tl.from(
      (<Vue>this.$refs.btnClose).$el,
      {
        scale: 0,
        rotate: '-10deg',
        duration: 0.45,
        ease: 'back.out'
      },
      'start'
    )
    tl.from(
      this.dividers,
      {
        xPercent: (index: number) => {
          return index % 2 === 0 ? -200 : 200
        },
        stagger: 0.1,
        ease: 'quint.out'
      },
      'start'
    )
    tl.from(
      chars,
      {
        y: 20,
        opacity: 0,
        stagger: {
          each: 0.03,
          from: 'start',
          grid: 'auto'
        },
        duration: 0.85,
        snap: {
          opacity: 1
        }
      },
      'start'
    )
    tl.from(
      [this.$refs.brandLogoContainer, this.$refs.title],
      {
        yPercent: -100,
        opacity: 0,
        duration: 0.85,
        stagger: 0.2,
        ease: 'quint.out'
      },
      'start'
    )
    tl.from(
      (this.$refs.footer as Element).children,
      {
        opacity: 0,
        duration: 0.65,
        stagger: 0.2,
        ease: 'quint.out'
      },
      '>-0.65'
    )
  }

  leave(el: Element, onComplete: gsap.Callback) {
    const tl: gsap.core.Timeline = gsap.timeline({
      autoRemoveChildren: true,
      onComplete
    })
    tl.to(this.$refs.inner, { opacity: 0, duration: 0.45 })
    // tl.to(this.$refs.background, { transformOrigin: '0% 50%', scaleX: 0, duration: 0.85, ease: 'quint.inOut' }, '-=0.2')
    tl.addLabel('start')
    tl.add((this.$refs.background as BrushSprites).leave(), 'start')
    tl.to(
      this.$refs.backdrop,
      {
        opacity: 0,
        duration: 1,
        ease: 'linear'
      },
      'start'
    )
  }

  close() {
    menu.setOpen(false)
  }
}

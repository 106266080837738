























































import Component, { mixins } from 'vue-class-component'
import gsap from 'gsap'
import SplitText from '@/utils/SplitText'

import { ScrollTriggerComponentMixin } from '@/mixins/ScrollTriggerComponentMixin'
import Paths from '@/core/Paths'

import agenda, { AgendaEvent } from '@/store/modules/agenda'
import transition, { TRANSITION_DIRECTION } from '@/store/modules/transition'
import menu from '@/store/modules/menu'

@Component({})
export default class Agenda extends mixins(ScrollTriggerComponentMixin) {
  tlCollection: gsap.core.Timeline[] = []
  scrollTriggerCollection: ScrollTrigger[] = []

  get agenda(): AgendaEvent[] {
    return agenda.events
  }

  mounted() {
    this.$nextTick(this.initScrollAnimation)
  }

  resolve(path): string {
    return Paths.resolve(path)
  }

  initScrollAnimation() {
    const eventCollection = (<Element>this.$el).querySelector('.agenda-event-collection')
    const backgroundLogo = (<Element>this.$el).querySelector('.agenda-background-logo')
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: eventCollection,
            scrub: true
          }
        })
        .addLabel('start')
        .to(backgroundLogo, {
          y: () => backgroundLogo.clientHeight - eventCollection.clientHeight
        })
    )
  }

  beforeRouteEnter(to, from, next) {
    if (menu.opened) {
      return next()
    }

    transition.setOpen({ opened: true, options: { direction: TRANSITION_DIRECTION.HORIZONTAL } })
    gsap.delayedCall(1, next)
  }

  enter(el: Element, onComplete) {
    const title: Element = el.querySelector('.agenda-header-title')
    const splitTitle = new SplitText(title, { type: 'lines, words, chars' })
    const logo: Element = el.querySelector('.agenda-logo')
    const description: Element = el.querySelector('.agenda-header-description')
    const cover: Element = el.querySelector('.agenda-header-cover')
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.add(() => {
      transition.setOpen({ opened: false, options: { showBackdrop: true } })
    })
    tl.addLabel('start', '+=1')
    tl.from(el, { opacity: 0, duration: 1.2, ease: 'quint.out' })
    tl.fromTo(
      splitTitle.chars,
      {
        opacity: 0
      },
      {
        opacity: 1,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start'
    )
    tl.from(
      [logo, cover, description],
      {
        opacity: 0,
        yPercent: 100,
        duration: 0.85,
        stagger: 0.1,
        ease: 'quint.out'
      },
      'start+=0.2'
    )
  }

  afterLeave() {
    (<Element>this.$root.$el).scrollTo(0, 0)
  }
}

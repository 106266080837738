import ResourceGroup from '@/webgl/assets/ResourceGroup'
import { CATCH_ASYNC } from '@/core/Async'
import Store from '@/store'
import { ImageResource, FetchResource, JsonResource, BytesResource } from '@/webgl/assets/Net'
// import { FontResource } from '@/webgl/assets/FontResource'
// import { AudioResource } from '@/core/audio/AudioResource'
// import AudioConfig from '@/core/audio/AudioConfig'
// import { AUDIO_ID } from '@/store/AudioId'
// import AudioModule from '@/core/audio/AudioModule'
// import AudioManager from '@/core/AudioManager'

import Paths from '@/core/Paths'

import { TRANSITION_DIRECTION } from '@/store/modules/transition'
import viewport from '@/store/modules/viewport'
import { Resource } from '@/webgl/assets/Resource'

export default class FrontResources {
  static Group: ResourceGroup = new ResourceGroup()

  static setupVideos() {
    const G = this.Group

    const ratio: number = viewport.windowHeight / viewport.windowWidth
    if (ratio <= 1) {
      // is viewport landscape
      G.add(<Resource>new BytesResource(Paths.resolve('./assets/videos/intro-desktop.mp4')))
    } else {
      G.add(<Resource>new BytesResource(Paths.resolve('./assets/videos/intro-mobile.mp4')))
    }
  }

  static setupLotties() {
    const G = this.Group

    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/enter-here.json')), 'enter-here')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/icon-enable-sound.json')), 'icon-enable-sound')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/icon-pano.json')), 'icon-pano')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/icon-see-more.json')), 'icon-see-more')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/icon-sound-toggle.json')), 'icon-sound-toggle')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/icon-wallpapers.json')), 'icon-wallpapers')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/icon-zoom.json')), 'icon-zoom')
    G.add(<Resource>new JsonResource(Paths.resolve('./assets/lottie/loading-complete.json')), 'loading-complete')
    G.add(
      <Resource>new JsonResource(Paths.resolve('./assets/lottie/tutorial-swipe-to-move.json')),
      'tutorial-swipe-to-move'
    )
    G.add(
      <Resource>new JsonResource(Paths.resolve('./assets/lottie/tutorial-tap-to-discover.json')),
      'tutorial-tap-to-discover'
    )
  }

  static setupImages() {
    const G = this.Group

    // load brush sprites
    const spritesFrames: number = 12
    const spritesPath: string = './assets/sprites/transition-%DIRECTION%/hd/frame-%INDEX%.png'
    for (let i = 0; i < spritesFrames; i++) {
      const file = spritesPath
        .replace(/%DIRECTION%/g, TRANSITION_DIRECTION.VERTICAL)
        .replace(/%INDEX%/g, i.toString().padStart(5, '0000'))
      const imageResource = new ImageResource(Paths.resolve(file))
      G.add(<Resource>imageResource)
    }
    for (let i = 0; i < spritesFrames; i++) {
      const file = spritesPath
        .replace(/%DIRECTION%/g, TRANSITION_DIRECTION.HORIZONTAL)
        .replace(/%INDEX%/g, i.toString().padStart(5, '0000'))
      const imageResource = new ImageResource(Paths.resolve(file))
      G.add(<Resource>imageResource)
    }

    // load other public assets
    G.add(<Resource>new ImageResource(Paths.resolve('./assets/videos/secret-film.png')))
    G.add(<Resource>new ImageResource(Paths.resolve('./assets/videos/secret-film@2x.png')))

    // load hashed assets
    G.add(<Resource>new ImageResource(require('@/assets/images/collab-logo.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/collab-logo@2x.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/line-1.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/secret-film.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/secret-film@2x.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/wallpapers/postcard.jpg')))
    G.add(<Resource>new ImageResource(require('@/assets/images/wallpapers/postcard@2x.jpg')))
    G.add(<Resource>new ImageResource(require('@/assets/images/stamp.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/stamp@2x.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/wallpapers/unconventional-gallery.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/wallpapers/unconventional-gallery@2x.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/agenda/header-background.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/logo-ruinart-art.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/logo-ruinart-art@2x.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/curved-arrow.png')))
    G.add(<Resource>new ImageResource(require('@/assets/images/curved-arrow@2x.png')))
  }

  static setupFonts() {
    const G = this.Group

    // G.add(new FontResource(
    //   "KlarnaDisplay-Bold",
    //   `url('${require('@/assets/fonts/KlarnaDisplay-Bold/KlarnaDisplay-Bold.woff')}') format('woff'),
    //   url('${require('@/assets/fonts/KlarnaDisplay-Bold/KlarnaDisplay-Bold.woff2')}') format('woff2'),
    //   url('${require('@/assets/fonts/KlarnaDisplay-Bold/KlarnaDisplay-Bold.ttf')}') format('truetype')`,
    //   {
    //     weight: "bold",
    //     style: "normal"
    //   },
    // ));
  }

  // static setupAudio(manager: AudioManager) {
  //   const G = manager.group
  //   const module = manager.module
  //   // TODO
  //   G.add(new AudioResource(AUDIO_ID.LOOP, module), AUDIO_ID.LOOP)
  //   G.add(new AudioResource(AUDIO_ID.COMPLETE, module), AUDIO_ID.COMPLETE)
  //   G.add(new AudioResource(AUDIO_ID.NO, module), AUDIO_ID.NO)
  //   G.add(new AudioResource(AUDIO_ID.YES, module), AUDIO_ID.YES)
  // }

  static load() {
    CATCH_ASYNC(
      this.Group.load().then(() => {
        // console.log('loaded')
        //   Store.notifyFrontLoaded();
      })
    )
  }
}












import Vue from 'vue'
import Component from 'vue-class-component';

import GLApp from "@/webgl/main";
import { Watch } from 'vue-property-decorator';
import gallery from '@/store/modules/gallery';

@Component
export default class GLGallery extends Vue {

  loaded = false
  isdestroyed:boolean

  viewer: GLApp

  get cursor(){
    return gallery.cursor
  }


  mounted(): void {
    this.isdestroyed = false
    this.viewer = GLApp.getInstance()
    this.viewer.load().then( this.onLoaded );

    this.$el.appendChild( this.viewer.canvas )
  }

  destroyed(){
    this.isdestroyed = true
    this.viewer.stop()
    // console.log('.stop()');
    
  }
  


  onLoaded(): void {
    if( this.isdestroyed ) return
    this.viewer.start()
    // console.log('.start()');
    
    this.loaded = true;
    this.onArtworkChange()
  }

  get currentArtwork(): number {
    return gallery.artworkIndex
  }

  @Watch( 'currentArtwork' )
  onArtworkChange(){
    if( !this.loaded )return
    this.viewer.setCurrentArtwork(this.currentArtwork)
  }
}












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import gsap from 'gsap'

import Paths from '@/core/Paths'

import gallery from '@/store/modules/gallery'
import transition from '@/store/modules/transition'
import sound from '@/store/modules/sound'

type IPlayerVars = {
  autoplay?: boolean
  controls?: boolean
  rel?: boolean
  showinfo?: boolean
  modestbranding?: number
  iv_load_policy?: number
}

@Component
export default class GalleryOutro extends Vue {
  player: any = null
  playerVars: IPlayerVars = {
    autoplay: false,
    controls: false,
    rel: false,
    showinfo: false,
    modestbranding: 1,
    iv_load_policy: 3
  }
  isPlaying: boolean = false
  hasPlayOnce: boolean = false
  videoId: string = '9wk-blLlJ-A' // https://www.youtube.com/embed/9wk-blLlJ-A?controls=0
  showVideo: boolean = false

  @Watch('isPlaying')
  watchShowVideo(isPlaying: boolean) {
    if (isPlaying) {
      sound.mute()
    }
  }

  get shareURL() {
    return document.location.href || ''
  }

  resolve(path): string {
    return Paths.resolve(path)
  }

  play() {
    this.player && this.player.playVideo()
  }
  pause() {
    this.player && this.player.pauseVideo()
  }

  goto(i: number): void {
    const tl: gsap.core.Timeline = gsap.timeline()
    tl.addLabel('enter')
    tl.add(() => {
      transition.setOpen({ opened: true, options: { showBackdrop: true } })
    }, 'enter')
    tl.addLabel('afterEnter', '+=1')
    tl.add(() => {
      gallery.setPosition(i)
    }, 'afterEnter')
    tl.addLabel('leave')
    tl.add(() => {
      transition.setOpen({ opened: false, options: { showBackdrop: true } })
    }, 'leave')
  }
}

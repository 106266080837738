import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class Viewport extends VuexModule {
  public windowWidth = window.innerWidth
  public windowHeight = window.innerHeight
  public otClientRect: ClientRect | DOMRect = null

  init() {
    window.addEventListener('resize', () => this.onResize())
  }

  dispose() {
    window.removeEventListener('resize', () => this.onResize())
  }

  @Action
  getOTClientRect() {
    const otBanner:Element = document && document.querySelector('#onetrust-banner-sdk') || null
    if (otBanner) {
      const bcr: ClientRect | DOMRect = otBanner.getBoundingClientRect()
      this._SET_OT_CLIENT_RECT(bcr)
    }
  }

  @Action
  onResize() {
    this._SET_SIZE([window.innerWidth, window.innerHeight])

    this.getOTClientRect()
  }

  @Mutation
  private _SET_SIZE(size: [number, number]) {
    this.windowWidth = size[0]
    this.windowHeight = size[1]
  }

  @Mutation
  private _SET_OT_CLIENT_RECT(clientRect: ClientRect | DOMRect) {
    this.otClientRect = clientRect
  }
}

const viewport = new Viewport({ store, name: 'viewport' })
viewport.init()
viewport.getOTClientRect()

export default viewport

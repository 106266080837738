

























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import gsap from 'gsap/all'

@Component
export default class UIBtn extends Vue {
  /**
   * native "type" HTML attribute for the button. Default to "button"
   */
  @Prop({
    type: String,
    default: 'button'
  })
  type?: string

  /**
   * optional icon id to display, id should be a filename of a .svg in src/assets/icons
   */
  @Prop({
    type: String
  })
  icon?: string

  /**
   * optional picto id to display, id should be a filename of a .svg in src/assets/pictos
   */
  @Prop({
    type: String
  })
  picto?: string

  /**
   * force active state
   */
  @Prop({
    type: Boolean,
    default: false
  })
  active!: boolean

  /**
   * Primary button, with --primary background color
   */
  @Prop({
    type: Boolean,
    default: false
  })
  primary!: boolean

  /**
   * Dark button, with --primary-dark background color
   */
  @Prop({
    type: Boolean,
    default: false
  })
  dark!: boolean

  /**
   * Light button, with --primary-light background color
   */
  @Prop({
    type: Boolean,
    default: false
  })
  light!: boolean

  /**
   * Radio button, with --primary-light background color, accent text always
   */
  @Prop({
    type: Boolean,
    default: false
  })
  radio!: boolean

  /**
   * Bold text when active
   */
  @Prop({
    type: Boolean,
    default: false
  })
  boldActive!: boolean

  /**
   * outlined button, with --primary border color and white BG
   */
  @Prop({
    type: Boolean,
    default: false
  })
  outlined!: boolean

  /**
   * Optional route passed to `$router.push()`
   */
  @Prop({
    type: [String, Object],
    default: undefined
  })
  to!: RawLocation

  /**
   * Optional external link
   */
  @Prop({
    type: String,
    default: undefined
  })
  toExternal!: string

  /**
   * compact size (32px height)
   */
  @Prop({
    type: Boolean,
    default: false
  })
  compact!: boolean

  /**
   * large size (59px height)
   */
  @Prop({
    type: Boolean,
    default: false
  })
  large!: boolean

  /**
   * wide size (56px horizontal padding )
   */
  @Prop({
    type: Boolean,
    default: false
  })
  wide!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  fullWidth!: boolean

  /**
   * capsule shape
   */
  @Prop({
    type: Boolean,
    default: false
  })
  pill!: boolean

  /**
   * smaller font-size, no icon margin
   */
  @Prop({
    type: Boolean,
    default: false
  })
  densify!: boolean

  /**
   * disabled btn
   */
  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  /**
   * display a shadow
   */
  @Prop({
    type: Boolean,
    default: false
  })
  shadow!: boolean

  /**
   * display a spinner
   */
  @Prop({
    type: Boolean,
    default: false
  })
  loading!: boolean

  /**
   * Run an enter animation
   * can use a delay as param
   */
  @Prop({})
  appear!: number

  /**
   * add a sound on click
   */
  @Prop({
    type: Boolean,
    default: false
  })
  sound!: boolean

  tl: gsap.core.Timeline

  get _isdisabled() {
    return this.disabled || this.loading
  }

  // get schemeClass(): string{
  //   if( !this.inheritColor ) {
  //     return primaryClass( this.color )
  //   }
  //   return null
  // }

  get iconOnly(): boolean {
    return (
      this.$slots.default === undefined ||
      this.$slots.default.length === 0 ||
      (this.$slots.default.length === 1 && this.$slots.default[0].text === '')
    )
  }

  mounted() {
    if (this.appear) this._enterAnimation()
  }

  beforeDestroy() {
    if (this.tl) {
      this.tl.eventCallback('onComplete', null)
    }
  }

  _enterAnimation() {}

  _onClick(e: Event, navigate: Function) {
    if (this.to) {
      navigate(e)
    }

    // if external link
    if (this.toExternal) {
      window.open(this.toExternal, '_blank')
    }

    this.$emit('click')
  }

  _onEnter() {
    const tl = gsap.timeline()
    tl.addLabel('start')
    tl.to(
      this.$refs.hoverBg,
      { transformOrigin: '50% 100%', scaleX: 0.5, scaleY: 0, duration: 1.25, ease: 'quint.out' },
      'start'
    )
    tl.fromTo(
      this.$refs.content,
      {
        yPercent: 0,
        opacity: 1,
      },
      {
        yPercent: 100,
        opacity: 0,
        duration: 0.35,
        ease: 'quint.in'
      },
      'start'
    )
    tl.fromTo(
      this.$refs.content,
      {
        yPercent: -100,
        opacity: 0
      },
      {
        yPercent: 0,
        opacity: 1,
        duration: 0.35,
        ease: 'quint.out'
      },
      'start+=0.35'
    )
  }

  _onLeave() {
    const tl = gsap.timeline()
    tl.addLabel('start')
    tl.to(this.$refs.hoverBg, { scale: 1, duration: 1.25, ease: 'quint.out', clearProps: 'scale' }, 'start')
    tl.fromTo(
      this.$refs.content,
      {
        yPercent: 0,
        opacity: 1,
      },
      {
        yPercent: -100,
        opacity: 0,
        duration: 0.35,
        ease: 'quint.in'
      },
      'start'
    )
    tl.fromTo(
      this.$refs.content,
      {
        yPercent: 100,
        opacity: 0
      },
      {
        yPercent: 0,
        opacity: 1,
        duration: 0.35,
        ease: 'quint.out'
      },
      'start+=0.35'
    )
  }
}

import { render, staticRenderFns } from "./WallpapersConfirmView.vue?vue&type=template&id=789506d6&scoped=true&"
import script from "./WallpapersConfirmView.vue?vue&type=script&lang=ts&"
export * from "./WallpapersConfirmView.vue?vue&type=script&lang=ts&"
import style0 from "./WallpapersConfirmView.vue?vue&type=style&index=0&id=789506d6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "789506d6",
  null
  
)

export default component.exports
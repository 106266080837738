
import Flag         from 'nanogl-pbr/Flag'
import Enum         from 'nanogl-pbr/Enum'
import Precision    from 'nanogl-pbr/ShaderPrecision'
import Version      from 'nanogl-pbr/ShaderVersion'

import mat4         from 'gl-matrix/src/gl-matrix/mat4'

import vShader from './shader.vert'
import fShader from './shader.frag'
import MaterialPass from 'nanogl-pbr/MaterialPass'
import LightSetup from 'nanogl-pbr/lighting/LightSetup'
import DepthFormat, { DepthFormatEnum } from 'nanogl-pbr/DepthFormatEnum'
import Program from 'nanogl/program'
import Node from 'nanogl-node'
import Camera from 'nanogl-camera'

var M4           = mat4.create();


var MAT_ID = 'dpm';

export default class DepthPass extends MaterialPass {

  version: Version
  precision: Precision
  shaderid: Flag<string>
  depthFormat: DepthFormatEnum


  constructor(){

    super( {
      uid  : MAT_ID,
      vert : vShader(),
      frag : fShader(),
    });

    this.version         = this.inputs.add( new Version( '100' ) );
    this.precision       = this.inputs.add( new Precision( 'mediump' ) );
    this.shaderid        = this.inputs.add( new Flag ( 'id_'+MAT_ID,  true  ) );

    this.depthFormat = this.inputs.add( new Enum( 'depthFormat',DepthFormat ) );
    
    this.mask = ~0;

  }





  setLightSetup( setup : LightSetup ){
    this.inputs.remove( this.depthFormat );
    this.depthFormat.proxy( setup.depthFormat );
    this.inputs.add( this.depthFormat );
  }


  prepare( prg:Program, node : Node, camera : Camera ){

    if( prg.uMVP ){
      camera.modelViewProjectionMatrix( M4, node._wmatrix );
      prg.uMVP(          M4            );
    }

    if( prg.uWorldMatrix )
      prg.uWorldMatrix( node._wmatrix );

    if( prg.uVP )
      prg.uVP( camera._viewProj );

  }


};


import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

const BASE_VOLUME = .7
@Module
export class Sound extends VuexModule {
  
  public mainVolume = BASE_VOLUME

  get isMuted():boolean {
    return this.mainVolume < 0.001
  }


  @Action
  mute() {
    this._SET_VOLUME(0)
  }

  @Action
  unmute() {
    this._SET_VOLUME(BASE_VOLUME)
  }

  @Mutation
  private _SET_VOLUME(v:number) {
    this.mainVolume = v
  }

  

  /**
   * amount of lowpass effect on main track
   */
  public mainLowPass = 1

  @Action
  setLowPass(v:number) {
    this._SET_LOWPASS(v)
  }

  @Mutation
  private _SET_LOWPASS(v:number) {
    this.mainLowPass = v
  }




  
  needUserAction: boolean = false

  @Action
  setNeedUserAction(once: boolean) {
    this._SET_NEED_USERACTION(once)
  }

  @Mutation
  private _SET_NEED_USERACTION(once: boolean) {
    this.needUserAction = once
  }

}

const sound = new Sound({ store, name: 'sound' })

/////////////
//////////////////////////////////////////////
///////////////
 
//////////


export default sound














import Paths from '@/core/Paths'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import lottie, { AnimationItem } from 'lottie-web'
import gsap from 'gsap'
import isTouchDevice from '@/utils/is-touch-device'

@Component
export default class GalleryTutorialStep extends Vue {
  tutorialAnimation: AnimationItem

  @Prop({
    type: String,
    required: true
  })
  id: string

  get device(): string {
    return isTouchDevice() ? 'mobile' : 'desktop'
  }

  beforeDestroy() {
    this.tutorialAnimation && this.tutorialAnimation.destroy()
  }

  mounted(): void {
    const path: string = Paths.resolve(`./assets/lottie/tutorial-${this.id}.json`)
    this.tutorialAnimation = lottie.loadAnimation({
      container: this.$refs.anim as Element,
      renderer: 'svg',
      autoplay: true,
      loop: 2,
      path
    })

    this.tutorialAnimation.addEventListener('loopComplete', this.onTutorialAnimationComplete)
  }

  onTutorialAnimationComplete(e) {
    if (e.currentLoop !== e.totalLoops) {
      return
    }
    this.$emit('end')
  }

  enter(el, onComplete) {
    const tl = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(el, { opacity: 0, duration: 0.35, ease: 'quint.inOut' }, 'start')
    tl.from(this.$refs.title, { yPercent: 100, duration: 0.65, ease: 'quint.out' }, 'start')
    tl.add(() => {
      this.tutorialAnimation && this.tutorialAnimation.play()
    }, 'start')
  }

  leave(el, onComplete) {
    const tl = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.to(el, { opacity: 0, duration: 0.35, ease: 'quint.out' }, 'start')
    tl.to(this.$refs.title, { yPercent: -100, duration: 0.65, ease: 'quint.out' }, 'start')
  }
}

import Paths from "@/core/Paths";
import gallery from "@/views/gallery";
import { mat4, vec2, vec3 } from "gl-matrix";
import GLTFResource from "../assets/GLTFResource";
import Frame from "./Frame";
import Gltf from "../lib/nanogl-gltf/lib";
import GltfTypes from "../lib/nanogl-gltf/lib/types/GltfTypes";
import Scene from "../scene";
import Pov360 from "./Pov360";
import PovEnclosed from "./PovEnclosed";
import Camera from "nanogl-camera";
import gallery_store from "@/store/modules/gallery";
import { clamp01 } from "../math";
import { getAssetPath } from "./Assets";

export interface FrameRaycastResult {
  inBounds : boolean
  localPos : vec3
  worldPos : vec3
}
 

export interface IPov {

  transitionDuration():number

  preRender()
  render()
  enter()
  leave()
  /**
   * 
   * @param out local matrix
   * @return vertical fov
   */
  getCameraParams(out: mat4):number

  getPathPosition(): number
  raycast( camera:Camera, viewportPos:vec2) : FrameRaycastResult;
  lowpassAmount: number

}

export default class Povs {


  gltfResource: GLTFResource;
  frames: IPov[];

  _currentFrame : IPov = null

  paralax = vec2.create()
  mouse = vec2.create()


  set currentFrame( f:IPov){
    this._currentFrame = f
    this.scene.xpcam.selectFrame( f );
  }

  get currentFrame(){
    return this._currentFrame
  }


  constructor( readonly scene:Scene ){

    document.addEventListener( 'mousemove', this.onMouseMove)
  }

  async load(): Promise<void> {
    const gltfResource = new GLTFResource(
      getAssetPath(`frames.glb`),
      this.scene
    )
    await gltfResource.load().then( (gltf)=>{
      // gltf.root.setScale(0.01)
      this.scene.root.add( gltf.root)
      this.extractPovs(gltf)
    })

  }

  


  // extractPovs( gltf:Gltf ) {
  //   this.frames = []
  //   const slides = [1, 2, 3, 5];
  //   for (let i = 0; i < 4; i++) {
  //     const frameRef = gltf.getElementByName( GltfTypes.NODE, `frame${i}_Reference`)
  //     this.frames.push( new Frame( this.scene, frameRef, slides[i], i) )
  //   }
  // }

  extractPovs( gltf:Gltf ) {

    this.frames = []
    for (const node of gltf.nodes) {
      
      const reg = /^frame_(\d+)$/
      const res = reg.exec( node.name )
      if( res !== null ){
        // names are "1" indexed , artwork indice are "0" indexed
        const index = parseInt(res[1])// - 1
        if( isNaN(index)) throw 'oops frame index is nan'
        // console.log(index);
        vec3.scale( node.position, node.position, .01 )
        
        if( index === 22 ){
          // pano 360
          this.frames[index] = new Pov360( this, node, index, index)
        
        } else if( index === 29 ){
          // pano 360
          this.frames[index] = new PovEnclosed( this, node, index, index)
        } else {
          const f = this.frames[index] = new Frame( this, node, index, index)

          if( index === 37 ){
            f.raycastExtent = 15
          }
        }
      }
    }

  }

  fcloseTimeout:number
  activateFrame(currentArtwork: number) {
    
    clearTimeout(this.fcloseTimeout)

    if( currentArtwork === -1 ){
      const f = this.currentFrame
      this.currentFrame?.leave()
      this.scene.xpcam.selectFrame( null );
      this.fcloseTimeout = setTimeout(() => {
        if( f === this.currentFrame )
          this.currentFrame = null
      }, 500);

    } else {
      const newFrame = this.frames[currentArtwork]
      if( newFrame !== this.currentFrame ){
        this.currentFrame?.leave()
        this.currentFrame = newFrame
        this.currentFrame.enter()
      }
    }
  }


  preRender(){

    this.currentFrame?.preRender()

    vec2.lerp( this.paralax, this.paralax, this.mouse, clamp01( this.scene.dt*3))
    const mc = this.scene.inputs.mouseCoords
    let rollover = false
    for (const pov of this.frames) {
      const raycast = pov.raycast( this.scene.camera, mc );
      if( raycast && raycast.inBounds ){
        rollover = true
        break
      }
    }

    gallery_store.setCursorPointer( rollover)
  }


  postRender(){
    this.currentFrame?.render()
  }


  onMouseMove = (e:MouseEvent)=>{
    const cx = e.clientX;
    const cy = e.clientY;
    this.mouse[0]  =   2 * cx /  (window.innerWidth) - 1;
    this.mouse[1]  = -(2 * cy /  (window.innerHeight) - 1);
  }

}
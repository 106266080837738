import Program from "nanogl/program";

import VS from './shader.vert'
import FS from './shader.frag'
import Texture2D from "nanogl/texture-2d";
import { mat4, vec2,  vec4 } from "gl-matrix";
import GLArrayBuffer from "nanogl/arraybuffer";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import Scene from "@/webgl/scene";
import { LocalConfig } from "nanogl-state";
import Paths from "@/core/Paths";
import GL from "@/webgl/gl/GL";


const DEG2RAD = Math.PI/180.0;

const INV_PROJ = mat4.create()
const V4A = vec4.create()
const V2A = vec2.create()


const QUAD_VERTICES = new Float32Array([
  -1, -1,
  -1, 1,
  1, -1,
  1, 1,
]);



function latLngToWorldPos( out: vec4, latlng: vec2 ): void {
  const theta = latlng[0] * Math.PI*2;
  const phi   = (latlng[1] - .5)* Math.PI;

  out[0] = Math.cos(phi) *  Math.sin(theta);
  out[2] = Math.cos(phi) *  -Math.cos(theta);
  out[1] = Math.sin(phi);
  out[3] = 1
}



export default class PanoRenderer {

  setTex(tex: Texture2D) {
    this.envmap = tex;
  }

  /**
   * the latlon projection shader
   */
  prg: Program

  /**
   * fullscreen quad
   */
  quad: GLArrayBuffer

  /**
   * latlon texture
   */
  private envmap: Texture2D

  /**
   * view projection camera matrix
   */
  viewproj: mat4


  rx= 0
  ry= Math.PI

  opacity = 1
  brightness = 0
  cfg: LocalConfig;


  constructor( readonly scene: Scene ){
    
    const gl = scene.gl
    
    this.prg    = new Program( gl, VS(), FS() )
    this.quad   = new GLArrayBuffer(gl, QUAD_VERTICES)
      .attrib( 'aPosition', 2, gl.FLOAT )

    this.viewproj = mat4.create()

    this.cfg = scene.glstate.config()
      .enableBlend()
      .blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )
      .enableDepthTest(false)
      .enableCullface(false)

      .enableStencil( true )
      .stencilFunc( GL.EQUAL, 1, 0xFF )

  }
  

  /**
   * 
   * convert Lat/Lng coordinate to viewport [0-1] space. return false if point is behind camera
   * @param out 
   * @param latlngCoords 
   */
  public unproject( out: [number, number], latlngCoords: [number, number] ): boolean {
    V2A.set( latlngCoords );
    latLngToWorldPos( V4A, V2A )
    vec4.transformMat4( V4A, V4A, this.viewproj )
    out[0] = V4A[0]/V4A[3] *.5 + .5
    out[1] = V4A[1]/V4A[3] *.5 + .5
    return V4A[3] > 0
  }
  
  
  render(){
    
    const gl = this.scene.gl
    
    const VP = this.viewproj;
    
    mat4.perspective(VP, 80 * DEG2RAD, this.scene.ratio, 3, 1000 )
    mat4.rotateX( VP, VP, this.rx )
    mat4.rotateY( VP, VP, this.ry )
    mat4.invert( INV_PROJ, VP )
    
    if( !this.envmap ) return;

    // bind program
    this.prg.use()

    // vertexAttribPointer
    this.quad.attribPointer( this.prg )

    // setup program's uniforms
    this.prg.tLatLon( this.envmap )
    this.prg.uUnproject( INV_PROJ )
    this.prg.uOpacity( this.opacity )
    this.prg.uBrightness( this.brightness )
    
    // drawArray()
    this.cfg.apply()
    this.quad.drawTriangleStrip()
  }

}
/////////////
///////////////////////////////////////
//////////


import DirLight  from 'nanogl-pbr/lighting/DirectionalLight'
import Setup     from 'nanogl-pbr/lighting/LightSetup'
import PunctualLight from 'nanogl-pbr/lighting/PunctualLight'
import Node      from 'nanogl-node'
import DepthPass from '@/webgl/glsl/depthpass'


import vec3 from  'gl-matrix/src/gl-matrix/vec3'
import quat from  'gl-matrix/src/gl-matrix/quat'
import quality from '@/webgl/lib/quality';
import Light from 'nanogl-pbr/lighting/Light'
import Scene from '@/webgl/scene'
import GLConfig from 'nanogl-state/config'



const V3   = vec3.create()

class LightControl {
  light: PunctualLight
  lum: number
  rX: number
  rZ: number


  constructor( light:PunctualLight ){
    this.light = light;

    this.lum = 0;
    this.rX = 0;
    this.rZ = 0;
  }

  setLum( v:number ){
    this.lum = v;
    this.light._color.set([this.lum, this.lum, this.lum])
  }

  update(){

    this.light._color.set([this.lum, this.lum, this.lum])

    quat.identity( this.light.rotation );
    quat.rotateY( this.light.rotation, this.light.rotation, this.rZ );
    quat.rotateX( this.light.rotation, this.light.rotation, this.rX );
    this.light.invalidate();
    
  }

  gui( folder:any, name:string ){

    const f0 =  folder.addFolder(name)
    f0.add(this, 'rZ', 0, Math.PI*2 )
    f0.add(this, 'rX', -Math.PI/2, Math.PI/2 )
    f0.add(this, 'lum', 0, 5 )
    if( this.light._castShadows )
    f0.add(this.light, 'iblShadowing', 0, 1 )
  }


}

export default class Lights{


  scene    : Scene
  node     : Node
  setup    : Setup
  list     : Light[]
  depthPass: DepthPass
  depthCfg : GLConfig
  controls : LightControl[]
  light0   : DirLight
  light1   : DirLight
  light2   : DirLight

  

  constructor( scene:Scene ){
    
    this.scene = scene
    const gl = scene.gl;


    this.node  = new Node();
    this.setup = new Setup();

    this.setup.bounds.fromMinMax( 
      [-20, -5, -20],
      [ 20,  15, 20] 
    );

    // 'PCFNONE',
    // 'PCF4x1',
    // 'PCF4x4',
    // 'PCF2x2'
      

    this.setup.stdModel.shadowFilter.set( 'PCF4x4' )
    this.setup.stdModel.iblShadowing.enable()


    this.list = []


    // default depth pass
    // =================
    const depthPass = new DepthPass();
    depthPass.setLightSetup( this.setup );
    this.depthPass = depthPass;

    this.depthCfg = scene.glstate.config()
      .cullFace( gl.FRONT )
      .enableCullface( true )
      .enableDepthTest( true )
      .depthMask( true )

    
/////////////////
//////////////////////////////////////////
//////////////


    let lctrl:LightControl;
    this.controls = [];

    // dir light 0
    // ===========
    this.light0 = new DirLight();
    this.light0._shadowmapSize = 1024;
    this.light0._shadowmapNearOffset = 0;
    this.light0.castShadows( quality.param( 'shadows' ) );
    this.light0.iblShadowing = 1;
    
    lctrl = new LightControl( this.light0 );
    lctrl.setLum( 0.3 )//.33 );
    lctrl.rX = -0.83
    lctrl.rZ = 1.6
    this.controls.push( lctrl );
/////////////////
///////////////////////////////
//////////////


    // dir light 1
    // ===========
    this.light1 = new DirLight();
    this.light1.castShadows( false );
    
    lctrl = new LightControl( this.light1 );
    lctrl.setLum( .5 )//1.44 );
    lctrl.rX = -.12
    lctrl.rZ = 5.9
    this.controls.push( lctrl );


/////////////////
///////////////////////////////
//////////////

    // dir light 2
    // ===========
    this.light2 = new DirLight();
    this.light2.castShadows( false );
    
    lctrl = new LightControl( this.light2 );
    lctrl.setLum( 0.6 )//1.65 );
    lctrl.rX = -.18 
    lctrl.rZ = 3.3
    this.controls.push( lctrl );


/////////////////
///////////////////////////////
//////////////

  
    // this.registerLight( this.light0 )
    // this.registerLight( this.light1 )
    // this.registerLight( this.light2 )
  }


  registerLight( l:Light ){
    this.scene.sroot.add( l );
    this.setup.add( l );
    this.list.push( l );
  }


  hasDepthShadowmap(){
    return this.light0.hasDepthShadowmap()
  }
  

  preRender(){

    for (var lctrl of this.controls) {
      lctrl.update();
    }
    

/////////////////
/////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////

  }


  computeShadowmapBounds(){

  }


}

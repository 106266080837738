import IBL        from './ibl'
import * as Net   from '@/webgl/assets/Net'
import gui        from '../dev/gui'

import NGL_IBL    from 'nanogl-pbr/lighting/Ibl'
import Input, { Uniform }      from 'nanogl-pbr/Input'
import Enum       from 'nanogl-pbr/Enum'
import Texture2D    from 'nanogl/texture-2d'
import Lights     from './Lights';
import Scene from '@/webgl/scene'
import { GLContext } from 'nanogl/types'
import { GammaModes, GammaModeEnum } from 'nanogl-pbr/GammaModeEnum'
import IIblMaterialPass from '@/webgl/glsl/IblMaterialPass'
import Paths from '@/core/Paths'






const INITIAL_IDX = 0;

const ENVS = [
  'env_musee',
  'default_env',
  'uffizi',
  'studio019',
  'studio020',
  'vanKleef',
  '5008',
  'LA_Downtown_Afternoon_Fishing',
  'MonValley',
  'doge2',
  'Helipad',
  'Mans_Outside',
  'pisa',
  'hallstatt',
  'parking_ext',
  'Brooklyn_Bridge_Planks'       ,
  'Ditch-River',
  'Tropical_Beach'               ,
  '10-Shiodome_Stairs',
  'Barce_Rooftop_C',
  'Tokyo_BigSight',
  'Factory_Catwalk',
  'Ice_Lake',
  'Charles-River-Esplanade-03',
  'MIT-01',
  'Mt-Washington-Hotel-Lookout',
  'Tufts-Parking-Lot',
  'studio016',
  'studio017',
  'studio018',
  'studio021',
  'studio022',
  'studio023',
  'studio024',
  'abandonned',
  'desert_highway',
  'DS360_Free_001_v02_Ref',
  'DS360_Free_002_Ref',
  'village',
  'Mono_Lake_B',
  'Ridgecrest_Road',
  'SD_abandoned_sanatorium_staircase',
  'SD_airport',
  'SD_bonifacio_aragon_stairs',
  'SD_bonifacio_street',
  'SD_bus_garage',
  'SD_cave_entry_in_the_forest',
  'SD_corsica_beach',
  'SD_elevator_corridor',
  'SD_Gdansk_shipyard_buildings',
  'SD_glazed_patio',
  'SD_industrial_room',
  'SD_panorama_map',
  'SD_road_in_tenerife_mountain',
  'SD_small_apartment',
  'SD_Soft_1Front',
  'SD_Soft_1Front_2Backs',
  'SD_Soft_1LowContrastFront_2Backs',
  'SD_Soft_2RingHighContrast',
  'SD_Soft_4TubeBank_2BlackFlags',
  'SD_Soft_5DaylightStudio',
  'SD_studio',
  'SD_studio_03',
  'SD_studio_04',
  'SD_studio_05',
  'SD_terrace_near_the_granaries',
  'SD_tomoco_studio',
  'SD_urban_exploring_interior',
]



const EXPO = 1.4
const GAMMA = 2.3

class Env{

  scene: Scene
  gl: GLContext

  envTex: Texture2D
  envHi: Texture2D
  envBg: Texture2D
  
  ibl: IBL
  lights: Lights
  
  gammaMode: GammaModeEnum

  gamma   : Input
  exposure: Input
  expoUniform : Uniform
  gammaUniform: Uniform

  shMul: number
  shBase: Float32Array
  
  reflectExpo=1
  reflectGamma=1

  constructor( scene:Scene ){

    const gl = scene.gl;

    this.scene = scene;
    this.gl = gl;
    
    this.envTex = new Texture2D( this.gl, this.gl.RGBA );
    this.envHi  = new Texture2D( this.gl, this.gl.RGBA );
    this.envBg  = new Texture2D( this.gl, this.gl.RGB );
    
    this.ibl    = new IBL( this.envTex, this.envHi, this.envBg );

    this.envTex.setFilter( false );

    this.lights  = new Lights( scene );
    this.lights.setup.add( this.ibl );

    this.shMul = 1.0;


    this.gammaMode  = new Enum( 'gammaMode', GammaModes);
    this.gamma      = new Input( 'gamma',       1, Input.ALL );
    this.exposure   = new Input( 'exposure',    1, Input.ALL );


    this.expoUniform = this.exposure .attachUniform( 'utmExpo' )
    this.gammaUniform = this.gamma    .attachUniform( 'uTMGamma')
    this.expoUniform.set( EXPO );
    this.gammaUniform.set( 1/GAMMA );


    if( scene.post.post.enabled ){
      this.gammaMode.set( 'GAMMA_NONE' )
    } else {
      this.gammaMode.set( 'GAMMA_STD' )
    }

/////////////////
///////////////
//////////////

  }


  setupForReflect(){
    this.expoUniform.set( this.reflectExpo );
    this.gammaUniform.set( this.reflectGamma );
  }
  
  restoreFromReflect(){
    this.expoUniform.set( EXPO );
    this.gammaUniform.set( 1/GAMMA );
  }


  setupMat( m : IIblMaterialPass ){

    m.setLightSetup( this.lights.setup );

    m.iGamma   .proxy( this.gamma )
    m.iExposure.proxy( this.exposure )
    m.gammaMode.proxy( this.gammaMode )

  }

  setupExpoGmma( m : IIblMaterialPass ){

    m.iGamma   .proxy( this.gamma )
    m.iExposure.proxy( this.exposure )
    m.gammaMode.proxy( this.gammaMode )

  }

  dispose(){

    this.envTex .dispose();
    this.envHi  .dispose();
    this.envBg  .dispose();

    this.envTex = null;
    this.envHi  = null;
    this.envBg  = null;

    this.ibl    = null;
    this.scene  = null;
    
    this.shBase = null;
    this.shMul  = 1.0;

  }


  loadDefault(){
    return this.load( 'envs/'+ENVS[INITIAL_IDX] )
  }


  load( dir:string ){
    
    return Promise.all( [
      Net.loadImage( Paths.resolve( 'assets/webgl/'+dir+'/env.png' ) ).then( (img)=>this.envTex.fromImage(img) ),
      Net.loadBytes( Paths.resolve( 'assets/webgl/'+dir+'/sh.bin' ) ).then( this.convertSH )
      // Net.loadImage( Paths.resolve( dir+'/bg.jpg' ) ).then( (img)=>this.envBg.fromImage(img) ),
      // Net.loadImage( Paths.resolve( dir+'/env_hi.png' ) ).then( (img)=>this.envHi.fromImage(img) ),
    ])

  }

  convertSH = (buf) => {
    this.shBase = new Float32Array(buf, 0, 9*3);
    this.ibl.sh = NGL_IBL.convert(this.shBase, this.shMul )
  }




  preRender(){
    this.lights.preRender();
  }


///////////////
/////////

//////////////////
/////////////////////
////
/////////////////////////////////////

///////////////////////////////////////
///////////////////////////////////////////////
////////////////////////////
//////

///////////////
//////////////////
////////////////
/////////////////
////////////////
/////


//////////////////////
//////////////////////

/////////////////////////////////////////////////
////////////////////////////////////

//////////////////////////
///////////////
//////////////////
//////////////////
///////////////////////

////////////////////
//////////////////////////////
///////////////////////////
/////////////////////
/////////////////////

////////////////////////////
///////////////////////////////////////////////////////////
////////////////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////////////////////////
/////////

////////////////////////////
/////////////////////
/////////////////////
//////////////////////////////////////////////
///////////////////////////////////////////////////

///////

///////

///////////////////////////////////////////
///////////////////////////////////////
//////
////
////////////////////////////////////////////
////////////////////////////////////////////
//////

//////////////////////////////////////////////
/////////////////////////////
//////////////////////////////////////////////////////////////
//////

/////////////////////////////////////
//////////////////////////////////////
//

///

////////////

}

export default Env;
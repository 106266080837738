











import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import gsap from 'gsap'

import { TRANSITION_DIRECTION } from '@/store/modules/transition'

const path: string = './assets/sprites/transition-%DIRECTION%/hd/frame-%INDEX%.png'
const frames: number = 12

@Component
export default class AppTransition extends Vue {

  @Prop({ type: String, default: TRANSITION_DIRECTION.HORIZONTAL }) readonly direction!: TRANSITION_DIRECTION

  get images(): string[] {
    const images: string[] = []
    for (let i = 0; i < frames; i++) {
      images.push(path.replace(/%DIRECTION%/g, this.direction).replace(/%INDEX%/g, i.toString().padStart(5, '0000')))
    }
    return images
  }

  animate(vars: gsap.TweenVars = {}) {
    const $sprites = this.$el.children

    const state = {
      current: 0,
      set currentSprite(index: number) {
        this.current = index
        gsap.set($sprites, { opacity: i => +(i === index) })
      },
      get currentSprite() {
        return this.current
      }
    }

    // immediate render first sprite
    const firstSprite = vars.runBackwards ? frames - 1 : 0
    gsap.set($sprites, { opacity: i => +(i === firstSprite) })

    return gsap.to(state, {
      currentSprite: frames - 1,
      snap: { currentSprite: 1 },
      duration: 1,
      ease: 'linear',
      ...vars,
      inherit: false
    })
  }

  enter(vars: gsap.TweenVars = {}) {
    return this.animate({
      ...vars,
      runBackwards: false
    })
  }

  leave(vars: gsap.TweenVars = {}) {
    return this.animate({
      ...vars,
      runBackwards: true
    })
  }
}

import { Route } from 'vue-router'
import RoutePaths, { RouteNames } from '../Routes'
import Agenda from './Agenda.vue'

export default [
  {
    path: RoutePaths.Agenda,
    name: RouteNames.Agenda,

    components: {
      default: Agenda
    },
  }
]
import router from '@/core/router'
import { RawLocation } from 'vue-router'

export enum RouteNames {
  About         = 'About',
  Agenda        = 'Agenda',
  Edito         = 'Edito',
  Gallery       = 'Gallery',
  Artwork       = 'Artwork',
  Wallpapers    = 'Wallpapers'
}

type RoutePathsType = {
  [K in keyof typeof RouteNames]: string
}

/**
 * Routes names and path configuration
 * key = route name
 * value = route path
 */
const RoutePaths: RoutePathsType = {
  Gallery       : '/',
  Artwork       : '/artwork/:slug',
  About         : '/about',
  Agenda        : '/agenda',
  Wallpapers    : '/wallpapers',
  Edito         : '/:slug'
}

Object.freeze(RoutePaths)

export function getRoutePathByName(n: RouteNames): string {
  return RoutePaths[n]
}

export default RoutePaths

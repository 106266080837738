









import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Paths from '@/core/Paths'
import gsap from 'gsap'
import lottie, { AnimationItem } from 'lottie-web'
import isTouchDevice from '@/utils/is-touch-device'
import sound from '@/store/modules/sound'

@Component
export default class UIBtnAudio extends Vue {
  toggleAnimation: AnimationItem
  hoverTL: gsap.core.Timeline
  get iconName(): string {
    return this.isMuted ? 'no-sound' : 'sound'
  }

  get isMuted() {
    return sound.isMuted
  }

  @Watch('isMuted')
  toggleAudio(isMuted: boolean) {
    if (!this.toggleAnimation) return
    if (isMuted) {
      this.toggleAnimation.playSegments([0, 15])
    } else {
      this.toggleAnimation.playSegments([15, 29])
    }
  }

  beforeDestroy() {
    this.toggleAnimation && this.toggleAnimation.destroy()
  }

  mounted() {
    this.prepareAnimations()
    this.toggleAudio(this.isMuted)
  }

  prepareAnimations() {
    this.toggleAnimation = lottie.loadAnimation({
      container: this.$refs.icon as Element,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      path: Paths.resolve('./assets/lottie/icon-sound-toggle.json')
    })

    this.hoverTL = gsap.timeline({ paused: true })
    this.hoverTL.addLabel('start')
    this.hoverTL.fromTo(
      this.$refs.background,
      { scale: 0, transformOrigin: '100% 100%' },
      { scale: 1, duration: 0.85, ease: 'quint.inOut' },
      'start'
    )
    this.hoverTL.to(this.$refs.background, { x: -16, y: -22, duration: 0.85, ease: 'quint.inOut' }, 'start')
    this.hoverTL.to(this.$refs.icon, { x: -16, y: -22, duration: 0.65, ease: 'quint.inOut' }, 'start')
  }

  _onClick() {
    sound.isMuted ? sound.unmute() : sound.mute()
  }

  _onEnter() {
    if (isTouchDevice()) {
      return
    }
    this.hoverTL.play(0)
  }

  _onLeave() {
    if (isTouchDevice()) {
      return
    }
    this.hoverTL.reverse()
  }
}








import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import menu from '@/store/modules/menu'

@Component
export default class UINavBtn extends Vue {
  @Watch('$route')
 watchRoute() {
     this.$el.classList.remove('light')
  }

  openNav() {
    menu.setOpen(true)
  }
}

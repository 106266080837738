import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Flag from "nanogl-pbr/Flag";
import Input from "nanogl-pbr/Input";
import Program from "nanogl/program";
import Texture2D from "nanogl/texture-2d";
import ScreenSize from "../ScreenSize";
import FragCode from "./code.frag"
import FragPreCode from "./pre-code.frag"


export default class FloorReflectionChunk extends Chunk {

  static enabled: Flag = new Flag(  'enableFReflect',  true );

  reflectionTexture : Texture2D = null

  strength : Input

  constructor(){
    super( true, true )
    this.addChild( FloorReflectionChunk.enabled );  
    this.addChild( ScreenSize.input );  

    this.addChild( this.strength = new Input( "ReflectionStrength", 1));
    this.strength.attachConstant( 1.8 )  
  }

  protected _genCode(slots: ChunksSlots): void {
    slots.add( 'lightsf', FragCode() )
    slots.add( 'pf', FragPreCode() )
  }


  setup(prg: Program): void{
    if( this.reflectionTexture === null ) throw 'missing reflection texture'
    prg.tFloorReflect( this.reflectionTexture );
  }
}

import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

export enum TRANSITION_DIRECTION {
  HORIZONTAL = 'hrzntl',
  VERTICAL = 'vrtcl'
}

export type TransitionOptions = {
  direction?: TRANSITION_DIRECTION.HORIZONTAL | TRANSITION_DIRECTION.VERTICAL,
  showBackdrop?: boolean
  fade?: boolean
  skip?: boolean
}

type TransitionPayload = {
  opened: boolean,
  options?: TransitionOptions
}

@Module
export class Transition extends VuexModule {
  opened: boolean = true
  options: TransitionOptions = {
    direction: TRANSITION_DIRECTION.HORIZONTAL,
    showBackdrop: true,
    fade: false,
    skip: false
  }

  @Action
  setOpen(payload: TransitionPayload) {
    this._SET_OPEN(payload)
  }

  @Mutation
  private _SET_OPEN({ opened, options }: TransitionPayload) {
    this.opened = opened
    this.options = {
      ...this.options,
      skip: false,
      fade: false,
      ...options
    }
  }
}

const transition = new Transition({ store, name: 'transition' })

export default transition

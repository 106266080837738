import Paths from "@/core/Paths";
import Camera from "nanogl-camera";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import GLConfig from "nanogl-state/config";
import Gallery from ".";
import GLTFResource from "../assets/GLTFResource";
import TextureLibrary from "../assets/TextureLibrary";
import { TextureResource } from "../assets/TextureResource";
import Masks from "../gl/masks";
import { Passes } from "../glsl/Passes";
import Gltf from "../lib/nanogl-gltf/lib";
import MaterialOverrideExtension2 from "../lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension2";
import { getAssetPath } from "./Assets";
import MaterialFactory, { MAT_NAME } from "./MaterialFactory";


export default class GallerySection {


  gltfResource: GLTFResource;
  gltfOverride: MaterialOverrideExtension2;
  lightmaps: TextureLibrary;




  constructor(readonly gallery: Gallery, readonly index: number) {


    this.gltfOverride = new MaterialOverrideExtension2();
    this.gltfOverride.getMaterial = this.getMaterial

    this.lightmaps = new TextureLibrary()

    this.gltfResource = new GLTFResource(
      getAssetPath(`section_${index}.glb`),
      gallery.scene,
      {
        extensions: [this.gltfOverride]
      }
    );

  }


  get gltf(): Gltf {
    return this.gltfResource.value
  }


  async load(): Promise<void> {
    await this.gltfResource.load().then( ()=>{
      this.gallery.scene.root.add( this.gltf.root )
    })
    await this.lightmaps.load()
  }


  getMaterial = (name: string):BaseMaterial => {
    // console.log(name);
    
    const reg = /(.+)_LM_(\d)_(\d)$/
    const res = reg.exec( name )
    let basename = name
    if( res !== null ){
      // has lightmap
      basename = res[1]
    }

    const mat = this.gallery.matFactory.createMaterial(basename as MAT_NAME)

    if( res !== null ){
      const section_id = res[2]
      const lm_index = res[3]
      const lm_name = `LM_${section_id}_${lm_index}`

      if( section_id !== ""+this.index ) throw `section ${this.index} contain lm ${section_id}` 

      const lm = this.getLightmapResource(lm_name)
      this.gallery.matFactory.addLightmap( mat, lm )
    }

    return mat

  }


  getLightmapResource( name:string ){
    if( !this.lightmaps.has( name ) ){
      const lm = new TextureResource( MaterialFactory.makeBBCTextureSet(`${name}.jpg` ), this.gallery.scene )

      lm.response().then(t=>{
        t.clamp()
        t.setFilter(true, false, false)
      })
      this.lightmaps.add( name      ,  lm )
      lm.load()
    }
    return this.lightmaps.get( name )
  }


  preRender() {

  }


  render(camera: Camera, mask: Masks, passId: Passes = Passes.DEFAULT, cfg?: GLConfig) {
    for (const renderable of this.gltf.renderables) {
      renderable.render(this.gallery.scene, camera, mask, passId, cfg)
    }
  }

}














































import Vue from 'vue'
import Component from 'vue-class-component'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '@/utils/SplitText'

import wallpapers from '@/store/modules/wallpapers'
import { Wallpaper } from '@/store/modules/wallpapers'
import viewport from '@/store/modules/viewport'
import Paths from '../../core/Paths'

@Component
export default class WallpapersSelectView extends Vue {
  showBackBtn: boolean = false
  $isDesktop: boolean
  snapScroll: (valueToSnap: number) => number

  itemIndex: number = -1
  slidePositions: number[] = []

  get items(): Wallpaper[] {
    return wallpapers.items
  }

  mounted() {
    this.itemIndex = this.$isDesktop ? -1 : 0
  }

  afterEnter(el: Element) {
    if (this.$isDesktop) {
      return
    }

    const scroller = this.$refs.scroller as HTMLElement
    const snapType = gsap.getProperty(scroller, 'scrollSnapType') as string
    snapType && snapType !== 'none' && gsap.set(scroller, { scrollSnapType: 'none' })
    scroller.scrollLeft = 0
    ;(<Element[]>this.$refs.slide).forEach((slide: Element) => {
      const { x, width } = slide.getBoundingClientRect()
      const left = x + width / 2 - window.innerWidth / 2
      this.slidePositions.push(left)
    })

    snapType && snapType !== 'none' && gsap.set(scroller, { clearProps: 'scrollSnapType' })
    this.snapScroll = gsap.utils.snap(this.slidePositions)

    // watch scroll pos
    ScrollTrigger.create({
      scroller,
      horizontal: true,
      trigger: this.$refs.slide[0],
      start: 'center center',
      endTrigger: this.$refs.slide[this.items.length - 1],
      end: 'center center',
      onUpdate: ({ start, end, progress }) => {
        const currentPos = this.snapScroll(gsap.utils.interpolate(start, end, progress))
        this.itemIndex = this.slidePositions.indexOf(currentPos)
      }
    })
  }

  selectItem(item: Wallpaper) {
    wallpapers.setSelected(item.id)
  }

  onClickItem(item: Wallpaper) {
    if (this.$isDesktop) {
      return this.selectItem(item)
    }

    const index = this.items.indexOf(item)
    if (this.itemIndex === index) {
      return this.selectItem(item)
    }

    this.slideTo(index)
  }

  slideTo(index: number) {
    const slide = this.$refs.slide[index] as Element
    if (!slide) {
      return
    }

    const left = this.slidePositions[index]
    const scroller = this.$refs.scroller as Element
    // scroller.scrollTo({ left, behavior: 'smooth' })
    gsap.to(scroller, {
      scrollTo: { x: left },
      duration: 0.6,
      ease: 'cubic.out'
    })
  }

  enter(el: Element, onComplete) {
    const title: Element = el.querySelector('.wallpapers-select-view-title')
    const splitTitle = new SplitText(title, { type: 'lines,words,chars' })
    const items = Array.from(el.querySelectorAll('.wallpapers-select-view-item'))
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(el, { opacity: 0, duration: 0.35, ease: 'quint.out' }, 'start')
    tl.fromTo(
      splitTitle.chars,
      {
        yPercent: 100,
        opacity: 0
      },
      {
        yPercent: 0,
        opacity: 1,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.fromTo(
      items,
      {
        x: (index) => {
          return gsap.utils.interpolate([-50, 50], index / (items.length - 1))
        },
        y: () => viewport.windowHeight,
        rotate: (index) => {
          return `${gsap.utils.interpolate([15, -15], index / (items.length - 1))}deg`
        },
        opacity: 0
      },
      {
        x: 0,
        y: 0,
        rotate: 0,
        opacity: 1,
        stagger: {
          each: 0.2,
          from: 'center',
          grid: 'auto',
          axis: 'x'
        },
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.add(() => {
      this.showBackBtn = true
    }, 'start+=0.3')
  }

  leave(el: Element, onComplete) {
    const title: Element = el.querySelector('.wallpapers-select-view-title')
    const splitTitle = new SplitText(title, { type: 'lines,words,chars' })
    const items = Array.from(el.querySelectorAll('.wallpapers-select-view-item'))
    const backBtn = el.querySelector('.back-btn')
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.to(
      backBtn,
      {
        opacity: 0,
        duration: 0.35
      },
      'start'
    )
    tl.to(
      splitTitle.chars,
      {
        yPercent: -100,
        opacity: 0,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.to(
      items,
      {
        x: (index) => {
          return gsap.utils.interpolate([-50, 50], index / (items.length - 1))
        },
        y: () => -viewport.windowHeight,
        rotate: (index) => {
          return `${gsap.utils.interpolate([-15, 15], index / (items.length - 1))}deg`
        },
        opacity: 0,
        stagger: {
          each: 0.2,
          from: 'center',
          grid: 'auto',
          axis: 'x'
        },
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.to(el, { opacity: 0, duration: 0.35, ease: 'quint.out' })

    gsap.set(backBtn, { pointerEvents: 'none' })
  }


  presolve(p){
    return Paths.resolve(p)
  }
  
}


import Program from 'nanogl/program';
import Fbo     from 'nanogl/fbo';


import vShader            from './shader.vert'
import fShader            from './shader.frag'


export default class Blur {
  constructor( scene, size ){

    var gl = scene.gl;
    this.gl    = gl;
    this.scene = scene;

    this.blurSamples = 32;
    this.size = size
    this.spread = 1


    this.blurCfg = scene.glstate.config()
      .enableCullface( false )
      .depthMask( false )
      .enableDepthTest( false )
      
    this.blurPrg = new Program( gl,
      vShader(),
      fShader(),
      '#define NUM_SAMPLES '+this.blurSamples
    )




    // BLUR STUFF
    // ===========



    this.blurKernelA = new Float32Array( this.blurSamples * 3 )
    this.blurKernelB = new Float32Array( this.blurSamples * 3 )

    this.computeKernel( this.blurKernelA, true,  1/this.size )
    this.computeKernel( this.blurKernelB, false, 1/this.size )

    this.blurA_fbo = this.makeBlurFbo(true)
    this.blurB_fbo = this.makeBlurFbo()


  }








  process( inputTex ){

    var blurPrg = this.blurPrg,
        gl = this.gl;
    
    // BLUR PASSES
    // =======================

    gl.clearColor( 0, 0, 0 , 0 )
    this.blurCfg.apply()
    blurPrg.use()
    blurPrg.uSpread( this.spread )

    var quad = this.scene.quad;
    quad.attribPointer( blurPrg );

    // BLURRING PASS H / A
    // =======================

    this.blurA_fbo.bind();
    gl.clear( gl.COLOR_BUFFER_BIT );

    blurPrg.tInput(  inputTex );
    blurPrg.uKernel( this.blurKernelA )

    quad.render()

    // BLURRING PASS V / B
    // =======================

    this.blurB_fbo.bind();
    gl.clear( gl.COLOR_BUFFER_BIT );

    blurPrg.tInput(  this.blurA_fbo.getColor() );
    blurPrg.uKernel( this.blurKernelB )

    quad.render()


  }






  getBlurredTex(){
    // if( this.lowquality ) 
    //   return inputTex
    return this.blurB_fbo.getColorTexture()
  }


  getBlurredFbo(){
    // if( this.lowquality ) 
    //   return inputTex
    return this.blurB_fbo
  }


  
  makeBlurFbo( alpha = false){
    const gl = this.gl;
    
    const fbo = new Fbo( gl );
    fbo.bind();
    fbo.attachColor( alpha?gl.RGBA:gl.RGB, gl.UNSIGNED_BYTE  );
    // fbo.attachDepth( true, false, false );
    fbo.resize( this.size, this.size );
    
    
    const color = fbo.getColor();
    color.bind()
    color.clamp()
    color.setFilter( true, false, false )
    
    return fbo;
  }


  computeKernel( kernel, horiz, dp ) {

    var dda = horiz ? 0:1;
    var ddb = horiz ? 1:0;


    var SQRT_PI = Math.sqrt( Math.PI );

    for( var c = 0, sample = 0; sample < this.blurSamples; ++sample ) {
      var i = sample * 3;

      var delta = sample - Math.round( (this.blurSamples-1)/2);
      var density = 4*delta/this.blurSamples;

      // normal_dens
      density = Math.exp(- density*density / 2.0 ) / SQRT_PI;
      c += density;

      kernel[i + dda] = delta * dp;
      kernel[i + ddb] = 0;
      kernel[i + 2]   = density;
    }

    for (sample = 0; sample < this.blurSamples; ++sample) {
      kernel[3 * sample + 2] /= c;
    }
    
  }


}















import gallery_hotspots, { Hotspot } from "@/store/modules/gallery-hotspots";
import Vue from "vue";
import Component from "vue-class-component";
import GalleryHotspot from "./GalleryHotspot.vue";

@Component({
  components: {
    GalleryHotspot,
  },
})
export default class GalleryHotspots extends Vue {

  get hotspots(): Hotspot[] {
    return gallery_hotspots.list;
  }

}

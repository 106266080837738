






import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class UIBtnIcon extends Vue {
  /**
   * id should be a filename of a .svg in src/assets/icons
   */
  @Prop({ type: String, required: true }) name!: string
}

import _datas from '@/store/datas/wallpapers.json'
import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules'

import store from '@/store'

export type Wallpaper = {
  id: string
  thumb: string
  mobile?: string
  desktop?: string
}

@Module
export class Wallpapers extends VuexModule {
  selected: string = ''

  get items(): Wallpaper[] {
    return _datas
  }

  getSelectedItem(): Wallpaper {
    return _datas.find((item) => item.id === this.selected)
  }

  @Action
  setSelected(id: string) {
    this._SET_SELECTED(id)
  }

  @Action
  reset() {
    this._SET_SELECTED('')
  }

  @Mutation
  _SET_SELECTED(id: string) {
    this.selected = id
  }
}

const wallpapers = new Wallpapers({ store, name: 'wallpapers' })

export default wallpapers

import { Route } from 'vue-router'
import RoutePaths, { RouteNames } from '../Routes'
import About from './About.vue'

export default [
  {
    path: RoutePaths.About,
    name: RouteNames.About,

    components: {
      default: About
    },
    props: {
      default: (route: Route) => ({
        slug: route.params.slug
      })
    }
  }
]

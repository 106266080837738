




import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class GalleryArtworkInformationPanel extends Vue {
  copied: boolean = false
  
  /**
   * Required share url
   */
  @Prop({
    type: String,
    default: "",
    required: true
  })
  url!: string

  share() {
    const url = this.url
    if (navigator.share) {
      navigator
        .share({
          title: this.$t('share.title') as string,
          text: this.$t('share.text') as string,
          url
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.error('Error sharing', error))
    } else {
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            console.log('link copied')
            this.copied = true
          })
          .catch((error) => console.error('Error coping', error))
      } else {
        console.warn('no fallback for navigator.share and navigator.clipboard apis')
      }
    }
  }
}

import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class ScrollTriggerComponentMixin extends Vue {
  tlCollection: gsap.core.Timeline[] = []
  scrollTriggerCollection: ScrollTrigger[] = []

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill()
        tl.clear() && tl.kill()
      })
    }

    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) => scrollTrigger.kill())
    }
  }

  mounted() {
    this.$nextTick(this.initScrollAnimation)
  }

  initScrollAnimation() {}
}
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import gsap from 'gsap'
import { RouteNames } from '@/views/Routes'
import gallery from '@/store/modules/gallery'
import transition, { TRANSITION_DIRECTION } from '@/store/modules/transition'

import GalleryArtworkInformationPanel from '@/components/GalleryArtworkInformationPanel/GalleryArtworkInformationPanel.vue'
import GalleryTutorial from '@/components/GalleryTutorial/GalleryTutorial.vue'
import GalleryTip from '@/components/GalleryTip/GalleryTip.vue'
import GalleryHotspots from '@/components/GalleryHotspots/GalleryHotspots.vue'
import GalleryIntro from '@/components/GalleryIntro/GalleryIntro.vue'
import GalleryOutro from '@/components/GalleryOutro/GalleryOutro.vue'
import GLGallery from '@/components/GLGallery/GLGallery.vue'
import { remapProgress } from '@/webgl/math'
import { easeInCubic, easeOutCubic } from '@/webgl/math/ease'

@Component({
  components: {
    GalleryTip,
    GalleryTutorial,
    GalleryArtworkInformationPanel,
    GalleryHotspots,
    GLGallery,
    GalleryIntro,
    GalleryOutro
  }
})
export default class Gallery extends Vue {
  $isDesktop: boolean

  get showIntro(): boolean {
    return gallery.intro
  }

  get playIntro() {
    return this.$store.state.preloaded && this.$store.state.skipIntro && gallery.intro // && gallery.introCanPlayThrough
  }

  get showTutorial(): boolean {
    return gallery.tutorial
  }

  get moveHint(): boolean {
    return !this.showIntro && !gallery.tutorial && gallery.moveHint && !this.showOutro && !gallery.isArtworkSelected
  }

  get showArtworkInformationPanel(): boolean {
    return gallery.isArtworkSelected && gallery.selectedArtwork.panel != undefined
  }

  get showPanoCopy(): boolean {
    return gallery.showPanoCopy
  }

  get showGenericClose(): boolean {
    return gallery.genericClose
  }

  get outroProgress() {
    return remapProgress(gallery.position - 30, 0.5, 1)
  }

  get showOutro(): boolean {
    return this.outroProgress > 0
  }

  get outroStyle(): string {
    const p = this.outroProgress
    const t = easeInCubic(1 - p)
    return `opacity: ${p}; transform: translateX(${t * 400}px);`
  }

  @Watch('showIntro')
  watchShowIntro(showIntro) {
    if (showIntro) {
      return
    }
    gallery.setTutorial(true)
  }

  @Watch('playIntro', { immediate: true })
  watchPlayIntro(playIntro) {
    if (!playIntro) {
      return
    }
    this.$nextTick(() => {
      ;(<GalleryIntro>this.$refs.intro).play()
    })

    transition.setOpen({ opened: false, options: { showBackdrop: true } })
  }

  beforeRouteEnter(to, from, next) {
    let direction: TRANSITION_DIRECTION = TRANSITION_DIRECTION.HORIZONTAL
    if (from.name === RouteNames.Wallpapers) {
      direction = TRANSITION_DIRECTION.VERTICAL
    }
    transition.setOpen({ opened: true, options: { direction } })
    gsap.delayedCall(1, next)
  }

  enter(el, onComplete) {
    if (!gallery.isArtworkSelected) {
      gallery.setHotspot(true)
    }

    if (!gallery.intro) {
      transition.setOpen({ opened: false, options: { showBackdrop: true } })
    }
    onComplete()
  }

  afterLeave() {
    gallery.setHotspot(false)
  }

  clickGenericClose() {
    gallery.clickGenericClose()
  }

  toggleTutorial() {
    gallery.setTutorial(!gallery.tutorial)
  }

  closeTutorial() {
    gallery.setTutorial(false)
  }

  exitArtwork() {
    gallery.exitArtwork()
  }

  beforeEnterPanel(el: Element) {
    const $ = gsap.utils.selector(el)

    if (this.$isDesktop) {
      gsap.set($('.info-panel-container'), { xPercent: 100 })
    } else {
      gsap.set($('.info-panel-container'), { y: 130 })
    }

    gsap.set($('.info-panel-backdrop'), { visibility: 'hidden' })
    ;(this.$refs.artworkPanel as GalleryArtworkInformationPanel).beforeEnter()
  }

  enterPanel(el: Element, onComplete: gsap.Callback) {
    const $ = gsap.utils.selector(el)
    const tl = gsap.timeline({
      defaults: {
        duration: 1.2,
        ease: 'cubic.inOut'
      },
      onComplete
    })
    if (this.$isDesktop) {
      tl.to($('.info-panel-container'), { xPercent: 0 }, 0).add(
        (this.$refs.artworkPanel as GalleryArtworkInformationPanel).enter(),
        0.6
      )
    } else {
      tl.to($('.info-panel-container'), { y: 0 }, 0)
      tl.eventCallback('onComplete', () => {
        onComplete()
        ;(this.$refs.artworkPanel as GalleryArtworkInformationPanel).afterEnter()
      })
    }

    tl.set($('.info-panel-backdrop'), { visibility: 'visible' })
  }

  leavePanel(el: Element, onComplete: gsap.Callback) {
    gsap.to(el, {
      opacity: 0,
      duration: 0.25,
      ease: 'linear',
      onComplete
    })
  }

  leaveTutorial(el, onComplete) {
    gsap.delayedCall(1, () => {
      onComplete()
    })
  }
}















import icons from '@/assets/icons';
import Vue, { VueConstructor } from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class UIIcon extends Vue {

  /**
   * id should be a filename of a .svg in src/assets/icons
   */
  @Prop({type:String, required:true}) name!: string


  /**
   * color scheme id, if set css 'color' property is set and svg element with `fill="currentColor` will inherit color"
   */
  @Prop({type:String, required:false}) color: string
  

  get colorStyle(): string{
    return (this.color!==undefined)?`color :${this.color}; `:''
  }

  get hasColor(): boolean{
    return this.color!==undefined
  }

  get icon(): VueConstructor<Vue> {
    return icons(this.name);
  }
}


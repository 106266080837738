
/////////////
//////////////////////////////////
//////////

import Lens from "@/webgl/camera/Lens";
import Masks from "@/webgl/gl/masks";
import Scene from "@/webgl/scene";
import { size, times } from "lodash";
import Camera from "nanogl-camera";
import Fbo from "nanogl/fbo";
import Program from "nanogl/program";

import BLIT_VS from './blit.vert'
import BLIT_FS from './blit.frag'
import { LocalConfig } from "nanogl-state";
import { vec2 } from "gl-matrix";
import gallery from "@/store/modules/gallery";
import Paths from "@/core/Paths";
import { TextureSrcSet } from "@/webgl/assets/TextureRequest";
import { TextureResource } from "@/webgl/assets/TextureResource";
import { clamp01 } from "@/webgl/math";
import { getAssetPath } from "../Assets";
import { easeOutQuad } from "@/webgl/math/ease";


// glass height is 70% screen height
const SIZE = .7

export default class Magnifying {

  fbo: Fbo

  camera : Camera<Lens>
  prg: Program;
  cfg: LocalConfig;

  lensPosition: vec2 = vec2.create()

  lensExp = 3.1
  lensZoom = .7
  reflectionTex: any;
  _loaded: boolean;

  opacity = 0

  // enabled = false
  get enabled(){
    const cdist = vec2.length(this.scene.inputs.mouseCoords)
    const p = gallery.position
    return p > 26.95 && p < 27.05 && !gallery.isArtworkSelected && cdist < .3
  } 

  // enabled = false
  get visible(){
    return this.opacity > 0
  }

  get finalSize(){
    return SIZE * .7 + SIZE*.3 * easeOutQuad( this.opacity )
  }

  constructor( private scene:  Scene ){

    const gl = scene.gl
    this.fbo = new Fbo( scene.gl )
    this.fbo.attachColor()
    this.fbo.attachDepth( true, false )
    this.fbo.resize( 16, 16 )

    this.prg = new Program( scene.gl, BLIT_VS(), BLIT_FS() )

    this.cfg = scene.glstate.config()
      .enableBlend()
      .blendFunc( gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA )


    
    const camera = new Camera( new Lens() );
    camera.lens.setAutoFov(.03) //80
    camera.lens.near = .1
    camera.lens.far = 100
  
    scene.root.add(camera)
    this.camera = camera

/////////////////
///////////////////////////////////
///////////////////////////////////
//////////////
  }


  async load(  ){
    const request = new TextureSrcSet( [
      ['webp', getAssetPath("lens_reflection.jpg.webp")],
      ['std',  getAssetPath("lens_reflection.jpg")],
    ])
    const loader = new TextureResource( request, this.scene )
    this.reflectionTex = await loader.load()
    this._loaded = true
  }

  
  
  preRender(){

    const prevOpacity = this.opacity

    if( this.enabled ){
      this.opacity += this.scene.dt / .3
    } else {
      this.opacity -= this.scene.dt / .3
    }
    this.opacity = clamp01( this.opacity)

    if( prevOpacity > 0 ) 
      this.scene.invalidate()

    if( !this.visible ) return

    this.scene.invalidate()
    
    const w = this.scene.glview.width
    const h = this.scene.glview.height
    // this.lensPosition.set(this.scene.inputs.mouseCoords)
    vec2.lerp( this.lensPosition, this.lensPosition, this.scene.inputs.mouseCoords, .2)
    
    this.fbo.resize( w, h )
    this.camera.lens.near = 1
    this.camera.lens.far = 20
    this.camera.setMatrix( this.scene.camera._matrix )
    this.camera.updateWorldMatrix()
    this.camera.updateViewProjectionMatrix( w, h )
  }

  rttPass(){
    if( !this.visible ) return
    
    this.fbo.getColorTexture().setFilter( true, false )
    this.fbo.getColorTexture().clamp()
    this.fbo.bind()
    this.fbo.defaultViewport()
    this.fbo.clear()
    this.scene.gallery.render( this.camera, Masks.OPAQUE )
    this.scene.gallery.render( this.camera, Masks.BLENDED )

  }

  render(){
    if( !this.visible || ! this._loaded ) return
    

    const w = this.scene.glview.width
    const h = this.scene.glview.height
    const sizeInPX = this.finalSize * h
    const scaleX = sizeInPX / w
    
    this.cfg.apply()
    this.prg.use()
    this.prg.tTex( this.fbo.getColorTexture() )
    this.prg.uResolution( w, h )
    this.prg.uTransform( 1*scaleX, 1*this.finalSize, this.lensPosition[0], this.lensPosition[1] )
    this.prg.uLensCenter( (this.lensPosition[0]*.5+.5)*w, (this.lensPosition[1]*.5 + .5)*h )
    this.prg.uLensRadius( sizeInPX/2 )
    this.prg.uLensParams( this.lensExp, 1- 1/this.lensZoom )
    this.prg.tLensReflTex( this.reflectionTex )
    this.prg.uOpacity( this.opacity )

    this.scene.quad.bind()
    this.scene.quad.render()

  }

}
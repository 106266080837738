
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import ageGate from '@/store/modules/age-gate'
import transition from '@/store/modules/transition'
import menu from '@/store/modules/menu'
import sound from '@/store/modules/sound'

import AppAgeGate from '@/components/AppAgeGate/AppAgeGate.vue'
import AppTransition from '@/components/AppTransition/AppTransition.vue'
import AppPreloader from '@/components/AppPreloader/AppPreloader.vue'
import AppIntro from '@/components/AppIntro/AppIntro.vue'
import AppNav from '@/components/AppNav/AppNav.vue'
import { AudioManager } from './core/Audio'
import gallery from './store/modules/gallery'
import { RouteNames, getRoutePathByName } from '@/views/Routes'
import FrontResources from '@/core/FrontResources'

@Component({
  components: {
    AppAgeGate,
    AppTransition,
    AppPreloader,
    AppIntro,
    AppNav
  }
})
export default class App extends Vue {
  get showRouterView(): boolean {
    return (
      (this.$store.state.skipIntro && this.$route.name !== RouteNames.Gallery) ||
      this.$route.name === RouteNames.Gallery
    )
  }

  get showAgeGate(): boolean {
    return !ageGate.legalAge
  }

  get showPreloader(): boolean {
    return !this.showAgeGate && !this.$store.state.preloaded
  }

  get showIntro(): boolean {
    return !this.showAgeGate && this.$store.state.preloaded && !this.$store.state.skipIntro
  }

  get ready(): boolean {
    return !this.showAgeGate && !this.showPreloader && !this.showIntro
  }

  get showTransition(): boolean {
    return transition.opened
  }

  get showNav(): boolean {
    return menu.opened
  }

  get showAudioHint(): boolean {
    return sound.needUserAction
  }

  get showPanoHint(): boolean {
    return gallery.showPanoHint
  }

  @Watch('showAgeGate', { immediate: true })
  watchShowAgeGate(showAgeGate) {
    if (!showAgeGate) {
      FrontResources.load()
      AudioManager.start()
    }
  }

  @Watch('showPreloader', { immediate: true })
  watchShowPreloader(showPreloader) {
    if (showPreloader) {
    }
  }

  created() {
    if (this.$route.name !== RouteNames.Gallery) {
      this.$router.push(getRoutePathByName(RouteNames.Gallery))
    }
  }
}

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import SplitText from '@/utils/SplitText'

ScrollTrigger.defaults({
  markers: process.env.VUE_APP_DEV_SCROLL_MARKERS === 'true'
})

gsap.registerPlugin(
  ScrollTrigger,
  ScrollToPlugin,
  SplitText
)
import audio_state from "@/store/modules/sound";
import { Howl, Howler } from "howler";
import Delay from "./Delay";
import Paths from "./Paths";





export const HowlerUtils = {

  loadHowl( sound:Howl ) : Promise<void> {
    return new Promise( (resolve)=>{
      sound.once('load', resolve);
      sound.load()
    })

  }
}

const NUM_CLICS = 8

const minorRange = [
  [13235,20293],
  [41470,48528],
  [69705,76763],
  [97941,104999],
] as const


export class AudioManager {
  static vines: Howl;
  static ready: boolean;
  
  
  static playVines(b: boolean) {
    if( !this.ready) return
    this.mainTrack.fade( this.mainTrack.volume(), b?0:1, 2000)
    this.vines.fade( this.vines.volume(), b?1:0, 2000)
  }

  static mainTrack: Howl;
  static clics: Howl[] = null;
  static currentClic = 0

  static lowpassFilter: BiquadFilterNode;
  static lpGain: GainNode;



  static async start(){
    Howler.autoUnlock = true

    this.startMainTrack()
    this.loadClics()


    audio_state.$watch(
      (m)=>m.mainVolume,
      this.onMainVolumeChange
    )


    audio_state.$watch(
      (m)=>m.mainLowPass,
      (mainLowPass:number)=>{
        const p = Math.pow( audio_state.mainLowPass, 1/2.2)
        this.lowpassFilter.frequency.setValueAtTime( 100 + (1-p) * 10000 , Howler.ctx.currentTime);
      }
    )

    this.onMainVolumeChange()

  }

  static playClic(){
    
    if( !this.clics ) return

    const t = this.mainTrack.seek()*1000

    const isminor = minorRange.some( ([s,e])=> (t>s && t<e) )
    const offset = isminor?4:0
    
    this.clics[this.currentClic%(NUM_CLICS/2)+offset].play()
    this.currentClic++
  }

  static waitContextReady() : Promise<void>{

    if( Howler.ctx.state === 'running') {
      return Promise.resolve()
    }
    // console.log('cant play audio, wait user gesture' );
    audio_state.setNeedUserAction(true)
    
    return new Promise( (resolve)=>{
      
      const stateChangehandler = ()=>{
        if( Howler.ctx.state === 'running'){
          resolve(0)
          Howler.ctx.removeEventListener( 'statechange',  stateChangehandler)
          audio_state.setNeedUserAction(false)
          // console.log('audio now running' );
        }
      }
      Howler.ctx.addEventListener( 'statechange',  stateChangehandler)
      stateChangehandler()
    }).then()
  }


  static onMainVolumeChange(){
    if(!Howler.masterGain) return
    const gain = Howler.masterGain.gain
    gain.setValueAtTime( gain.value, Howler.ctx.currentTime)
    gain.linearRampToValueAtTime(audio_state.mainVolume, Howler.ctx.currentTime+.3)
  }

  static async loadClics(){
    const clics = []
    for (let i = 0; i < NUM_CLICS; i++) {
      
      clics[i] = new Howl({
        src: Paths.resolve(`assets/audio/clic_0${i+1}.mp3`)
      });
      
    }

    const all = clics.map( HowlerUtils.loadHowl )
    await Promise.all( all )
    
    this.clics = clics

  }

  static async startMainTrack(){

    this.mainTrack = new Howl({
      src: Paths.resolve('assets/audio/main_soundtrack.mp3'),
      loop:true
    });


    this.vines = new Howl({
      src: Paths.resolve('assets/audio/vines.mp3'),
      loop:true
    });

    await (HowlerUtils.loadHowl(this.mainTrack))
    await (HowlerUtils.loadHowl(this.vines))
    await this.waitContextReady();
    (Howler as any).state = "running"
    // this.mainTrack.volume(0)
    this.mainTrack.play()

    let xxx = this.mainTrack as any

    
    const node : GainNode = (this.mainTrack as any)._sounds[0]._node
    this.lowpassFilter = Howler.ctx.createBiquadFilter()
    this.lpGain = Howler.ctx.createGain()
    this.lowpassFilter.type = 'lowpass'
    this.lowpassFilter.gain.value = 0
    this.lowpassFilter.frequency.value = 2000

    node.disconnect()
    node.connect( this.lowpassFilter )
    this.lowpassFilter.connect( this.lpGain )
    this.lpGain.connect( Howler.masterGain )

    let t = this.mainTrack as any
    
    // debugger
    t._sounds[0]._node.bufferSource.loopStart = 7.509
    t._sounds[0]._node.bufferSource.loopEnd   = 112.941



    this.vines.play()
    this.vines.volume(0)
    t = this.vines as any
    t._sounds[0]._node.bufferSource.loopStart = 1
    t._sounds[0]._node.bufferSource.loopEnd   = 41

    this.ready = true
  }

}
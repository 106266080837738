import Paths from "@/core/Paths";
import gallery from "@/store/modules/gallery";
import { quat, vec2, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Node from "nanogl-node";
import { InputTouch } from "../lib/inputs";
import { remapProgress } from "../math";
import Scene from "../scene";
import Frame from "./Frame";
import PanoRenderer from "./pano/PanoRenderer";
import Povs, { IPov } from "./Povs";
import { tween, setBezierFunction, Tweenable } from 'shifty';
import { clamp } from "lodash";
import { Howl } from "howler";
import { AudioManager, HowlerUtils } from "@/core/Audio";
import { getAssetPath } from "./Assets";


const DELTA = vec2.create()

const BASE_AUTOROTATE_SPEED = .1// rad/sec
export default class Pov360 extends Frame implements IPov {
  pano: PanoRenderer;
  startRx: number;
  startRy: number;
  startPos = vec2.create();
  isDrag: boolean;
  touch: InputTouch;

  autoRotateSpeed = 0
  autoRotateTween:Tweenable
  autoRotateTimeout: number;
  audio: Howl;


  lowpassAmount = 1
  

  getCameraOffset( out:vec3, rot:quat, camera : Camera<PerspectiveLens> ) : number {
    out[0] = 0.0;
    out[1] = 0.0;
    out[2] = 0.0;
    quat.identity( rot )
    return  -1
  }

  
  constructor( povs : Povs, ref : Node, slide : number, index : number ){
    super( povs, ref, slide, index )

    this.pano = new PanoRenderer( this.scene )
    const tex = this.scene.gallery.matFactory.texs.map.get('vignes')
    tex.response().then(
      ()=>this.pano.setTex(tex.texture)
    )
    
  }

  enter() {

    gallery.showGenericClose( ()=>gallery.exitArtwork() );
    
    this.scene.inputs.onTouchAdded.on(this.onTouchStart)
    this.scene.inputs.onTouchRemoved.on(this.onTouchStop)
    this.startAutoRotate()

    AudioManager.playVines( true )
  }
  

  onTouchStart = (t: InputTouch)=>{
    if( this.touch ) return
    this.touch = t
    this.isDrag = true
    this.startRx = this.pano.rx
    this.startRy = this.pano.ry
    
    this.startPos.set( t.coords )

    clearTimeout( this.autoRotateTimeout )
  }
  
  onTouchStop = (t: InputTouch)=>{
    if( t === this.touch ){
      this.isDrag = false
      this.touch = null
      this.autoRotateTimeout = setTimeout(() => {
        this.startAutoRotate()
      }, 6000);
    }
  }

  startAutoRotate(){
    gallery.setShowPanoHint( true )
    gallery.setShowPanoCopy( true )
    this.autoRotateTween?.stop();

    this.autoRotateTween = new Tweenable();
    this.autoRotateTween.setConfig({
      from:{s:this.autoRotateSpeed, rx:this.pano.rx}, 
      to:{s:1, rx:0}, 
      duration : 2000,
      easing : "easeInOutQuart",
      step:v=>{
        this.autoRotateSpeed = v.s;
        this.pano.rx = v.rx
      }
    })
    this.autoRotateTween.tween()
  }

  stopIdle(){
    this.autoRotateTween?.stop();
    this.autoRotateTween = null
    this.autoRotateSpeed = 0
    gallery.setShowPanoHint( false )
  }
  
  leave() {
    gallery.setShowPanoCopy( false )
    this.scene.inputs.onTouchAdded.off(this.onTouchStart)
    this.scene.inputs.onTouchRemoved.off(this.onTouchStop)
    clearTimeout( this.autoRotateTimeout )
    this.stopIdle()
    AudioManager.playVines( false )
  }
  
  preRender(){
    this.scene.invalidate()
  }

  render(){
    
    this.pano.ry += this.autoRotateSpeed * this.scene.dt * BASE_AUTOROTATE_SPEED
    
    if( this.isDrag ){
      
      this.stopIdle()

      vec2.sub( DELTA, this.touch.coords, this.startPos )
      this.pano.rx = this.startRx + DELTA[1]
      this.pano.ry = this.startRy - DELTA[0]
    }

    this.pano.rx = clamp( this.pano.rx, -Math.PI/2, .9)

    this.pano.opacity = remapProgress( this.scene.xpcam.frameMatMix, .2, 1 )
    this.pano.brightness = 1.0 - remapProgress( this.scene.xpcam.frameMatMix, .5, 1 )
    this.pano.render()
  }

}



















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import gsap from 'gsap'
import SplitText from '@/utils/SplitText'

@Component
export default class GalleryTip extends Vue {
  @Prop({ type: String, required: true }) text!: string
  @Prop({ type: Boolean, default: false }) cursor!: boolean

  enter(el: Element, onComplete: gsap.Callback) {
    const $ = gsap.utils.selector(el)
    const split = new SplitText(this.$refs.content)

    const tl: gsap.core.Timeline = gsap.timeline({
      onComplete
    })
    tl.addLabel('start')
    tl.from(
      el,
      {
        transformOrigin: '100% 50%',
        yPercent: 100,
        rotate: '-10deg',
        opacity: 0,
        duration: 0.85,
        ease: 'back.out'
      },
      'start'
    )
    tl.from(
      $('.background-edge-inner'),
      {
        xPercent: (i, item) => (i % 2 ? -100 : 100),
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
    tl.from(
      split.chars,
      {
        opacity: 0,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: `steps(${split.chars.length})`
      },
      '-=0.3'
    )
  }

  afterEnter(el) {
    const tl: gsap.core.Timeline = gsap.timeline({ repeat: -1, yoyo: true })
    tl.to(el, { y: 10, duration: 1.25, ease: 'linear' })
  }

  leave(el: Element, onComplete: gsap.Callback) {
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.to(el, {
      opacity: 0,
      duration: 0.85,
      ease: 'quint.out'
    })
  }
}

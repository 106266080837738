import { vec3 } from "gl-matrix";
import { loadBytes } from "@/webgl/assets/Net";


const SCALE = 0.01

export default class CameraPath{

  timelines: Timeline[];
  dataurl: string;

  constructor( dataurl : string ) {
    this.dataurl = dataurl;
    this.timelines = [];

    this.loadDatas();
  }


  async loadDatas(){
    try{
      const buffer = await loadBytes( this.dataurl );
      this.parseBuffer( buffer );
    } catch(e){
      console.error( e )
    }
  }
  
  parseBuffer(buffer : ArrayBuffer){
    
    const head = new Uint16Array(buffer, 0, 3)
    
    let numFrames = head[0]
    const subsampling = head[1]
    const numtimelines = head[2]
    const timescale = 1 / subsampling;
    
    // console.log("def",head)

    numFrames *= subsampling;

    for (var i = 0; i < numtimelines; i++) {
      const tl = new Timeline();
      tl.datas = new Float32Array(buffer, 8 + i * (6 * 4 * numFrames), 6 * numFrames);
      tl.timescale = timescale;
      this.timelines.push( tl )
    }

  }

  getPositionAt(pos:vec3, tgt:vec3, frame:number, timeline:number = 0) {

    var t = this.timelines[timeline];
    t.getPositionsAt(pos, tgt, frame);
  }


}






class Timeline {

  timescale: number;
  datas: Float32Array;

  constructor() {
    this.datas = null;
    this.timescale = 1;
  }


  getPositionsAt(pos: vec3, tgt: vec3, frame: number) {

    frame /= this.timescale;

    const f1 = Math.floor(frame);

    const blend = frame - f1;
    const iblend = 1.0 - blend;

    const i = f1 * 6;
    const dat = this.datas;

    // quat.slerp( Q, poseJoint1.rotation, poseJoint2.rotation, blend )

    pos[0] = SCALE * (dat[i + 0] * iblend + dat[i + 6] * blend);
    pos[1] = SCALE * (dat[i + 1] * iblend + dat[i + 7] * blend);
    pos[2] = SCALE * (dat[i + 2] * iblend + dat[i + 8] * blend);

    tgt[0] = SCALE * (dat[i + 3] * iblend + dat[i + 9] * blend);
    tgt[1] = SCALE * (dat[i + 4] * iblend + dat[i + 10] * blend);
    tgt[2] = SCALE * (dat[i + 5] * iblend + dat[i + 11] * blend);


  }
}

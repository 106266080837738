


























































import Component, { mixins } from 'vue-class-component'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { ScrollTriggerComponentMixin } from '@/mixins/ScrollTriggerComponentMixin'
import SplitText from '@/utils/SplitText'

@Component
export default class AboutSections extends mixins(ScrollTriggerComponentMixin) {
  tlCollection: gsap.core.Timeline[] = []
  scrollTriggerCollection: ScrollTrigger[] = []

  initScrollAnimation() {
    Array.from((<Element>this.$el).querySelectorAll('.about-section-divider'), (el: Element, i: number) => {
      const tl: gsap.core.Timeline = gsap.timeline({
        scrollTrigger: {
          scroller: '#app',
          trigger: el,
          start: 'center center'
        }
      })
      tl.fromTo(
        el,
        {
          transformOrigin: i % 2 ? '0% 50%' : '100% 50%',
          xPercent: i % 2 ? 100 : -100
        },
        {
          xPercent: 0,
          duration: 1.25,
          ease: 'quint.out'
        },
        'start'
      )
      this.tlCollection.push(tl)

      return el
    })

    Array.from((<Element>this.$el).querySelectorAll('.about-section-title'), (el: Element, i: number) => {
      const splitTitle = new SplitText(el, { type: 'lines, words, chars' })

      const tl: gsap.core.Timeline = gsap.timeline({
        scrollTrigger: {
          scroller: '#app',
          trigger: el
          // start: 'bottom center'
        }
      })
      tl.addLabel('start')

      tl.fromTo(
        splitTitle.chars,
        {
          opacity: 0
        },
        {
          opacity: 1,
          stagger: 0.03,
          duration: 0.85,
          snap: {
            opacity: 1
          },
          ease: 'quint.out'
        },
        'start'
      )

      this.tlCollection.push(tl)

      return el
    })

    Array.from((<Element>this.$el).querySelectorAll('.about-section-description'), (el: Element, i: number) => {
      const tl: gsap.core.Timeline = gsap.timeline({
        scrollTrigger: {
          scroller: '#app',
          trigger: el
          // start: 'top bottom'
        }
      })
      tl.addLabel('start')

      tl.fromTo(
        el,
        {
          yPercent: 50
        },
        {
          yPercent: 0,
          duration: 0.85,
          ease: 'quint.out'
        },
        'start'
      )

      this.tlCollection.push(tl)

      return el
    })

    Array.from((<Element>this.$el).querySelectorAll('.about-section-figure'), (el: Element, i: number) => {
      // this.scrollTriggerCollection.push(
      //   ScrollTrigger.create({
      //     trigger: el,
      //     onEnter: () => {
      //       ;(<Element>el).classList.add('st-enter')
      //     }
      //   })
      // )

      const tl: gsap.core.Timeline = gsap.timeline({
        scrollTrigger: {
          scroller: '#app',
          trigger: el,
          scrub: 1,
          // start: 'top bottom',
          // end: 'bottom center',
          onEnter: () => {
            ;(<Element>el).classList.add('st-enter')
          }
        }
      })
      tl.addLabel('start')

      tl.fromTo(
        el.querySelector('img'),
        {
          scale: 1.2,
          yPercent: 20
        },
        {
          yPercent: 0,
          duration: 1
          // ease: 'quint.out'
        },
        'start'
      )

      this.tlCollection.push(tl)

      return el
    })
  }
}





































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import gsap from 'gsap'

import wallpapers from '@/store/modules/wallpapers'
import transition, { TRANSITION_DIRECTION } from '@/store/modules/transition'

import WallpapersSelectView from '@/components/WallpapersSelectView/WallpapersSelectView.vue'
import WallpapersFormView from '@/components/WallpapersFormView/WallpapersFormView.vue'
import WallpapersConfirmView from '@/components/WallpapersConfirmView/WallpapersConfirmView.vue'

@Component({
  components: {
    WallpapersSelectView,
    WallpapersFormView,
    WallpapersConfirmView
  }
})
export default class Wallpapers extends Vue {
  showConfirm: boolean = false
  wallpapersViewsReady: boolean = false

  get hasArworkSelected(): boolean {
    return wallpapers.selected !== ''
  }

  @Watch('hasArworkSelected')
  onArtworkSelectedChange(hasSelected: boolean) {
    !hasSelected && (this.showConfirm = false)
  }

  resetSelection() {
    wallpapers.reset()
  }

  beforeRouteEnter(to, from, next) {
    transition.setOpen({ opened: true, options: { direction: TRANSITION_DIRECTION.VERTICAL, showBackdrop: true } })
    gsap.delayedCall(1, next)
  }

  beforeRouteLeave(to, from, next) {
    this.resetSelection()
    next()
  }

  enter(el: Element, onComplete: gsap.Callback) {
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.add(() => {
      transition.setOpen({ opened: false, options: { fade: true, showBackdrop: false } })
    })
    tl.addLabel('start', '+=0.3')
    tl.from(el, { opacity: 0, duration: 0.35, ease: 'quint.out' })
    tl.from(this.$refs.bgLogo, { opacity: 0, scale: 0, duration: 0.85, ease: 'quint.out' }, 'start')
    tl.add(() => {
      this.wallpapersViewsReady = true
    }, '-=0.65')
  }

  leave(el: Element, onComplete: gsap.Callback) {
    onComplete()
    // const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    // tl.addLabel('start')
    // tl.to(this.$refs.bgLogo, { opacity: 0, scale: 0, duration: 0.85, ease: 'quint.out' }, 'start')
    // tl.to(el, { opacity: 0, duration: 1.2, ease: 'quint.out' }, 'start+=0.35')
  }

  afterLeave() {
    ;(<Element>this.$root.$el).scrollTo(0, 0)
  }
}

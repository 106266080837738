import _datas from '@/store/datas/agenda.json'
import { VuexModule, Module } from 'vuex-class-modules'

import store from '@/store'

export type AgendaEvent = {
  id: string
  title: string
  date: string
  // description: string
  // tickets_url?: string
  // external_url?: string
}

@Module
export class Agenda extends VuexModule {
  get events(): AgendaEvent[] {
    return _datas
  }
}

const agenda = new Agenda({ store, name: 'agenda' })

export default agenda

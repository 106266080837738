

/////////////
//////////////////////////////////
//////////

import Config from 'nanogl-state/config'
import Fbo    from 'nanogl/fbo'
import Blur   from '@/webgl/glsl/blur'
import {mat4} from 'gl-matrix'
import Scene from '@/webgl/scene';
import Plane from '@/webgl/math/plane';
import Camera from 'nanogl-camera';
import { MsaaFbo } from './MsaaFbo';


const M4A   = mat4.create();
const M4B   = mat4.create();



// const SUBSCALE = 4

export default class Reflect {

  viewprojCopy: mat4;
  scene: Scene;
  plane: Plane;
  size: number;
  blur: Blur;
  globalCfg: Config;
  fbo: MsaaFbo;
  msaa: boolean;
  blitFbo: Fbo;

  groundHeight = -.03
  
  constructor( scene:Scene, plane?:Plane ){


    this.viewprojCopy = mat4.create()
    this.scene = scene;
    this.plane = plane;

    this.size = 512;
    this.blur = new Blur( scene, this.size )
    this.blur.spread = 1
    
    this.globalCfg = new Config()
    .frontFace( scene.gl.CW )
    
    const gl = scene.gl;
    
    // gl.getParameter( )
    const fbo = new MsaaFbo( gl, 16 );
    
    // if( isWebgl2(gl))
    // {
    //   this.msaa = true
    //   fbo.attach( gl.COLOR_ATTACHMENT0, new RenderBuffer(gl, gl.RGBA8, 16 ) );
    //   this.blitFbo = new Fbo( gl );
    //   this.blitFbo.attachColor( gl.RGBA, gl.UNSIGNED_BYTE );
    //   this.blitFbo.resize( this.size, this.size );
      
    // } else {
    //   this.msaa = false
    //   fbo.attachColor( gl.RGBA, gl.UNSIGNED_BYTE );
    // }

    // fbo.attachDepth( true, false, false );
    fbo.setSize( this.size, this.size );
    
    
    const color = fbo.getColorTexture()
    color.bind()
    color.clamp()
    color.setFilter( true, false, false )

    this.fbo = fbo;


/////////////////
///////////////////////////////////////////
//////////////

  }


  blitRenderBuffer(){
    this.fbo.blitMsaa();
  }


  prepare( w:number, h:number ){
    var gl = this.scene.gl;
    // console.log(  this.scene.renderer.width, this.scene.renderer.height );
    // this.fbo.resize( nextPOT(w), nextPOT(h) );

    this.fbo.renderFbo.bind();
    gl.viewport( 0, 0, this.size, this.size );
    gl.clearColor( 1, 1, 1, 1 );
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT );

  }


  processOutput(){
    this.blur.process( this.fbo.getColorTexture() )
  }


  getOutput(){
    return this.blur.getBlurredTex()
  }

  getOutputFbo(){
    return this.blur.getBlurredFbo()
  }


  processCamera( cam:Camera ){
    this.viewprojCopy.set( cam._viewProj )

    mat4.identity( M4A )
    M4A[5] = -1
    M4A[13] = 2 * this.groundHeight

    mat4.multiply( M4B, M4A, cam._wmatrix );
    mat4.invert( M4B, M4B );
    mat4.multiply( cam._viewProj, cam.lens.getProjection(), M4B );

  }
  
  restoreCamera( cam:Camera ){
    cam._viewProj.set( this.viewprojCopy );
  }

}
















import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class UICookieSettingsBtn extends Vue {
  get showCookieSettings() {
    return true //process.env.VUE_APP_ENVCONFIG !== 'development'
  }
}

import { Route } from 'vue-router'
import RoutePaths, { RouteNames } from '../Routes'
import Gallery from './Gallery.vue'

export default [
  {
    path: RoutePaths.Gallery,
    name: RouteNames.Gallery,

    components: {
      default: Gallery
    }
  },
  {
    path: RoutePaths.Artwork,
    name: RouteNames.Artwork,

    components: {
      default: Gallery
    },
    props: {
      default: (route: Route) => ({
        slug: route.params.slug
      })
    }
  }
]

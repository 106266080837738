import { render, staticRenderFns } from "./GalleryTutorialStep.vue?vue&type=template&id=2f2d4354&scoped=true&"
import script from "./GalleryTutorialStep.vue?vue&type=script&lang=ts&"
export * from "./GalleryTutorialStep.vue?vue&type=script&lang=ts&"
import style0 from "./GalleryTutorialStep.vue?vue&type=style&index=0&id=2f2d4354&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f2d4354",
  null
  
)

export default component.exports
import _rules from '@/store/datas/ageGateRules.json'
import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules'
import Cookies from 'js-cookie'

import store from '@/store'

const COOKIE_NAME = 'age-gate'

export type AgeGateRule = {
  country_code: string
  country_name: string
  age: number
  allowed_country: string
  input_type: string
  year: number
  month_number: number
  day: number
  langs_list: string
  default_language: string
}

interface IBirthDate {
  d?: number
  m?: number
  y: number
}

const preferedCountryCodes: string[] = ['BE', 'DE', 'IT', 'ES', 'CH', 'GB', 'US']

@Module
export class AgeGate extends VuexModule {
  legalAge = false

  countryCode = 'US'

  birthdate: IBirthDate = {
    d: 1,
    m: 1,
    y: 2018
  }

  rules: AgeGateRule[] = _rules

  get orderedRules(): AgeGateRule[] {
    const filteredRules: AgeGateRule[] = this.rules.filter((rule) => preferedCountryCodes.includes(rule.country_code))
    const otherRules: AgeGateRule[] = this.rules.filter((rule) => !preferedCountryCodes.includes(rule.country_code))
    return [...filteredRules, ...otherRules]
  }

  get selectedRule(): AgeGateRule {
    return this.rules.filter((rule) => rule.country_code === this.countryCode)[0] || null
  }

  get selectedCountryInputType(): string[] {
    return this.selectedRule.input_type.split('')
  }

  get hasLegalAge(): boolean {
    const birthdate: Date = new Date(this.birthdate.y, this.birthdate.m - 1, this.birthdate.d)
    const now: Date = new Date()
    const diff: number = now.getTime() - birthdate.getTime()
    return this.selectedRule.age * (1000 * 60 * 60 * 24 * 365) <= diff
  }

  get isAllowedCountry(): boolean {
    return this.selectedRule.allowed_country !== 'no'
  }

  get userCountryName(): string | boolean {
    const rule: AgeGateRule = this.selectedRule
    if (rule) {
      return rule['country_name']
    }
    return false
  }

  get userBirthdate(): string {
    const countryInputType: string[] = this.selectedCountryInputType
    const bdate: IBirthDate = { y: null, m: null, d: null }

    countryInputType.forEach((type) => {
      bdate[type] = this.birthdate[type]
    })

    const bdateValues: string[] = Object.values(bdate)
    return bdateValues.filter((type) => type !== null).join('.')
  }

  @Action
  selectCountry(countryCode: string) {
    this._SET_COUNTRY_CODE(countryCode)
  }

  @Action
  selectBirthDateYear(year: number) {
    this._SET_BIRTHDATE_YEAR(year)
  }

  @Action
  selectBirthDateMonth(month: number) {
    this._SET_BIRTHDATE_MONTH(month)
  }

  @Action
  selectBirthDateDay(day: number) {
    this._SET_BIRTHDATE_DAY(day)
  }

  @Action
  validateLegalAge(valid = true) {
    this._SET_LEGAL_AGE(valid)
    this.save()
  }

  /**
   * Initialize store from cookie if exists
   */
  @Action
  init() {
    const cookie = Cookies.get(COOKIE_NAME)
    if (!cookie) {
      return
    }

    const { date, country, pass } = JSON.parse(cookie)
    // date format = Y.m.d
    const [y, m, d] = date.split('.')
    d && this._SET_BIRTHDATE_DAY(d)
    m && this._SET_BIRTHDATE_MONTH(m)
    y && this._SET_BIRTHDATE_YEAR(y)

    pass !== undefined && this._SET_LEGAL_AGE(pass)
    country && this._SET_COUNTRY_CODE(country.toUpperCase())
  }

  /**
   * Save results to cookie
   */
  @Action
  save() {
    // {"date":"1987.1.1","country":"fr","language":"fr-e","pass":true,"format":"Y"}
    const datas = {
      date: `${this.birthdate.y}.${this.birthdate.m}.${this.birthdate.d}`,
      country: this.selectedRule.country_code.toLowerCase(),
      language: this.selectedRule.default_language.toLowerCase(),
      pass: this.legalAge,
      format: this.selectedCountryInputType.join('/').replace('m', 'F').replace('y', 'Y')
    }

    Cookies.set(COOKIE_NAME, JSON.stringify(datas))
  }

  @Mutation
  _SET_COUNTRY_CODE(countryCode: string) {
    this.countryCode = countryCode
  }

  @Mutation
  _SET_BIRTHDATE_YEAR(year: number) {
    this.birthdate.y = year
  }

  @Mutation
  _SET_BIRTHDATE_MONTH(month: number) {
    this.birthdate.m = month
  }

  @Mutation
  _SET_BIRTHDATE_DAY(day: number) {
    this.birthdate.d = day
  }

  @Mutation
  _SET_LEGAL_AGE(valid: boolean) {
    this.legalAge = valid
  }
}

const ageGate = new AgeGate({ store, name: 'age-gate' })
ageGate.init()

/////////////
//////////////////////////////////////////////////////
////////////////////////////////
 
//////////

export default ageGate

import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class Resources extends VuexModule {
  progress: number = 0
  
  @Action
  increaseProgress(payload: number = 1) {
    this._INC_PROGRESS(payload)
    // console.log( this.progress + '/' + this.total )
  }

  @Mutation
  private _INC_PROGRESS(progress: number = 1) {
    this.progress+=progress
  }

  total: number = 0

  @Action
  increaseTotal(payload: number) {
    this._INC_TOTAL(payload)
  }

  @Mutation
  private _INC_TOTAL(payload: number) {
    this.total+= payload
  }
}

const resources = new Resources({ store, name: 'resources' })

export default resources


















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import 'vue-recaptcha-v3'
import gsap from 'gsap'
import SplitText from '@/utils/SplitText'

import wallpapers from '@/store/modules/wallpapers'
import { Wallpaper } from '@/store/modules/wallpapers'
import viewport from '@/store/modules/viewport'
import ageGate from '@/store/modules/age-gate'
import Paths from '../../core/Paths'

@Component
export default class WallpapersFormView extends Vue {
  showBackBtn: boolean = false

  firstname: string = ''
  lastname: string = ''
  email: string = ''
  accept: string = ''
  pending: boolean = false

  get selectedItem(): Wallpaper {
    return wallpapers.getSelectedItem()
  }

  backToSelection() {
    wallpapers.reset()
  }

  async submit() {
    this.pending = true

    await this.$recaptchaLoaded()

    try {
      await this.sendForm()
      this.$emit('confirm')
    } catch (error) {
      console.log(error.message)
    }

    this.pending = false
  }

  async sendForm() {
    const token = await this.$recaptcha('submit')

    const data = {
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      birthdate: ageGate.userBirthdate,
      country: ageGate.userCountryName,
      wallpaper: this.selectedItem.id,
      accept: this.accept,
      token // Google reCaptcha token
    }

    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }

    const response = await fetch(`${process.env.VUE_APP_API_BASE_PATH}/wallpapers`, settings)

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`
      throw new Error(message)
    }
  }

  enter(el: Element, onComplete) {
    const title: Element = el.querySelector('.wallpapers-form-view-title')
    const splitTitle = new SplitText(title, { type: 'lines,words,chars' })
    let items: Element[] = Array.from(el.querySelectorAll('.wallpapers-form-view-item-selected'))
    items = [...items, ...Array.from(el.querySelectorAll('.wallpapers-form-view-verso'))]
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(el, { opacity: 0, duration: 0.35, ease: 'quint.out' })
    tl.fromTo(
      splitTitle.chars,
      {
        yPercent: 100,
        opacity: 0
      },
      {
        yPercent: 0,
        opacity: 1,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.fromTo(
      items,
      {
        x: (index) => {
          return gsap.utils.interpolate([-50, 50], index / (items.length - 1))
        },
        y: () => viewport.windowHeight,
        rotate: (index) => {
          return `${gsap.utils.interpolate([15, -15], index / (items.length - 1))}deg`
        },
        opacity: 0
      },
      {
        x: 0,
        y: 0,
        rotate: 0,
        opacity: 1,
        stagger: {
          each: 0.2,
          from: 'center',
          axis: 'x'
        },
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.from(
      el.querySelector('.wallpapers-form-view-caption'),
      {
        yPercent: 100,
        opacity: 0,
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.add(() => {
      this.showBackBtn = true
    }, 'start+=0.3')
  }

  leave(el: Element, onComplete) {
    const title: Element = el.querySelector('.wallpapers-form-view-title')
    const splitTitle = new SplitText(title, { type: 'lines,words,chars' })
    let items: Element[] = Array.from(el.querySelectorAll('.wallpapers-form-view-item-selected'))
    items = [...items, ...Array.from(el.querySelectorAll('.wallpapers-form-view-verso'))]
    const backBtn: Element = el.querySelector('.back-btn')
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.to(
      backBtn,
      {
        opacity: 0,
        duration: 0.35
      },
      'start'
    )
    tl.to(
      splitTitle.chars,
      {
        yPercent: -100,
        opacity: 0,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.to(
      items,
      {
        x: (index) => {
          return gsap.utils.interpolate([-50, 50], index / (items.length - 1))
        },
        y: () => -viewport.windowHeight,
        rotate: (index) => {
          return `${gsap.utils.interpolate([-15, 15], index / (items.length - 1))}deg`
        },
        opacity: 0,
        stagger: {
          each: 0.2,
          from: 'center',
          axis: 'x'
        },
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.to(
      el.querySelector('.wallpapers-form-view-caption'),
      {
        opacity: 0,
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.3'
    )
    tl.to(el, { opacity: 0, duration: 0.35, ease: 'quint.out' })

    gsap.set(backBtn, { pointerEvents: 'none' })
  }

  presolve(p){
    return Paths.resolve(p)
  }
}

import { Route } from 'vue-router'
import RoutePaths, { RouteNames } from '../Routes'
import Edito from './Edito.vue'

export default [
  {
    path: RoutePaths.Edito,
    name: RouteNames.Edito,

    components: {
      default: Edito
    },
    props: {
      default: (route: Route) => ({
        slug: route.params.slug
      })
    }
  }
]

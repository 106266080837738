


























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import gsap from 'gsap'

@Component
export default class UIBtnRound extends Vue {
  /**
   * Optional route passed to `$router.push()`
   */
  @Prop({
    type: [String, Object],
    default: undefined
  })
  to!: RawLocation

  /**
   * Optional external link
   */
  @Prop({
    type: String,
    default: undefined
  })
  toExternal!: string

  /**
   * disabled btn
   */
  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  /**
   * Run an enter animation
   * can use a delay as param
   */
  @Prop({})
  appear!: number

  tl: gsap.core.Timeline

  get _isdisabled() {
    return this.disabled
  }

  beforeDestroy() {
    if (this.tl) {
      this.tl.eventCallback('onComplete', null)
    }
  }

  mounted() {
    if (this.appear) this._enterAnimation()
  }

  _enterAnimation() {
    this.tl = gsap.timeline()
    this.tl.from(this.$el, { scale: 0, rotate: '-10deg', duration: 0.85, ease: 'back.out' })
  }

  _onClick(e: Event, navigate: Function) {
    if (this.to) {
      navigate(e)
    }

    // if external link
    if (this.toExternal) {
      window.open(this.toExternal, '_blank')
    }

    this.$emit('click')
  }
}

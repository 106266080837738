




















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Paths from '@/core/Paths'
import gsap from 'gsap'
import SplitText from '@/utils/SplitText'
import lottie, { AnimationItem } from 'lottie-web'

import { Hotspot } from '@/store/modules/gallery-hotspots'
import gallery from '@/store/modules/gallery'

@Component({
  components: {}
})
export default class GalleryHotspot extends Vue {
  iconAnimation: AnimationItem
  hover: boolean = false
  hoverTL: gsap.core.Timeline

  @Prop({ type: Object, required: true }) hotspot!: Hotspot

  get icon(): string {
    return this.hotspot.icon
  }

  get label(): string {
    return this.hotspot.text
  }

  get style(): string {
    return `transform: translate( calc(${this.hotspot.screen.x * 100}vw), calc(${this.hotspot.screen.y * 100}vh ) );`
  }

  get visible(): boolean {
    return this.hotspot.visible
  }

  get culled(): boolean {
    return Math.abs(this.hotspot.slide - gallery.position) > 1
  }

  get show(): boolean {
    return !this.culled && !gallery.isArtworkSelected && gallery.hotspot
  }

  @Watch('hover')
  watchHover(hover: boolean) {
    this.mouseHover(hover)
  }

  // @Watch('povHover')
  // watchPovHover(hover) {
  //   this.mouseHover(hover)
  // }

  enter(el: Element, onComplete) {
    this.iconAnimation = lottie.loadAnimation({
      container: this.$refs.icon as Element,
      renderer: 'svg',
      autoplay: false,
      loop: true,
      // path: Paths.resolve('./assets/lottie/icon-enable-sound.json')
      path: Paths.resolve(`./assets/lottie/icon-${this.icon}.json`)
    })

    const split = new SplitText(this.$refs.label, { type: 'chars' })

    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(
      (this.$refs.bubble as Vue).$el,
      {
        scale: 0,
        rotate: '-10deg',
        duration: 0.85,
        ease: 'back.out'
      },
      'start'
    )
    tl.from(
      split.chars,
      {
        opacity: 0,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: `steps(${split.chars.length})`
      },
      'start'
    )
    tl.from(
      this.$refs.icon,
      {
        opacity: 0,
        scale: 0,
        rotate: '-45deg',
        duration: 0.65,
        ease: 'quint.inOut'
      },
      'start+=0.2'
    )
    tl.add(() => {
      this.iconAnimation.play()
    }, 'start+=0.4')
  }

  afterEnter() {
    this.hoverTL = gsap.timeline({ paused: true })
    this.hoverTL.addLabel('start')
    this.hoverTL.to((<Vue>this.$refs.bubble).$el, { scale: 1.1, duration: 0.65, ease: 'back.inOut' }, 'start')
    this.hoverTL.to(this.$refs.label, { y: 10, duration: 0.65, ease: 'back.inOut' }, 'start')
  }

  leave(el: Element, onComplete) {
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.to(el, { opacity: 0, scale: 0, duration: 0.35, ease: 'quint.in' })
  }

  async onClick() {
    this.hotspot.handler()
  }

  onMEnter() {
    this.hover = true
  }

  onMLeave() {
    this.hover = false
  }

  mouseHover(hover) {
    if (!this.hoverTL) {
      return
    }
    if (hover) {
      this.hoverTL.play()
    } else {
      this.hoverTL.reverse()
    }
  }
}

import Vue from 'vue'

// import '@/core/bugsnag'
import '@/components'
import 'vue-select/dist/vue-select.css'
import '@/styles/index.styl'
import '@/core/GSAPPlugins'

import App from '@/App.vue'
import router from '@/core/router'
import { RouteConfig } from 'vue-router'
import store from '@/store'
import i18n from '@/core/i18n'

import vSelect from 'vue-select'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueYouTubeEmbed from 'vue-youtube-embed'

import ViewsRoutes from '@/views'
import FrontResources from '@/core/FrontResources'

Vue.config.productionTip = false

Vue.component('v-select', vSelect)
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY || '',
  loaderOptions: {
    autoHideBadge: true
  }
})

Vue.use(VueYouTubeEmbed, {
  componentId: 'youtube-player',
  defer: Promise.resolve()
})


ViewsRoutes.forEach((r: RouteConfig) => router.addRoute(r))

Vue.mixin({
  computed: {
    $isDesktop(): boolean {
      return window.matchMedia('(min-width: 1050px)').matches
    }
  }
})


FrontResources.setupVideos()
FrontResources.setupLotties()
FrontResources.setupImages()

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
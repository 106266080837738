























































import Component, { mixins } from 'vue-class-component'
import gsap from 'gsap'
import { ScrollTriggerComponentMixin } from '@/mixins/ScrollTriggerComponentMixin'
import SplitText from '@/utils/SplitText'

import viewport from '@/store/modules/viewport'

@Component
export default class AboutArtFairs extends mixins(ScrollTriggerComponentMixin) {
  tlCollection: gsap.core.Timeline[] = []
  scrollTriggerCollection: ScrollTrigger[] = []

  initScrollAnimation() {
    const title = (<Element>this.$el).querySelector('.about-art-fairs-title')
    const splitTitle = new SplitText(title, { type: 'lines, words, chars' })

    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: title
            // start: 'bottom center'
          }
        })
        .fromTo(
          splitTitle.chars,
          {
            opacity: 0
          },
          {
            opacity: 1,
            stagger: 0.03,
            duration: 0.85,
            snap: {
              opacity: 1
            },
            ease: 'quint.out'
          }
        )
    )

    const caption = (<Element>this.$el).querySelector('.about-art-fairs-caption')
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: caption
            // start: 'bottom center'
          }
        })
        .fromTo(
          caption,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.85,
            ease: 'quint.out'
          }
        )
    )
    const galleryItemContainer = (<Element>this.$el).querySelector('.about-art-fairs-gallery')
    const galleryItemCollection = Array.from((<Element>this.$el).querySelectorAll('.about-art-fairs-gallery-item'))
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: galleryItemCollection[0],
            // scrub: 1,
            // start: 'center bottom-=10%',
            // end: 'bottom center'
          }
        })
        .fromTo(
          galleryItemCollection,
          {
            // x: 0
            xPercent: 100,
          },
          {
            // x: (): number => {
            //   const len: number = galleryItemCollection.length
            //   return viewport.windowWidth - galleryItemCollection[len - 1].getBoundingClientRect().right
            // },
            xPercent: 0,
            stagger: 0.1,
            duration: 1,
            ease: 'quint.inOut'
          }
        )
    )

    const content = (<Element>this.$el).querySelector('.about-art-fairs-content')
    const agendaCTA = (<Element>this.$el).querySelector('.about-art-fairs-content-cta')
    this.tlCollection.push(
      gsap
        .timeline({
          scrollTrigger: {
            scroller: '#app',
            trigger: content
            // start: 'bottom center'
          }
        })
        .addLabel('start')
        .fromTo(
          content,
          {
            opacity: 0
          },
          {
            opacity: 1,
            duration: 0.85,
            ease: 'quint.out'
          }
        )
      // .fromTo(
      //   agendaCTA,
      //   {
      //     opacity: 0,
      //     yPercent: 100,
      //   },
      //   {
      //     opacity: 1,
      //     yPercent: 0,
      //     duration: 0.85,
      //     ease: 'quint.out'
      //   },
      //   'start'
      // )
    )
  }
}

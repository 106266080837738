import { RouteConfig } from 'vue-router'

import about from './about'
import agenda from './agenda'
import edito from './edito'
import gallery from './gallery'
import wallpapers from './wallpapers'

const Routes: RouteConfig[] = [
  ...about,
  ...agenda,
  ...gallery,
  ...wallpapers,
  ...edito,
]

export default Routes

import { quat, vec3 } from "gl-matrix";
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import Frame from "./Frame";
import { IPov } from "./Povs";



const DIST = 6
export default class PovEnclosed extends Frame implements IPov {
  
  lowpassAmount = 1
  paralaxStrength = 0

  transitionDuration():number {
    return 2500
  }

  getCameraOffset( out:vec3, rot: quat, camera : Camera<PerspectiveLens> ) : number {

    const frameW = this.ref.scale[0]*Frame.FRAME_EXTENTS
    const frameH = this.ref.scale[1]*Frame.FRAME_EXTENTS

    let vpBaseSizeW:number
    let vpBaseSizeH:number
    const frameAspect = frameW/frameH;
    const viewportAspect = this.scene.ratio
    
    
    const destopMode = viewportAspect > 1

    quat.identity( rot )

    out[0] = 0
    out[1] = 0
    out[2] = -DIST

    let vfov = 40

    if( destopMode ){

      // fit artwork on the half left side of the screen

      if( frameAspect < viewportAspect*.5 ){
        
        // vp wider then frame, fit height
        vfov = 2* Math.atan( .5*frameH / DIST )
        
      } else {
        // vp higher then frame, fit width
        vpBaseSizeW =  frameW*2
        const hfov = 2* Math.atan( frameW / DIST )
        vfov = hfov/viewportAspect
      }
      
      const ry = camera.lens._hfov /4
      quat.rotateY( rot, rot, -ry )
      
      
      
    } else {

      if( frameAspect < viewportAspect ){
        
        // vp wider then frame, fit height
        vpBaseSizeH =  frameH
        vpBaseSizeW =  vpBaseSizeH * viewportAspect;
        
        // out[2] = -.5*vpBaseSizeH / Math.tan( camera.lens._vfov/2 );
        vfov = 2* Math.atan( .5*vpBaseSizeH / DIST )
        
      } else {
        // vp higher then frame, fit width
        vpBaseSizeW =  frameW
        vpBaseSizeH =  vpBaseSizeW / viewportAspect;
        
        // out[2] = -.5*vpBaseSizeW / Math.tan( camera.lens._hfov/2 );

        const hfov = 2* Math.atan( .5*vpBaseSizeW  / DIST )
        vfov = hfov/viewportAspect
      }
      
      const rx = (camera.lens._vfov * (vpBaseSizeH - frameH ) /2) / vpBaseSizeH
      quat.rotateX( rot, rot, -rx )
      // out[1] = (vpBaseSizeH - frameH ) /2

    }

    return vfov

  }

  


}



























import Vue from 'vue'
import Component from 'vue-class-component'
import gsap from 'gsap'
import gallery from '@/store/modules/gallery'

import GalleryTutorialStep from './GalleryTutorialStep.vue'

import galleryTutorialSteps from '@/store/datas/gallery-tutorial-steps.json'

type tutorialStep = {
  id: string
}

@Component({
  components: {
    GalleryTutorialStep
  }
})
export default class GalleryTutorial extends Vue {
  stepIndex: number = 0

  get tutorialSteps(): tutorialStep[] {
    return galleryTutorialSteps
  }

  get currentTutorialStep(): tutorialStep {
    return this.tutorialSteps[this.stepIndex]
  }

  onStepEnd(e) {
    if (this.stepIndex < this.tutorialSteps.length - 1) {
      this.stepIndex += 1
      return
    }
    this.stepIndex = 0
  }

  enter(el: Element, onComplete) {
    const tl = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(el, { opacity: 0, scale: 0.3, duration: 0.85, ease: 'quint.inOut' }, 'start')
    tl.from(this.$refs.background, { scaleY: 0.5, duration: 0.85, ease: 'quint.out' }, 'start')
  }

  leave(el: Element, onComplete) {
    const tl = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.to(el, { opacity: 0, duration: 0.45, ease: 'quint.out' }, 'start')
  }

  close() {
    gallery.setTutorial(false)
  }
}



























import Vue from 'vue'
import Component from 'vue-class-component'
import gsap from 'gsap'

import transition, { TRANSITION_DIRECTION } from '@/store/modules/transition'
import menu from '@/store/modules/menu'
import viewport from '@/store/modules/viewport'

import AboutSections from './AboutSections.vue'
import AboutBio from './AboutBio.vue'
import AboutArtFairs from './AboutArtFairs.vue'

@Component({
  components: {
    AboutSections,
    AboutBio,
    AboutArtFairs
  }
})
export default class About extends Vue {
  tlCollection: gsap.core.Timeline[] = []

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill()
        tl.clear() && tl.kill()
      })
    }
  }

  beforeRouteEnter(to, from, next) {
    if (menu.opened) {
      return next()
    }

    transition.setOpen({ opened: true, options: { direction: TRANSITION_DIRECTION.HORIZONTAL } })
    gsap.delayedCall(1, next)
  }

  enter(el, onComplete) {
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.add(() => {
      transition.setOpen({ opened: false, options: { showBackdrop: true } })
    })
    tl.addLabel('start', '+=1')
    tl.from(
      [this.$refs.headerCover, this.$refs.headerCoverItem],
      {
        yPercent: (i) => (i % 2 ? 120 : -110),
        duration: 1.15,
        ease: 'quint.out'
      },
      'start'
    )

    const headerContentBCR: ClientRect = (<Element>this.$refs.headerContent).getBoundingClientRect()
    const scaleX: number = (viewport.windowWidth / headerContentBCR.width) * 2
    const scaleY: number = (viewport.windowHeight / headerContentBCR.height) * 2
    tl.fromTo(
      this.$refs.headerContent,
      {
        transformOrigin: '50%, 75%',
        scaleX,
        scaleY
      },
      {
        scaleX: 1,
        scaleY: 1,
        duration: 1.15,
        ease: 'quint.out'
      },
      'start'
    )
    tl.from(
      [this.$refs.headerContentLogo, this.$refs.headerContentDescription],
      {
        yPercent: 100,
        opacity: 0,
        duration: 0.85,
        stagger: 0.1,
        ease: 'quint.out'
      },
      '-=0.2'
    )
  }

  afterEnter() {
    const tl: gsap.core.Timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: <Element>this.$refs.headerCover,
        scrub: 1,
        start: 'top top'
      }
    })
    tl.addLabel('start')
    tl.to(
      this.$refs.headerCoverItem,
      {
        yPercent: -10,
        scale: 1.1,
        duration: 1
      },
      'start'
    )

    this.tlCollection.push(tl)
  }

  afterLeave() {
    ;(<Element>this.$root.$el).scrollTo(0, 0)
  }
}

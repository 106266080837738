










import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Paths from '@/core/Paths'
import gsap from 'gsap'
import SplitText from '@/utils/SplitText'
import lottie, { AnimationItem } from 'lottie-web'

import viewport from '@/store/modules/viewport'

export type HintModel = {
  icon: string
  content: string
}

@Component
export default class UIHint extends Vue {
  iconAnimation: AnimationItem

  @Prop({ type: Object, required: true }) model!: HintModel

  get otClientRect(): ClientRect {
    return viewport.otClientRect
  }

  @Watch('otClientRect')
  watchOtClientRect() {
    this.resize()
  }

  mounted() {
    viewport.getOTClientRect()
  }

  resize() {
    if (viewport.otClientRect) {
      gsap.set(this.$el, { bottom: viewport.otClientRect.bottom - viewport.otClientRect.top + 30 })
    }
  }

  enter(el, onComplete) {
    this.resize()

    this.iconAnimation = lottie.loadAnimation({
      container: this.$refs.icon as Element,
      renderer: 'svg',
      autoplay: false,
      loop: true,
      path: Paths.resolve(`./assets/lottie/icon-${this.model.icon}.json`)
    })

    const split = new SplitText(this.$refs.content)

    const tl = gsap.timeline({
      onComplete
    })
    tl.addLabel('start')
    tl.from(
      el,
      {
        transformOrigin: '100% 50%',
        y: 60,
        rotate: '-10deg',
        opacity: 0,
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
    tl.from(
      this.$refs.background,
      {
        transformOrigin: '50% 50%',
        scaleY: 0.5,
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
    tl.from(
      split.chars,
      {
        opacity: 0,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: `steps(${split.chars.length})`
      },
      'start'
    )
    tl.add(() => {
      this.iconAnimation.play()
    }, 'start+=0.4')
  }

  leave(el, onComplete) {
    const tl = gsap.timeline({ onComplete })
    tl.to(el, {
      opacity: 0,
      duration: 0.85,
      ease: 'quint.out'
    })
  }
}

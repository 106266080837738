import Vue from "vue"
import router from '@/core/router'
import RoutePaths from '@/views/Routes'

import datas from '../datas/hotspots.json'
import gallery from "./gallery"

export type Hotspot = {

  // position: vec3
  pos: number[]

  id: string
  
  icon : string
  
  text : string
  
  slide: number


  screen: {x:number, y:number}

  visible: boolean

  handler : ()=>void

}


function createHotspots() : Hotspot[] {
  return datas.map( o=> Object.assign({}, o, {
    screen:{x:0, y:0},
    visible:false,
    handler(){
      const id = (this as Hotspot).id
      if( id === 'card' ){
        gallery.setHotspot(false)
        router.push(RoutePaths.Wallpapers)
      } else if( id === "well" ){
        gallery.selectArtwork( 29 )
      }
    }
  }))
}


export type HotspotsStore = {
  list:Hotspot[]
}


const gallery_hotspots:HotspotsStore = Vue.observable({
  list: createHotspots()
})


export default gallery_hotspots


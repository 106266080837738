import { Route } from 'vue-router'
import RoutePaths, { RouteNames } from '../Routes'
import Wallpapers from './Wallpapers.vue'

export default [
  {
    path: RoutePaths.Wallpapers,
    name: RouteNames.Wallpapers,

    components: {
      default: Wallpapers
    }
  }
]

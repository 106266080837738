






























import Vue from 'vue'
import Component from 'vue-class-component'
import gsap from 'gsap'

import gallery from '@/store/modules/gallery'
import { TRANSITION_DIRECTION } from '@/store/modules/transition'

import BrushSprites from '@/components/BrushSprites/BrushSprites.vue'

@Component({
  components: {
    BrushSprites
  }
})
export default class GalleryArtworkInformationPanel extends Vue {
  $isDesktop: boolean

  brushDirection = TRANSITION_DIRECTION.VERTICAL

  get artworkCount(): number {
    return gallery.artworkIndex + 1
  }

  get artworkLength(): number {
    return gallery.artworks.length
  }

  get artwork() {
    return gallery.selectedArtwork.panel
  }

  get shareURL() {
    return document.location.href || ''
  }

  close() {
    gallery.exitArtwork()
  }

  beforeEnter() {
    const $ = gsap.utils.selector(this.$el)

    if (this.$isDesktop) {
      gsap.set($('.background-edge--start'), { yPercent: 100 })
      gsap.set($('.background-middle'), { scaleY: 0 })
      gsap.set($('.background-edge--end'), { yPercent: -100 })
    }

    gsap.set($('.inner'), { autoAlpha: 0 })
    gsap.set($('.background'), { clearProps: '--bg-color' })
    gsap.set($('.background-reveal'), { opacity: 0 })
    gsap.set($('.js-stagger-item'), { y: 10, opacity: 0 })
    gsap.set($('.btn-close'), { scale: 0, rotation: -15 })
  }

  enter() {
    const $ = gsap.utils.selector(this.$el)
    const tl = gsap.timeline({
      defaults: {
        duration: 0.45,
        ease: 'quart.inOut'
      },
      onComplete: this.afterEnter
    })
    tl
      .to($('.background-edge'), { yPercent: 0 }, 0)
      .to($('.background-middle'), { scaleY: 1 }, 0)
      .set($('.background-reveal'), { opacity: 1 })
      .set($('.background'), { '--bg-color': 'white' })

    return tl
  }

  afterEnter() {
    const $ = gsap.utils.selector(this.$el)
    const tl = gsap.timeline({
      autoRemoveChildren: true
    })
    tl
      .set($('.inner'), { autoAlpha: 1 })
      .add((this.$refs.brushSprites as BrushSprites).leave({
        duration: 0.6,
        onComplete: () => {
          gsap.set($('.background-reveal'), { opacity: 0 })
        }
      }), 0)
      .to($('.js-stagger-item'), {
        y: 0,
        opacity: 1,
        duration: 0.3,
        ease: 'cubic.out',
        stagger: 0.05
      }, 0.2)
      .to($('.btn-close'), {
        scale: 1,
        rotation: 0,
        duration: 0.45,
        ease: 'back.out(2.5)'
      })
  }

  leave() {

  }
}

import Vue from 'vue'
import Vuex from 'vuex'
import { ActionTree, GetterTree, MutationTree } from 'vuex'

Vue.use(Vuex)

const strict = process.env.VUE_APP_VUEX_STRICT === 'true'
const logger = process.env.VUE_APP_VUEX_LOGGER === 'true'

export interface IStoreState {
  preloaded: boolean
  skipIntro: boolean
}

export interface IRootState {}

const state: IStoreState = {
  preloaded: false,
  skipIntro: false,
}

const actions: ActionTree<IStoreState, IRootState> = {
  onLoad({commit}, preloaded: boolean) {
    commit('_SET_PRELOADED', preloaded)
  },
  skipIntro({commit}, skip: boolean) {
    commit('_SET_SKIP_INTRO', skip)
  },
}

const mutations: MutationTree<IStoreState> = {
  _SET_PRELOADED(currentState: IStoreState, payload: boolean) {
    currentState.preloaded = payload
  },
  _SET_SKIP_INTRO(currentState: IStoreState, payload: boolean) {
    currentState.skipIntro = payload
  },
}

const getters: GetterTree<IStoreState, IRootState> = {
  isPreloaded(currentState: IStoreState) {
    return currentState.preloaded
  },
  hasSkipIntro(currentState: IStoreState) {
    return currentState.skipIntro
  },
}

const store = new Vuex.Store<IStoreState>({
  state,
  actions,
  mutations,
  getters,
  strict,
  plugins: logger ? [Vuex.createLogger()] : []
})

/////////////
////////////////////////////////////////////////////
///////////////////////////////////
 
//////////

export default store











import Vue from 'vue'
import Component from 'vue-class-component'
import gsap from 'gsap'

import transition from '@/store/modules/transition'

import BrushSprites from '@/components/BrushSprites/BrushSprites.vue'

@Component({
  components: {
    BrushSprites
  }
})
export default class AppTransition extends Vue {
  get direction(): string {
    return transition.options.direction
  }

  get showBackdrop(): boolean {
    return transition.options.showBackdrop
  }

  enter(el: Element, onComplete: gsap.Callback) {
    if (transition.options.skip) {
      return onComplete()
    }

    const tl: gsap.core.Timeline = gsap.timeline({
      autoRemoveChildren: true,
      onComplete
    })
    tl.addLabel('start')
      .set(this.$el, { clearProps: 'opacity' })
      .add((this.$refs.brushSprites as BrushSprites).enter(), 'start')

    if (transition.options.showBackdrop && this.$refs.backdrop) {
      gsap.set(this.$refs.backdrop, { clearProps: 'opacity' })
      tl.from(
        this.$refs.backdrop,
        {
          opacity: 0,
          duration: 1,
          ease: 'linear'
        },
        'start'
      )
    }
  }

  afterEnter() {
    (<Element>this.$root.$el).scrollTo(0, 0)
  }

  leave(el: Element, onComplete: gsap.Callback) {
    if (transition.options.skip) {
      return onComplete()
    }

    const tl: gsap.core.Timeline = gsap.timeline({
      autoRemoveChildren: true,
      onComplete
    })
    tl.addLabel('start')

    if (transition.options.fade) {
      tl.to(
        this.$el,
        {
          opacity: 0,
          duration: 0.3,
          ease: 'linear'
        },
        'start'
      )
    } else {
      tl.add((this.$refs.brushSprites as BrushSprites).leave(), 'start')
    }

    if (transition.options.showBackdrop && this.$refs.backdrop) {
      tl.to(
        this.$refs.backdrop,
        {
          opacity: 0,
          duration: 1,
          ease: 'linear'
        },
        'start'
      )
    }
  }
}

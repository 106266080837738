import Vue from 'vue'
import VueRouter from 'vue-router'
import Component from 'vue-class-component'

Vue.use(VueRouter)

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL
})

router.onError( (e: Error)=>{
  console.log("router error", e.message)
})


export default router

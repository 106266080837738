import _datas from '@/store/datas/artworks.json'
import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules'

import store from '@/store'
import { AudioManager } from '@/core/Audio'





export type Artwork = {
  id: string
  panel? : {
    title: string
    subtitle?: string
    description: string
  }
}


let _closeCallback :Function = null


@Module
export class Gallery extends VuexModule {
  get artworks(): Artwork[] {
    return _datas
  }


  /**
   * 
   */
  artworkIndex = -1

  get selectedArtwork(): Artwork | null {
    if( this.artworkIndex === -1 ) return null;
    return _datas[this.artworkIndex]
  }

  get isArtworkSelected(): boolean{
    return this.artworkIndex > -1
  }

  @Action
  selectArtwork(i:number) {
    AudioManager.playClic()
    this._SET_ARTWORK_INDEX(i)
  }
  
  @Action
  exitArtwork() {
    AudioManager.playClic()
    this._SET_ARTWORK_INDEX(-1)
  }

  @Mutation
  _SET_ARTWORK_INDEX(i: number) {
    this.artworkIndex = i
  }


  /**
   * position of the camera in the gallery path
   * 1 is the begining (first pov), 3 is the third pov etc
   * position doesn't match artwork indices (there can be multiple artwork for 1 pov)
   */
  position = 1

  @Action
  setPosition(p:number) {
    this._SET_POSITION(p)
  }

  @Mutation
  _SET_POSITION(v: number) {
    this.position = v
  }


  /**
   * display a general purpose close btn
   */
  genericClose = false

  @Action
  showGenericClose( cb:Function ){
    _closeCallback = cb
    this._SET_GENERIC_CLOSE(true)
  }
  
  @Action
  clickGenericClose(){
    _closeCallback?.()
    _closeCallback = null
    this._SET_GENERIC_CLOSE(false)
  }

  @Mutation
  _SET_GENERIC_CLOSE(b: boolean) {
    this.genericClose = b
  }


  /**
   * display cursor:pointer over canvas
   */
  cursor = false

  @Action
  setCursorPointer(v:boolean) {
    this._SET_CURSOR_POINTER(v)
  }

  @Mutation
  _SET_CURSOR_POINTER(b: boolean) {
    this.cursor = b
  }

  /**
   * display cursor:pointer over canvas
   */
  showPanoHint = false

  @Action
  setShowPanoHint(v:boolean) {
    this._SET_SHOW_PANOHINT(v)
  }

  @Mutation
  _SET_SHOW_PANOHINT(b: boolean) {
    this.showPanoHint = b
  }

  /**
   * display pov360 copyright
   */
   showPanoCopy = false

   @Action
   setShowPanoCopy(v:boolean) {
     this._SET_SHOW_PANOCOPY(v)
   }
 
   @Mutation
   _SET_SHOW_PANOCOPY(b: boolean) {
     this.showPanoCopy = b
   }

  

  /**
   * display gallery intro
   */ 
  intro = true

  @Action
  setIntro(b: boolean) {
    this._SET_INTRO(b)
  }

  @Mutation
  _SET_INTRO(b: boolean) {
    this.intro = b
  }

  /**
   * is gallery intro video is loaded
   */ 
  introCanPlayThrough = false

  @Action
  setIntroCanPlayThrough(b: boolean) {
    this._SET_INTRO_CAN_PLAY_THROUGH(b)
  }

  @Mutation
  _SET_INTRO_CAN_PLAY_THROUGH(b: boolean) {
    this.introCanPlayThrough = b
  }

  /**
   * display tutorial state
   */
  tutorial = false

  @Action
  setTutorial(b: boolean) {
    this._SET_TUTORIAL(b)
  }

  @Mutation
  _SET_TUTORIAL(b: boolean) {
    this.tutorial = b
  }

  /**
   * display gallery tip
   */
  moveHint = true

  @Action
  setMoveHint(v:boolean) {
    this._SET_MOVE_HINT(v)
  }

  @Mutation
  _SET_MOVE_HINT(b: boolean) {
    this.moveHint = b
  }

  /**
   * 
   */
  hotspot = true

  @Action
  setHotspot(v:boolean) {
    this._SET_HOTSPOT(v)
  }

  @Mutation
  _SET_HOTSPOT(b: boolean) {
    this.hotspot = b
  }
}

const gallery = new Gallery({ store, name: 'gallery' })

export default gallery























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '@/utils/SplitText'

import viewport from '@/store/modules/viewport'

@Component
export default class UINewsletterSection extends Vue {
  tlCollection: gsap.core.Timeline[] = []
  scrollTriggerCollection: ScrollTrigger[] = []

  // get viewportWidth(): number {
  //   return viewport.windowWidth
  // }
  // @Watch('viewportWidth')
  // watchViewportWidth() {
  //   this.resizeIForm()
  // }

  beforeDestroy() {
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill()
        tl.clear() && tl.kill()
      })
    }
    if (this.scrollTriggerCollection) {
      this.scrollTriggerCollection.forEach((scrollTrigger) => scrollTrigger.kill())
    }
  }

  mounted() {
    this.$nextTick(() => {
      this.initScrollAnimation()

      const uiNavBtn: Element = (document && document.querySelector('.open-nav-btn')) || null
      if (uiNavBtn) {
        this.scrollTriggerCollection.push(
          ScrollTrigger.create({
            scroller: '#app',
            trigger: this.$el,
            start: (): string => {
              return `top bottom-=70px`
            },
            onEnter: () => {
              uiNavBtn.classList.add('light')
            },
            onLeaveBack: () => {
              uiNavBtn.classList.remove('light')
            }
          })
        )
      }
    })
  }

  // resizeIForm() {
  //   const height: number = (<HTMLIFrameElement>this.$refs.iForm).contentWindow.document.body.scrollHeight
  //   gsap.set(<HTMLIFrameElement>this.$refs.iForm, { height })
  // }

  initScrollAnimation() {
    const splitTitle = new SplitText(this.$refs.title, { type: 'chars' })
    const tl: gsap.core.Timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.$el
      },
      start: 'center bottom'
    })
    tl.addLabel('start')
    tl.fromTo(
      splitTitle.chars,
      {
        opacity: 0
      },
      {
        opacity: 1,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start'
    )
    tl.fromTo(
      [this.$refs.description], // [this.$refs.description, (<Vue>this.$refs.cta).$el],
      {
        opacity: 0,
        yPercent: 100
      },
      {
        opacity: 1,
        yPercent: 0,
        duration: 0.85,
        ease: 'quint.out'
      },
      'start+=0.2'
    )

    this.tlCollection.push(tl)
  }
}

import Scene from "@/webgl/scene";

import MaterialFactory from "./MaterialFactory";
import Camera from "nanogl-camera";
import Masks from "@/webgl/gl/masks";
import GLConfig from "nanogl-state/config";
import { Passes } from "@/webgl/glsl/Passes";
import GallerySection from "./GallerySection";
import HotspotsProjector from "./Hotspots";
import gallery_hotspots from "@/store/modules/gallery-hotspots";
import Magnifying from "./magnifying";
import Povs from "./Povs";
import Delay from "@/core/Delay";


const ID = 'gallery';

const NUM_SECTIONS = 3 

export default class Gallery {
  
  scene: Scene;
  matFactory: MaterialFactory;

  sections : GallerySection[]

  magnifying : Magnifying

  povs: Povs
  


  constructor( scene : Scene ){

    this.scene = scene;
    
    this.matFactory = new MaterialFactory(scene)

    this.sections = []
    for (let i = 0; i < NUM_SECTIONS; i++) {
      this.sections.push( new GallerySection(this, i+1) )
    }

    this.povs = new Povs( scene )
    this.magnifying = new Magnifying( scene )
    
  }
  
  load(){
    const all = this.sections.map( s=>s.load())
    all.push( this.matFactory.texs.load() )
    all.push( this.povs.load() )
    all.push( this.magnifying.load() )

    const p = Promise.all(all)
    
    p.then( ()=> this.ghostLoad() )

    return p
  }

  async ghostLoad(){
    await Delay(500)
    this.matFactory.ghostLoad()
  }
  
  
  preRender() {
    
    for (const section of this.sections) {
      section.preRender()
    }

    this.magnifying.preRender()
    this.povs.preRender()
  }
  
  
  rttPass(){
    HotspotsProjector.projectHotspots( gallery_hotspots.list, this.scene )
    this.magnifying.rttPass()
  }
  

  render(camera: Camera, mask: Masks, passId : Passes = Passes.DEFAULT, cfg?: GLConfig ) {
    for (const section of this.sections) {
      section.render( camera, mask, passId, cfg )
    }

  }
  
  postRender(){
    this.povs.postRender()
    this.magnifying.render()

  }
}
/////////////
/////////////////////////////////
//////////


import { vec3, vec2, quat, mat4 } from 'gl-matrix'


import mat4LookAt from '@/webgl/math/mat4-lookat';
// import mat4lerp from 'math/mat4-lerp';
import { tween, setBezierFunction, Tweenable } from 'shifty';

import { ICameraController } from './ICameraController';
import Scene from '@/webgl/scene';
import Camera from 'nanogl-camera';
import PerspectiveLens from 'nanogl-camera/perspective-lens';
import CameraPath from './CameraPath';

import { InputTouch } from '@/webgl/lib/inputs';
import mat4lerp from '@/webgl/math/mat4-lerp';
import Paths from '@/core/Paths';
import gallery from '@/store/modules/gallery';
import { IPov } from '../gallery/Povs';
import { mix } from '../math';
import audio_state from "@/store/modules/sound";
import { getAssetPath } from '../gallery/Assets';

const VEC2 = vec2.create();
const POS = vec3.create();
const TGT = vec3.create();
const M4 = mat4.create();

const MAX_SLIDE = 31

class MeanValue{

  size  = 15;
  hist : number[];

  reset(){
    this.hist = []
  }

  push(v:number){
    this.hist.push(v);
    if( this.hist.length>this.size )
      this.hist.shift()
  }

  mean(){
    let val  = 0;
    for (let i = 0; i < this.hist.length; i++) {
      val = Math.max( val, this.hist[i] );
    }
    return val;
  }
}

export default class XPController implements ICameraController {

  scene: Scene;
  el: HTMLCanvasElement;
  povTransition: number;
  // fov: number;
  frameFov: number;
  lastOneTouch: number;
  _isDragging: boolean;
  _isPinching: boolean;
  _changing: boolean;
  _downPos: vec2;
  cam: Camera<PerspectiveLens>;
  path: CameraPath;

  slideDown = 0;
  slideVelocity = 0;
  slideTween: any;
  lowpassAmount = 0

  
  meanVelocity : MeanValue;
  
  
  frameTween: any;
  currentFrame: IPov|null = null;
  frameMatMix  = 0;
  frameMatCopy : mat4 = mat4.create();
  

  isScrolling = false
  scrollTimeoutDelay = 1000
  scrollTimeout:number
  scrollDestSlide: number;
  moveHintTimeout: number;


  get slide():number {
    return gallery.position
  }

  set slide(p:number) {
    gallery.setPosition( p )
  }

  get fov(){
    return (this.scene.ratio < 1 ) ? .5 : .4
  }

  constructor(scene: Scene) {

    this.scene = scene;
    this.el = scene.glview.canvas;
    this.povTransition = 0;
    this.frameFov = this.fov
    this.lastOneTouch = 0;

    this.meanVelocity = new MeanValue();


    // ORBIT
    // ---------

    this._isDragging = false;
    this._isPinching = false;
    this._changing = false;
    this._downPos = vec2.create();

    // POV
    // ---------

    this.path = new CameraPath( getAssetPath(`camera_path.bin`) );



/////////////////
//////////////////////////////
//////////////////////////////////////
//////////////
  }

///////////////
////////////////////

///
////////////


  setMousePos(e, el, v2) {
    const w = window.innerWidth
    const h = window.innerHeight
    v2[0] = 2 * e.clientX / (w) - 1
    v2[1] = -(2 * e.clientY / (h) - 1)

  }



  start(cam: Camera<PerspectiveLens>) {
    this.cam = cam;
    this.scene.inputs.onTouchAdded.on(this.onTouchAdded)
    this.scene.ilayer.addEventListener( "wheel", this.onMouseWheel )
  }
  
  
  stop() {
    this.scene.inputs.onTouchAdded.off(this.onTouchAdded)
    this.scene.ilayer.removeEventListener( "wheel", this.onMouseWheel )
  }
  
  
  onMouseWheel = (e:any)=>{
    if( ! this.isScrolling ){
      
      this.scrollDestSlide = this.slide
    }
    this.isScrolling = true
    this.userScroll()
    const me = e as WheelEvent
    const scroll = me.deltaY / 1200
    this.scrollDestSlide += scroll
    clearTimeout( this.scrollTimeout )
    this.scrollTimeout = setTimeout( this.onScrollTimeout, this.scrollTimeoutDelay )
    //e.preventDefault()
  }
  
  onScrollTimeout = ()=>{
    this.isScrolling = false
    this.stopDrag()
  }

  onTouchAdded = (t: InputTouch) => {
    if (this.scene.inputs.touches.length === 1) {
      const now = Date.now();
      // console.log( now - this.lastOneTouch )
      if (now - this.lastOneTouch < 300) {
        this.doubleTap()
        this.lastOneTouch = 0
      } else {

        this.lastOneTouch = now
      }
    } else {
      this.lastOneTouch = 0
    }
  }


  selectFrame(frame: IPov | null ) {

    if( this.currentFrame === frame ) return;

    let duration:number
    if( frame !== null ){
      duration = frame.transitionDuration()
      frame.getCameraParams( this.frameMatCopy );
    } else if( this.currentFrame ) {
      duration = this.currentFrame .transitionDuration()
    } else {
      duration = 1000
    }
    this.currentFrame = frame;
    this.frameTransition( frame !== null, duration );
  }

  frameTransition( enter : boolean, duration:number ){

    if( this.frameTween ) this.frameTween.stop();

    this.frameTween = new Tweenable();
    this.frameTween.setConfig({
      from:{s:this.frameMatMix}, 
      to:{s:enter?1:0}, 
      easing : "easeInOutQuart",
      duration : duration,
      step:v=>{
        this.frameMatMix = v.s;
      }
    })

    const onComplete = this.frameTween.tween();
    if( enter ){
      onComplete.then(()=>{
        gallery.setHotspot(false)
        // this.slide = this.currentFrame.getPathPosition();
      });
    } else {
      onComplete.then(()=>{
        gallery.setHotspot(true)
        // this.slide = this.currentFrame.getPathPosition();
      });
    }
  }




  update(dt: number) {
    this._handleInputs();
    this.path.getPositionAt(POS, TGT, this.slide * 100);
    mat4LookAt(M4, POS, TGT);

    // mix frame selection
    if( this.frameMatMix > 0 ){
      if( this.currentFrame != null ){
        this.frameFov = this.currentFrame.getCameraParams( this.frameMatCopy );
        this.lowpassAmount = this.currentFrame.lowpassAmount
      }

      mat4lerp( M4, M4, this.frameMatCopy, this.frameMatMix );
      if( this.frameFov <= 0 ) this.frameFov = this.fov
      const finalFov = mix( this.fov, this.frameFov, this.frameMatMix )
      
      this.cam.lens.setVerticalFov( finalFov )
      
      const finallowpass = mix( 0, this.lowpassAmount, this.frameMatMix )
      audio_state.setLowPass( finallowpass )
    } else {
      this.cam.lens.setVerticalFov( this.fov )
    }
    
    this.cam.setMatrix(M4);
  }

  doubleTap() {

  }

  _handleInputs() {

    this.slide = Math.max(0, Math.min(MAX_SLIDE, this.slide));

    const touches = this.scene.inputs.touches;

    const dragging = touches.length === 1 && this.currentFrame === null;
    const pinching = touches.length === 2 && this.currentFrame === null;

    let startDrag = false;
    let stopDrag = false;
    let startPinch = false;

    if (this._isDragging !== dragging) {

      startDrag = dragging;
      stopDrag = !dragging;
      this._isDragging = dragging;
    }

    if (this._isPinching !== pinching) {
      startPinch = pinching;
      this._isPinching = pinching;
    }


    if (startDrag) {

      this.isScrolling = false
      clearTimeout( this.scrollTimeout )

      this.slideVelocity = 0;
      this.meanVelocity.reset();
      if( this.slideTween ) this.slideTween.stop();
      this.slideDown = this.slide;

    }

    if (stopDrag) {
      this.stopDrag();
    }


    if (this._isDragging) {

      touches[0].getDelta(VEC2);
      const newSlide = this.slideDown - VEC2[0] * .4
      this.slideVelocity = (newSlide - this.slide)/this.scene.dt;
      this.meanVelocity.push( this.slideVelocity );
      this.slide = newSlide

      // console.log(this.slide)
      if( Math.abs(VEC2[0]) > 0.01 ){
        this.userScroll()
      }

    } else {
      // this.slideVelocity *= .95
      // this.slide += this.slideVelocity * this.scene.dt
    }


    if( this.isScrolling ){
      this.slideTween?.stop();

      this.slide += (this.scrollDestSlide - this.slide)*.1
    }

    if (this._isPinching) {

      if (startPinch) {

      }


    }


    this.slide = Math.max(0, Math.min(MAX_SLIDE, this.slide));

  }


  userScroll() {
    gallery.setMoveHint(false)
    clearTimeout( this.moveHintTimeout )
    this.moveHintTimeout = setTimeout( ()=>this.userIdle(), 5000 )
  }
  
  userIdle() {
    gallery.setMoveHint(true)
    clearTimeout( this.moveHintTimeout )
  }


  stopDrag() {
    // console.log('velocity: ',this.meanVelocity.mean());
    const esimateDelta = Math.max( -.6, Math.min( .6, this.slideVelocity*.6 ) );
    let snapTo = Math.round( this.slide + esimateDelta )
    

    if( snapTo < 1 ) snapTo = 1;

    const deltaSlide = snapTo - this.slide;

    const duration = 1

    let bezierSlope;
    if( Math.abs( deltaSlide ) < 0.01 ){
      bezierSlope = 0
    } else {
      bezierSlope  = this.slideVelocity / deltaSlide;
    }

    const inAngle = Math.atan( bezierSlope );
    const inAmp = .5


    // console.log( 'slope: ',bezierSlope )

    setBezierFunction("cam_slide_tween", inAmp*Math.cos( inAngle ), inAmp*Math.sin( inAngle ), 0.500, 1)

    
    if( this.slideTween ) this.slideTween.stop();

    this.slideTween = new Tweenable();
    this.slideTween.setConfig({
      from:{s:this.slide}, 
      to:{s:snapTo}, 
      easing : "cam_slide_tween",
      duration : duration*1000,
      step:v=>{
        this.slide = v.s;
      }
    });
    this.slideTween.tween();

  }





}

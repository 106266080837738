
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import gsap from 'gsap'
import SplitText from '@/utils/SplitText'

@Component
export default class UIBackBtn extends Vue {
  hoverTL: gsap.core.Timeline
  /**
   * Optional route passed to `$router.push()`
   */
  @Prop({
    type: [String, Object],
    default: undefined
  })
  to!: RawLocation

  /**
   * Optional external link
   */
  @Prop({
    type: String,
    default: undefined
  })
  toExternal!: string

  /**
   * disabled btn
   */
  @Prop({
    type: Boolean,
    default: false
  })
  disabled!: boolean

  /**
   * display a spinner
   */
  @Prop({
    type: Boolean,
    default: false
  })
  loading!: boolean

  /**
   * Run an enter animation
   * can use a delay as param
   */
  @Prop({})
  appear!: number

  tl: gsap.core.Timeline
  tlCollection: gsap.core.Timeline[] = []

  get _isdisabled() {
    return this.disabled || this.loading
  }

  mounted() {
    if (this.appear) this._enterAnimation()
    const tl: gsap.core.Timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.$el,
        start: 200,
        once: false,
        toggleActions: 'play none reverse none'
      }
    })
    tl.fromTo(
      this.$refs.content,
      {
        opacity: 1
      },
      {
        opacity: 0,
        duration: 0.25,
        ease: 'quint.out'
      },
      'start'
    )
    this.tlCollection.push(tl)
  }

  beforeDestroy() {
    if (this.tl) {
      this.tl.eventCallback('onComplete', null)
    }
    if (this.tlCollection) {
      this.tlCollection.forEach((tl) => {
        tl.scrollTrigger && tl.scrollTrigger.kill()
        tl.clear() && tl.kill()
      })
    }
  }

  _enterAnimation() {
    const split = new SplitText(this.$refs.content)
    const tl: gsap.core.Timeline = gsap.timeline()
    tl.addLabel('start')
    tl.from(this.$refs.icon, { scale: 0, rotate: '-10deg', duration: 0.85, ease: 'back.out' })
    tl.from(
      split.chars,
      {
        yPercent: 100,
        opacity: 0,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start'
    )
  }

  _createHoverAnimation() {
    this.hoverTL = gsap.timeline({ paused: true })
    this.hoverTL.addLabel('start')
    if (this.$refs.inner) {
      this.hoverTL.fromTo(
        (<Element>this.$refs.inner),
        {
          xPercent: 0,
          opacity: 1
        },
        {
          xPercent: 100,
          opacity: 0,
          duration: 0.35,
          ease: 'quint.in'
        },
        'start'
      )
      this.hoverTL.set((<Element>this.$refs.inner), { color: 'white', opacity: 1 })
      this.hoverTL.fromTo(
        (<Element>this.$refs.inner),
        {
          xPercent: -100
          // opacity: 0
        },
        {
          xPercent: 0,
          // opacity: 1,
          duration: 0.35,
          ease: 'quint.out'
        },
        'start+=0.35'
      )
    }
  }

  _onClick(e: Event, navigate: Function) {
    if (this.to) {
      navigate(e)
    }

    // if external link
    if (this.toExternal) {
      window.open(this.toExternal, '_blank')
    }

    this.$emit('click')
  }

  _onEnter() {
    if (!this.hoverTL) {
      this._createHoverAnimation()
    }
    this.hoverTL.play()
  }

  _onLeave() {
    if (!this.hoverTL) {
      this._createHoverAnimation()
    }
    this.hoverTL.reverse()
  }
}

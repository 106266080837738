


/////////////
/////////////////////////////////////////
//////////



import Masks from '@/webgl/gl/masks';
import Input, { Constant, IInputParam, Uniform } from 'nanogl-pbr/Input';

import { TextureResource } from '@/webgl/assets/TextureResource';

import ReflectDistPass from '@/webgl/glsl/reflect_dist';
import UnlitPass    from '@/webgl/glsl/unlit'                      ;


import BaseMaterial from 'nanogl-pbr/BaseMaterial';
import { Passes } from '@/webgl/glsl/Passes';
import GLConfig from 'nanogl-state/config';

import { TextureSrcSet } from '@/webgl/assets/TextureRequest';
import TexCoord from 'nanogl-pbr/TexCoord';
import Paths from '@/core/Paths';
import { StandardSpecular } from 'nanogl-pbr/StandardPass';
import LightmapChunk from '../glsl/standard_lm/LightmapChunk';
import FloorReflectionChunk from '../glsl/standard_ssr/FloorReflectionChunk';
import TextureLibrary from '../assets/TextureLibrary';
import MaterialProvider from '../assets/materials-provider';
import Scene from '../scene';
import Texture2D from 'nanogl/texture-2d';
import GL from '../gl/GL';
import { MakeFromHex } from '../lib/color';
import { mat3 } from 'gl-matrix';
import ZClipChunk from '../glsl/ZClipChunk';
import { getAssetPath } from './Assets';




export enum MAT_NAME {
  white_walls     = "white_walls",
  concrete_floor  = "concrete_floor",
  props_1  = "props_1",
  props_2  = "props_2",
  props_3  = "props_3",
  props_4  = "props_4",
  neons  = "neons",
  stickers  = "stickers",
  worms  = "worms",
  black_cache = "black_cache",

  wp_painted_concrete = "wp_painted_concrete" ,
  wp_not_ready_yet    = "wp_not_ready_yet"    ,
  wp_dark_wall        = "wp_dark_wall"        ,
  wp_concrete_01b2    = "wp_concrete_01b2"    ,
  wp_all_the_same     = "wp_all_the_same"     ,

  stencil1     = "stencil1"     ,
}





export default class MaterialFactory {

  ghostLoad() {
    return Promise.all([
      new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_1_base_color.jpg'  , true ), this.scene ).doLoadWithTex( this.texs.get('props_1_basecolor')),
      new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_2_base_color.jpg'  , true ), this.scene ).doLoadWithTex( this.texs.get('props_2_basecolor')),
      new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_3_base_color.jpg'  , true ), this.scene ).doLoadWithTex( this.texs.get('props_3_basecolor')),
      new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_4_base_color.jpg'  , true ), this.scene ).doLoadWithTex( this.texs.get('props_4_basecolor')),
      new TextureResource( MaterialFactory.makeBBCTextureSet('360_vignes.jpg'  , true ), this.scene ).doLoadWithTex( this.texs.get('vignes')),
    ])
  }
  
  
  lightmapMultiplyer: Input;
  lightmapMultiplyerUniform: Uniform;
  lightmap_mul : number = .95

  floorReflectivity = .5
  floorAlbedoBoost = 1
  
  reflectDistPass: ReflectDistPass;
  texs: TextureLibrary;
  mats: MaterialProvider
  private _isInit : boolean = false;
  scene: Scene;
  floorAlbedoBoostUniform: Uniform;
  floorReflectivityUniform: Uniform;
  
  
  static makeBBCTextureSet( path: string, bbc = false ): TextureSrcSet {
    return TextureSrcSet.create( path , bbc )
  }

  
  constructor( scene: Scene  ) {
    
    if( this._isInit ) return;

    this.scene = scene
    this.texs = new TextureLibrary()
    this.mats = this.scene.matlib.createProvider( 'gallery' );
    
    this.reflectDistPass = new ReflectDistPass();
    this.reflectDistPass.mask = Masks.REFLECTED;
  
    this.lightmapMultiplyer = new Input( 'LightmapMultiplier'  , 1 )
    this.lightmapMultiplyerUniform = this.lightmapMultiplyer.attachUniform( 'ulmmul' );
    this.lightmapMultiplyerUniform.set( this.lightmap_mul)
  

    this.floorAlbedoBoostUniform  =new Uniform("albedoMult", 1)
    this.floorReflectivityUniform =new Uniform("fru"       , 1)
    this.floorAlbedoBoostUniform.set( this.floorAlbedoBoost)
    this.floorReflectivityUniform.set( this.floorReflectivity)

    this.createTextures();


/////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////
  }


  
  createTextures() {
    
    const texs = this.texs


    texs.add( 'props_1_basecolor'      ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_1_base_color_L0.jpg'  , false ), this.scene ) )
    texs.add( 'props_2_basecolor'      ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_2_base_color_L0.jpg'  , false ), this.scene ) )
    texs.add( 'props_3_basecolor'      ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_3_base_color_L0.jpg'  , false ), this.scene ) )
    texs.add( 'props_4_basecolor'      ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Assets_4_base_color_L0.jpg'  , false ), this.scene ) )

    texs.add( 'neons'                   ,  new TextureResource( MaterialFactory.makeBBCTextureSet('neons.jpg'                 ), this.scene ) )
    texs.add( 'stickers'                ,  new TextureResource( MaterialFactory.makeBBCTextureSet('stickers_basecolor.jpg'              ), this.scene ) )
    texs.add( 'worms'                   ,  new TextureResource( MaterialFactory.makeBBCTextureSet('worms.jpg'                 ), this.scene ) )

    texs.add( 'vignes'                  ,  new TextureResource( MaterialFactory.makeBBCTextureSet('360_vignes_L0.jpg'                 ), this.scene ) )

    texs.add( 'Concrete_Basecolor'      ,  new TextureResource( MaterialFactory.makeBBCTextureSet('ConcreteFloor_Albedo.jpg'    ), this.scene ) )
    // texs.add( 'Concrete_Normal'         ,  new TextureResource( MaterialFactory.makeBBCTextureSet('ConcreteFloor_Normal.jpg'    ), this.scene ) )
    // texs.add( 'Concrete_Glossiness'     ,  new TextureResource( MaterialFactory.makeBBCTextureSet('ConcreteFloor_Glossiness.jpg'), this.scene ) )
    // texs.add( 'Concrete_Wall_diffuse'   ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Concrete_Wall_diffuse.jpg'   ), this.scene ) )
    // texs.add( 'Concrete_Wall_specular'  ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Concrete_Wall_specular.jpg'  ), this.scene ) )
    // texs.add( 'Concrete_Wall_glossiness',  new TextureResource( MaterialFactory.makeBBCTextureSet('Concrete_Wall_glossiness.jpg'), this.scene ) )
    // texs.add( 'Concrete_Wall_normal'    ,  new TextureResource( MaterialFactory.makeBBCTextureSet('Concrete_Wall_normal.jpg'    ), this.scene ) )
    
    texs.add( 'wp_painted_concrete_color' , new TextureResource( MaterialFactory.makeBBCTextureSet('wp_painted_concrete_color.jpg' ), this.scene ) )
    texs.add( 'wp_dark_wall_color'        , new TextureResource( MaterialFactory.makeBBCTextureSet('wp_dark_wall_color.jpg'        ), this.scene ) )
    texs.add( 'wp_concrete_01b2_color'    , new TextureResource( MaterialFactory.makeBBCTextureSet('wp_concrete_01b2_color.jpg'    ), this.scene ) )
    texs.add( 'wp_not_ready_yet_color' , new TextureResource( MaterialFactory.makeBBCTextureSet('wp_not_ready_yet_color.jpg' ), this.scene , {genmipmaps: true, mipmap:true, aniso:8} ) )
    texs.add( 'wp_all_the_same_color'  , new TextureResource( MaterialFactory.makeBBCTextureSet('wp_all_the_same_color.jpg'  ), this.scene , {genmipmaps: true, mipmap:true, aniso:8} ) )

  }




  createMaterial( name : MAT_NAME ) : BaseMaterial {
    switch(name){
      case MAT_NAME.white_walls : return this.createWhiteWalls()
      case MAT_NAME.concrete_floor : return this.createConcreteFloor()
      case MAT_NAME.props_1 : return this.createProps1()
      case MAT_NAME.props_2 : return this.createProps2()
      case MAT_NAME.props_3 : return this.createProps3()
      case MAT_NAME.props_4 : return this.createProps4()
      case MAT_NAME.neons   : return this.createNeons()
      case MAT_NAME.stickers: return this.createStickers()
      case MAT_NAME.worms   : return this.createWorms()
      case MAT_NAME.black_cache   : return this.createCache( [0, 0, 0] )
      case MAT_NAME.stencil1   : return this.createStencil1()
     
      case MAT_NAME.wp_painted_concrete : return this.createPropsBase("wp_painted_concrete_color")
      case MAT_NAME.wp_not_ready_yet    : return this.createPropsBase("wp_not_ready_yet_color"   )
      case MAT_NAME.wp_dark_wall        : return this.createPropsBase("wp_dark_wall_color"       )
      case MAT_NAME.wp_concrete_01b2    : return this.createPropsBase("wp_concrete_01b2_color"   )
      case MAT_NAME.wp_all_the_same     : return this.createPropsBase("wp_all_the_same_color"    )


    }   
    throw `missing material "${name}"` 
  }


  createWhiteWalls(): BaseMaterial {
    
    const m = this.createBaseMaterial();


    const pass = this.createBaseSpecularPass();
    this.scene.iblMngr.setupExpoGmma( pass );

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;

    pass.surface.baseColor.attachConstant(MakeFromHex(0xffffff))
    pass.surface.specular.attachConstant([.04, .04, .04])
    pass.surface.glossiness.attachConstant([.4])
    
    
    m.addPass( pass, Passes.DEFAULT );
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;

  }


  createConcreteWalls(): BaseMaterial {
    
    const m = this.createBaseMaterial();


    const texs = this.texs;


    const pass = this.createBaseSpecularPass();
    this.scene.iblMngr.setupMat( pass );

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;

    const uv0 = TexCoord.create( 'aTexCoord0' );
      
    // pass.surface.baseColor. attachSampler ( 'tAlbedo' , uv0 ).set( texs.get('Concrete_Wall_diffuse'    ) )
    // pass.surface.specular.  attachSampler ( 'tSpec'   , uv0 ).set( texs.get('Concrete_Wall_specular'   ) )
    // pass.surface.glossiness.attachSampler ( 'tGloss'  , uv0 ).set( texs.get('Concrete_Wall_glossiness' ) )
    // pass.normal .           attachSampler ( 'tNormal' , uv0 ).set( texs.get('Concrete_Wall_normal'     ) )
    
    
    m.addPass( pass, Passes.DEFAULT );
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;

  }

  createProps1():BaseMaterial {
    return this.createPropsBase('props_1_basecolor')
  }
  createProps2():BaseMaterial {
    return this.createPropsBase('props_2_basecolor')
  }
  createProps3():BaseMaterial {
    return this.createPropsBase('props_3_basecolor')
  }
  createProps4():BaseMaterial {
    return this.createPropsBase('props_4_basecolor')
  }

  createWorms():BaseMaterial {
    const m = mat3.create()
    // mat3.fromScaling(m, [2, .5])
    mat3.fromScaling(m, [1, 1])
    const tc = TexCoord.createTransformed( 'aTexCoord0', m )
    return this._createSimpleLigtmapped('worms', 0, tc)
  }

  createPropsBase( basecolorname:string):BaseMaterial {
    const m = this._createSimpleLigtmapped(basecolorname)
    return m;
  }

  _createSimpleLigtmapped( basecolorname:string, glossiness= .4, tc?:TexCoord):BaseMaterial {
    const m = this.createBaseMaterial();
    const texs = this.texs;

    const pass = this.createBaseSpecularPass();
    this.scene.iblMngr.setupExpoGmma( pass );

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE | Masks.REFLECTED;

    
    const uv0 = tc || TexCoord.create( 'aTexCoord0' );
      
    pass.surface.baseColor. attachSampler ( 'tAlbedo' , uv0 ).set( texs.get(basecolorname ) )
    
    pass.surface.specular.attachConstant([.04, .04, .04])
    pass.surface.glossiness.attachConstant([glossiness])
    
    
    m.addPass( pass, Passes.DEFAULT );
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )

    return m;
  }

  createConcreteFloor(): BaseMaterial {

    const m = this.createBaseMaterial();


    const texs = this.texs;

    const pass = this.createBaseSpecularPass();

    this.opaqueConfig( pass.glconfig );
    pass.mask = Masks.OPAQUE

    const uv0 = TexCoord.createFromTRS( 'aTexCoord0', {scale:12, rotation:Math.PI/6} );
      
    pass.surface .baseColor . attachSampler ( 'tAlbedo' , uv0 ).set( texs.get( "Concrete_Basecolor"  ) )
    // pass.surface .baseColorFactor . attachConstant( [3, 3, 3])
    // pass.surface .glossiness .attachSampler ( 'tGloss'  , uv0 ).set( texs.get( "Concrete_Glossiness" ) )
    // pass.normal  .            attachSampler ( 'tNormal' , uv0 ).set( texs.get( "Concrete_Normal"     ) )
    
    const spec = .00
    pass.surface.specular.attachConstant([spec, spec, spec])
    
    const reflChunk = new FloorReflectionChunk()
    reflChunk.reflectionTexture = this.scene.reflect.getOutput()
    // reflChunk.strength.attachConstant( .9 )
    pass.inputs.add( reflChunk )
    
    
    pass     .surface .baseColorFactor.attach(this.floorAlbedoBoostUniform)
    reflChunk.strength.                attach(this.floorReflectivityUniform)


    // this.scene.iblMngr.setupMat( pass );
    this.scene.iblMngr.setupExpoGmma( pass );

    
    m.addPass( pass, Passes.DEFAULT );

    return m;

  }

  createNeons(): BaseMaterial {
    const m = this.createBaseMaterial();
    const pass = new UnlitPass();
    
    const uv0 = TexCoord.create();
    pass.iColor. attachSampler ( 'tColor' , uv0 ).set( this.texs.get( "neons"  ) )

    pass.glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(false)
      .enableBlend()
      .blendFunc( GL.ONE, GL.ONE )

    m.addPass( pass, Passes.DEFAULT )
    pass.mask = Masks.BLENDED | Masks.REFLECTED_BLENDED

    return m;
  }

  createStickers(): BaseMaterial {
    const m = this.createBaseMaterial();
    const pass = new UnlitPass();
    
    const uv0 = TexCoord.create();
    pass.iColor. attachSampler ( 'tColor' , uv0 ).set( this.texs.get( "stickers"  ) )

    pass.glconfig
      .enableCullface(false)
      .enableDepthTest()
      .depthMask(false)
      .enableBlend()
      .blendFunc( GL.ZERO, GL.SRC_COLOR )


    m.addPass( pass, Passes.DEFAULT )
    pass.mask = Masks.BLENDED
    

    return m;
  }



  createCache( color : number[] ) : BaseMaterial {

    const m = this.createBaseMaterial();
    
    const pass = new UnlitPass();
      
    pass.iColor .attachConstant(color)

    pass.glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(true)

    m.addPass( pass, Passes.DEFAULT );
    pass.mask = Masks.REFLECTED
    
    m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )
    return m;
  }


  createStencil1(  ) : BaseMaterial {

    const m = this.createBaseMaterial();
    
    const pass = new UnlitPass();
      
    pass.iColor .attachConstant([0, 0, 0])

    pass.glconfig
      .enableCullface(false)
      .enableDepthTest(true)
      .depthMask(true)

      .enableStencil( true )
      .stencilFunc( GL.ALWAYS, 1, 0xFF )
      .stencilOp( GL.KEEP, GL.KEEP, GL.REPLACE )
      .stencilMask( 0xFF )


    m.addPass( pass, Passes.DEFAULT );
    pass.mask = Masks.BLENDED
    
    //m.addPass( this.reflectDistPass, Passes.REFLECT_DEPTH )
    return m;
  }


  addLightmap( material: BaseMaterial, lightmap: Texture2D ){
    const lmChunk = new LightmapChunk()
    const sampler = lmChunk.iLightmap.attachSampler('tLightmap' , TexCoord.create('aTexCoord1') );
    sampler.set( lightmap );
    lmChunk.iAmbientMultiplier.attachConstant(0)
    lmChunk.iLightmapMultiplier.proxy( this.lightmapMultiplyer )

    const pass = material.getPass(Passes.DEFAULT).pass
    pass.inputs.add( lmChunk )

  }





  opaqueConfig( glconfig : GLConfig ) : void {
    glconfig
      .enableCullface(true)
      .enableDepthTest()
      .depthMask(true)
  }


  createGITest( lmname : string, mat:BaseMaterial) : void {

    const pass = new UnlitPass();
    pass.iColor .attachSampler('tColor' , TexCoord.create('aTexCoord0') ).set( this.texs.get( lmname ) );
    pass.mask = Masks.OPAQUE;
    this.opaqueConfig( pass.glconfig )
    mat.addPass( pass, Passes.DEFAULT );
    
  }




  createBaseMaterial( ) : BaseMaterial {
    return new BaseMaterial( this.scene.gl );
  }

  createBaseSpecularPass(): StandardSpecular {
    const p = new StandardSpecular()

    // p.inputs.add( new ZClipChunk() )
    return p;
  }
  

  

}



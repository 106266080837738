import Camera from 'nanogl-camera'
import { vec2, vec3, mat4, quat } from 'gl-matrix';
import Ray from '@/webgl/math/ray';
import Plane from '@/webgl/math/plane';
import Node from 'nanogl-node';
import rayPlaneIntersection from '@/webgl/math/ray-plane-intersection';
import { InputTouch } from '@/webgl/lib/inputs';
import mat4unscale from '@/webgl/math/mat4-unscale';
import Scene from '@/webgl/scene';
import PerspectiveLens from 'nanogl-camera/perspective-lens';
import gallery from '@/store/modules/gallery';
import Povs, { FrameRaycastResult, IPov } from './Povs';


const FRAME_EXTENTS = 1.3

const ray    : Ray   = new Ray  ();
const plane  : Plane = new Plane();
const V3     : vec3 = vec3.create();
const INV_W  : mat4 = mat4.create();
const M4A    : mat4 = mat4.create();
const M4B    : mat4 = mat4.create();

const ZERO = vec3.create()
const Q1 = quat.create()
const Q_ID = quat.create()
const NORMALIZE_FRAME_QUAT = quat.create()

const PARALAX_STRENGTH = 0.08
quat.rotateZ( NORMALIZE_FRAME_QUAT, NORMALIZE_FRAME_QUAT, Math.PI );
quat.rotateY( NORMALIZE_FRAME_QUAT, NORMALIZE_FRAME_QUAT, Math.PI );


export default class Frame implements IPov {

  static readonly FRAME_EXTENTS = 1.3

  ref: Node;
  scene: Scene;
  slide: number;
  paralax = vec2.create()

  paralaxStrength = PARALAX_STRENGTH

  // camera position offset relative to frame origin to fit frame in the screen



  touchSelectionCandidate : number
  index: number;

  lowpassAmount = .9

  raycastExtent = 1


  render(){}  
  preRender() {}
  enter(){}
  leave(){}
  transitionDuration():number {
    return 1500
  }

  constructor( protected povs : Povs, ref : Node, slide : number, index : number ){
    this.ref = ref;
    this.scene = povs.scene;
    this.slide = slide;

    this.scene.inputs.onTouchAdded.on(this.onTouchAdded)
    this.scene.inputs.onTouchRemoved.on(this.onTouchRemoved)

    this.index = index;
  }


  getPathPosition(): number {
    return this.slide
  }




  getCameraParams( out : mat4 ):number{
    
    
    const fov = this.getCameraOffset( V3, Q1, this.scene.camera )
    quat.multiply( Q1, NORMALIZE_FRAME_QUAT, Q1)
    // quat.copy( Q1, NORMALIZE_FRAME_QUAT )
    mat4.fromRotationTranslation( M4A, Q1, V3 )
    

    quat.rotateY( Q1, Q_ID, this.povs.paralax[0]*this.paralaxStrength )
    quat.rotateX( Q1, Q1, this.povs.paralax[1]*this.paralaxStrength )

    mat4.fromRotationTranslation( M4B, Q1, ZERO )
    
    mat4.multiply( M4A, M4B, M4A );
    
    mat4unscale( M4B, this.ref._wmatrix )
    mat4.multiply( out, M4B, M4A );

    
    const p = this.scene.mainCamera._parent
    if( p ){
      mat4.invert( M4A, p._wmatrix );
      mat4.multiply( out, M4A, out );
    }

    return fov
  }


  


  getCameraOffset( out:vec3, rot: quat, camera : Camera<PerspectiveLens> ) : number {

    const frameW = this.ref.scale[0]*FRAME_EXTENTS
    const frameH = this.ref.scale[1]*FRAME_EXTENTS

    let vpBaseSizeW:number
    let vpBaseSizeH:number
    const frameAspect = frameW/frameH;
    const viewportAspect = this.scene.ratio
    
    
    const destopMode = viewportAspect > 1

    quat.identity( rot )

    out[0] = 0
    out[1] = 0

    if( destopMode ){

      // fit artwork on the half left side of the screen

      if( frameAspect < viewportAspect*.5 ){
        
        // vp wider then frame, fit height
        out[2] = -.5*frameH / Math.tan( camera.lens._vfov/2 );

      } else {
        // vp higher then frame, fit width
        vpBaseSizeW =  frameW*2
        out[2] = -frameW / Math.tan( camera.lens._hfov/2 );
      }
      
      const ry = camera.lens._hfov /4
      quat.rotateY( rot, rot, -ry )
      
      
      
    } else {

      if( frameAspect < viewportAspect ){
        
        // vp wider then frame, fit height
        out[2] = -.5*frameH / Math.tan( camera.lens._vfov/2 );
        
      } else {
        // vp higher then frame, fit width
        out[2] = -.5*frameW / Math.tan( camera.lens._hfov/2 );
      }
      
      const rx = .05 * camera.lens._vfov
      quat.rotateX( rot, rot, -rx )
      // out[1] = (vpBaseSizeH - frameH ) /2

    }

    return -1
  }

  


  onTouchAdded = (touch: InputTouch) => {
    
    // if( Math.abs( this.slide - this.scene.xpcam.slide) < .5 ){
      const raycast = this.raycast( this.scene.camera, touch.coords );
      // console.log(raycast);
      
      if( raycast && raycast.inBounds ){
        this.touchSelectionCandidate = touch.id
      }
    // }
  }

  onTouchRemoved = (touch: InputTouch) => {
    if( touch.id === this.touchSelectionCandidate && touch.duration < 300 && touch.getDeltaLength() < .05 ){
      // if( Math.abs( this.slide - this.scene.xpcam.slide) < .5 ){
        const raycast = this.raycast( this.scene.camera, touch.coords );

        if( raycast && raycast.inBounds ){
          gallery.selectArtwork( this.index )  
        }
      // }
    }
  }


  raycast( camera:Camera, viewportPos:vec2) : FrameRaycastResult {
    
    ray.unproject( viewportPos, camera );
    plane.origin.set( this.ref._wposition )
    plane.normal.set( [
      this.ref._wmatrix[8],
      this.ref._wmatrix[9],
      this.ref._wmatrix[10]
    ]);


    if( rayPlaneIntersection( V3, ray, plane ) ){

      mat4.invert( INV_W, this.ref._wmatrix );

      const localPos : vec3 = vec3.create();
      vec3.transformMat4( localPos, V3, INV_W );

      const l = this.raycastExtent*.5
      const inBounds : boolean = Math.abs( localPos[0] ) < l && Math.abs( localPos[1] ) < l;

      const result :FrameRaycastResult = {
        worldPos : vec3.clone(V3),
        localPos,
        inBounds
      }

      return result;
    }

    return null;


  }

}
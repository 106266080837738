import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5c2b62f2&scoped=true&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&id=5c2b62f2&lang=stylus&scoped=true&"
import style1 from "./App.vue?vue&type=style&index=1&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2b62f2",
  null
  
)

export default component.exports

































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import Paths from '@/core/Paths'
import gsap from 'gsap'
import lottie, { AnimationItem } from 'lottie-web'

import gallery from '@/store/modules/gallery'
import viewport from '@/store/modules/viewport'

import GalleryTip from '@/components/GalleryTip/GalleryTip.vue'

@Component({
  components: {
    GalleryTip
  }
})
export default class GalleryIntro extends Vue {
  continueAnimation: AnimationItem
  video: HTMLVideoElement
  ended: boolean = false
  paused: boolean = true
  reachBreakTime: boolean = false
  resume: boolean = false
  suspended: boolean = false
  shouldPlay: boolean = false

  /**
   * time to pause the video : 3.58
   */
  @Prop({ type: Number, required: true }) breakTime!: number

  get videoSource(): string {
    const ratio: number = viewport.windowHeight / viewport.windowWidth
    if (ratio <= 1) {
      // is viewport landscape
      return Paths.resolve('./assets/videos/intro-desktop.mp4#t=0.1')
    } else {
      return Paths.resolve('./assets/videos/intro-mobile.mp4#t=0.1')
    }
  }

  get playing(): boolean {
    return !(this.paused || this.ended)
  }

  get moveHint(): boolean {
    return this.paused && this.reachBreakTime && gallery.moveHint
  }

  @Watch('playing')
  watchPlaying(playing) {
    gsap.ticker[playing ? 'add' : 'remove'](this.tick)
  }

  @Watch('ended')
  watchEnded(ended) {
    if (!ended) {
      return
    }
    gallery.setIntro(false)
  }

  mounted() {
    this.$nextTick(() => {
      this.video = this.$refs.video as HTMLVideoElement
      // this.play()

      this.loadContinueAnimation()
    })
  }

  loadContinueAnimation() {
    this.continueAnimation = lottie.loadAnimation({
      container: this.$refs.lottieContainer as Element,
      renderer: 'svg',
      autoplay: false,
      loop: false,
      path: Paths.resolve('./assets/lottie/enter-here.json')
    })
  }

  play() {
    this.shouldPlay = true

    this.$nextTick(() => {
      this.video &&
        this.video
          .play()
          .then(() => {
            this.suspended = false
          })
          .catch((error) => {
            console.log(error)
            this.suspended = true
            // gsap.ticker['add'](this.tick)
          })
    })
  }

  continueVideo() {
    if (!(this.paused && parseFloat(this.video.currentTime.toFixed(2)) === this.breakTime)) {
      return
    }
    this.resume = true
    this.play()
  }

  tick() {
    if (!this.video) {
      return
    }

    if (!this.resume && this.video.currentTime >= this.breakTime) {
      this.video.pause()
      this.video.currentTime = this.breakTime
      this.reachBreakTime = true
      this.continueAnimation.play()
    }
  }

  onStateChange() {
    this.paused = this.video.paused
    this.ended = this.video.ended
  }

  onCanPlayThrough() {
    gallery.setIntroCanPlayThrough(true)
  }

  leave(el, onComplete) {
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.to(this.$refs.flashlight, { opacity: 1, duration: 0.65, ease: 'quint.out' }, 'start')
    tl.to(el, { opacity: 0, duration: 2, ease: 'quint.out' }, '-=0.2')
  }
}

























import Vue from 'vue'
import Component from 'vue-class-component'
import gsap from 'gsap'

import wallpapers from '@/store/modules/wallpapers'
import viewport from '@/store/modules/viewport'

@Component
export default class WallpapersConfirmView extends Vue {
  backToSelection() {
    wallpapers.reset()
  }

  enter(el: Element, onComplete) {
    const backBtn: Element = el.querySelector('.back-btn')
    const dialog: Element = el.querySelector('.wallpapers-confirm-view-dialog')
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(el, { opacity: 0, duration: 0.85, ease: 'quint.out' }, 'start')
    tl.from(
      dialog,
      {
        transformOrigin: '100% 0%',
        rotate: '-15deg',
        yPercent: 100,
        opacity: 0,
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
    tl.from(
      backBtn,
      {
        opacity: 0,
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start+=0.4'
    )
  }

  leave(el, onComplete) {
    const backBtn: Element = el.querySelector('.back-btn')
    const dialog: Element = el.querySelector('.wallpapers-confirm-view-dialog')
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(
      backBtn,
      {
        opacity: 0,
        duration: 0.85,
        ease: 'quint.inOut'
      },
      'start'
    )
    tl.to(dialog, { opacity: 0, scale: 0.9, y: -viewport.windowHeight, duration: 0.85, ease: 'quint.out' }, 'start')
    tl.to(el, { opacity: 0, duration: 0.85, ease: 'quint.out' }, 'start')
  }
}


/////////////
//////////////////////////////////////////////
/////////////////////////////////////////////
/////////////////////////////////////////
///////////////////////////////////////////////
//////////////////////////////////////////////////

/////////////////////////////////////////////////////////
///////////
//////////


import GLState        from 'nanogl-state'
import Node           from 'nanogl-node'
import Camera         from 'nanogl-camera'
import Rect           from 'nanogl-primitives-2d/rect'

import Programs         from '@/webgl/gl/programs'
import IBLManager       from '@/webgl/gl/env'
import PostProcess      from '@/webgl/gl/post'
import Masks            from '@/webgl/gl/masks'
import Inputs           from '@/webgl/lib/inputs'
import Raycaster        from '@/webgl/lib/raycaster'
import Materials        from '@/webgl/assets/materials-lib'
import CamControler     from '@/webgl/camera/CameraController'
import OrbitControler   from '@/webgl/camera/XPController'
import MaxControler     from '@/webgl/camera/MaxController'
import Lens             from '@/webgl/camera/Lens'
import quality          from '@/webgl/lib/quality'
import Reflect          from '@/webgl/gl/Reflect'
import SceneInvalidator from '@/webgl/lib/scene-invalidator'
import GLView           from '@/webgl/glview'
import CameraControler  from '@/webgl/camera/CameraController'
import XPController     from '@/webgl/camera/XPController'

import { DEG2RAD } from '@/webgl/math'

import { GLContext } from 'nanogl/types'
import PerspectiveLens from 'nanogl-camera/perspective-lens'
import { mat4 } from 'gl-matrix'
import Deferred from '@/utils/Deferred'
import { Passes } from './glsl/Passes'
import PunctualLight from 'nanogl-pbr/lighting/PunctualLight'
import ScreenSize from './glsl/ScreenSize'
import Gallery from './gallery'

import resources from '@/store/modules/resources'



export default class Scene {
  
  dt: number
  time: number
  ratio: number
  ilayer      : Element
  loaddefer   : Deferred
  glview      : GLView
  gl          : GLContext
  mainCamera  : Camera<PerspectiveLens>
  devCamera   : Camera<PerspectiveLens>
  camera      : Camera<PerspectiveLens>
  sroot       : Node
  root        : Node
  glstate     : GLState
  quad        : Rect
  inputs      : Inputs
  invalidator : SceneInvalidator
  post        : PostProcess
  matlib      : Materials
  iblMngr     : IBLManager
  reflect     : Reflect
  programs    : Programs
  camCtrl     : CameraControler
  xpcam       : XPController
  maxcam      : MaxControler
  gallery     : Gallery



  
  
  raycaster      : any
  enableDebugDraw: boolean
  envRotation    : number
  forceFps       : any
  
  debugCam    : () => void
  mainCam     : () => void
  logDebugCam : () => void
  mipmapUsage : () => void
  ghostload : () => void
  
  
///////////////
////////////////////////////
////////////

  constructor() {

    this.dt     = 0   ;
    this.time   = 0   ;
    this.ratio  = 1.0 ;
    this.ilayer = null;

  }

  /**
   *
   * @param {import('glview').default} glview
   */
  init(glview : GLView) {

    this.loaddefer = new Deferred()

    this.glview = glview
    this.gl = this.glview.gl


    // CAMERA
    // ======
    this.mainCamera = this.makeCamera()
    this.devCamera  = this.makeCamera()
    this.camera     = this.mainCamera


    this.sroot = new Node();
    this.root  = new Node();

    this.glstate     = new GLState(this.gl    );
    this.quad        = new Rect   (this.gl    );
    this.inputs      = new Inputs (this.ilayer);
    this.invalidator = new SceneInvalidator();
    
    
    this.post        = new PostProcess( this );
    this.matlib      = new Materials  ( this );
    this.iblMngr     = new IBLManager ( this );
    this.raycaster   = new Raycaster  ( this );
    this.programs    = new Programs(this);
    this.reflect     = new Reflect    ( this );
    this.gallery     = new Gallery    ( this );
    
    
/////////////////
/////////////////////////////////////////
////////////////////////////////
//////////////



    this.envRotation = Math.PI

    // CONTROLERS
    // ======
    this.camCtrl = new CamControler  (this              )
    this.xpcam   = new OrbitControler(this              )
    this.maxcam  = new MaxControler  (this.glview.canvas)
    
    this.camCtrl.setControler(this.xpcam)
    this.invalidator.watchNode( this.mainCamera );
    this.invalidator.watchNode( this.devCamera );

    // GRAPH
    // ======
    this.root.add( this.mainCamera )
    this.root.add( this.devCamera )
    this.sroot.add( this.root )
    // this.root .add( this.tree      .node );

    // this.camCtrl.setControler(this.maxcam);

    this.inputs.start();


/////////////////
////////////////////////////////////

///////////////////////////////
////////////////////////////////////
/////////////////////////////////////////////////////////
//////////////////////////////////////////////
/////////////////////////
/////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////
///////////////
////////////////////////////////////////
/////////////////////////////
//////////////////////////////
////////////////////////////////
////////////////////////////
////////////////////////////////////
/////////////////////////////////////////

/////////////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////////////////////////////
////
////
///////////////////////////////////////////////
/////////////////////////////////////////////////////
////


//////////////

  }

  invalidate(){
    this.invalidator.invalidate();
  }

  handleResize(){
    this.invalidate();
  }


 
  render(dt) {
    
/////////////////
//////////////////////////////////
////////////
/////
/////////////////////////
//////////////
    
    this.dt = dt;
    this.time += dt;


    this.drawScene( this.camera );

  }



  drawScene( camera, fbo = null, force = false ){


    const gl = this.gl;
    const w = fbo ? fbo.width  : this.glview.width;
    const h = fbo ? fbo.height : this.glview.height;

    this.ratio = w / h;
    ScreenSize.setSize(w, h)



    // preRender
    // =============
    this.camCtrl.preRender();
    this.iblMngr.preRender();
    this.post   .preRender();


    this.gallery.preRender();


    // upadate graph
    // =================

    this.root.rotation.set([0, 0, 0, 1])
    this.root.rotateY(this.envRotation)
    this.sroot.updateWorldMatrix()


    camera.updateViewProjectionMatrix(w, h);
    
    
    if( !force && !this.invalidator.needRender() ) {
      return;
    }
    
    
    // RTT
    // ==========
    
    this.iblMngr.lights.setup.prepare(gl);
    this.renderLightmaps()

    this.gallery.rttPass()
    // this.glstate.apply()
    
    
    
    // REFLECT
    // =======
    
    if( quality.param('reflect') ){ 

      this.glstate.push( this.reflect.globalCfg )
      this.glstate.apply()
      
      this.reflect.processCamera( camera )
      this.reflect.prepare( w, h )
      
      this.iblMngr.setupForReflect()
      this.gallery.render( camera, Masks.REFLECTED_COLOR )
      this.gallery.render( camera, Masks.REFLECTED_BLENDED )
      this.iblMngr.restoreFromReflect()
      
      this.glstate.apply()
      gl.colorMask( false, false, false, true );
      gl.clear( gl.DEPTH_BUFFER_BIT )
      this.gallery.render( camera, Masks.REFLECTED_DEPTH, Passes.REFLECT_DEPTH);
      gl.colorMask( true, true, true, true );
      
      this.reflect.blitRenderBuffer();
      this.reflect.restoreCamera( camera );
      
      this.glstate.pop()
      
      this.reflect.processOutput()

      // fbodebug.debug( this.reflect.getOutputFbo() );
    }
    
    
    // RENDER
    // ========
    
    
    this.glstate.apply();
    gl.clearColor(1, 1, 1, 1);
    
    // this.post.post.preRender(w, h);
    // this.post.post.bindColor()


    gl.bindFramebuffer(gl.FRAMEBUFFER, fbo ? fbo.fbo : null);
    gl.viewport( 0, 0, w, h );
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT );
    
    //render stuffs
    this.gallery.render( camera, Masks.OPAQUE )
    this.gallery.render( camera, Masks.BLENDED )
    this.gallery.postRender()

    
    this.glstate.apply()
    // this.post.post.render()
    
    
/////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////////
///////////////////////////////
//////////////////////////////
/////////////////////////////
/////
///////////////////////////
//////////////


/////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////////

  }



  renderLightmaps() {
    const gl = this.gl;
    const lights = this.iblMngr.lights;
    const depthpass = lights.depthPass;
    const glstate = this.glstate;

    const isRgb = (lights.setup.depthFormat.value() === 'D_RGB');
    lights.depthCfg.colorMask(isRgb, isRgb, isRgb, isRgb);

    for (var l of lights.list) {
      const ld = l as PunctualLight
      if ( ld._castShadows) {
        ld.bindShadowmap()
        // fbodebug.debug( l._fbo );
        gl.clearColor(1, 1, 1, 1);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

        glstate.push(lights.depthCfg);

        // render
        this.gallery.render( ld._camera, Masks.SHADOW_CASTER, Passes.DEPTH )

        glstate.pop();

      }
    }
    glstate.apply();
  }



  async load() {
    // resources.increaseTotal(3)

    await this.iblMngr.loadDefault()
    resources.increaseProgress()
    await this.gallery.load()
    resources.increaseProgress()
    await this.preCompileMats()
    resources.increaseProgress()
    this.onLoaded()

  }



  preCompileMats = () => {
    this.iblMngr.lights.setup.prepare(this.gl);
    return this.matlib.compileAllAsync()
  }


  onLoaded = () => {
    // this.texlib.getGhostLoadables()
/////////////////
/////////////////////////////////////////
//////////////

  }




  makeCamera() {
    // const camera = Camera.makePerspectiveCamera()
    const camera = new Camera( new Lens() );
    camera.lens.setVerticalFov(.4) //80
    camera.lens.near = .1
    camera.lens.far = 100

    camera.setMatrix(<mat4> new Float32Array(
      // [0.9995434284210205, -1.396704476519517e-7, -0.03021577186882496, 0, -0.0026346975937485695, 0.9961907863616943, -0.08716089278459549, 0, 0.030100684612989426, 0.08720070123672485, 0.9957358837127686, 0, 0.45953, 6.27598, 25.63325, 1]
      [0.7439903020858765, -1.1964883128712245e-7, 0.6681898236274719, 0, 0.09012731909751892, 0.9908614754676819, -0.10035132616758347, 0, -0.6620836853981018, 0.1348825991153717, 0.7371914386749268, 0, -26.818082809448242, 8.28488826751709, 31.428661346435547, 1]
    ))

    return camera
  }

}





import {  mat4, vec3, vec4 } from "gl-matrix";
import Camera from "nanogl-camera";
import { Hotspot } from "@/store/modules/gallery-hotspots";
import Scene from "../scene";
import gallery from "@/store/modules/gallery";

const M4A = mat4.create()
const V4A = vec4.create()
const V3A = vec3.create()


export default class HotspotsProjector {


  static projectHotspots( list: Hotspot[], scene : Scene ): void {

    const camera = scene.camera
    const slide = gallery.position


    mat4.invert(M4A, scene.root._wmatrix)

    for (const h of list) {
      V4A.set( h.pos )
      V4A[3] = 1.0
      
      vec4.transformMat4(V4A, V4A, scene.root._wmatrix )
      vec4.transformMat4(V4A, V4A, camera._viewProj )
      
      h.screen.x = (V4A[0] / V4A[3]) * 0.5 + 0.5
      h.screen.y = -(V4A[1] / V4A[3]) * 0.5 + 0.5
      
      const inRange =  Math.abs( h.slide - slide) < .5 

      if( V4A[3] < 0 || ! inRange ){
        // behind cam, hide hotspot
        h.visible = false
      } else {
        h.visible = true

      }
    }
  }

  
}

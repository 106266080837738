

function _Throw( msg?: string ): void {
  throw new Error( msg );
}


type AssertType = {
  isTrue (condition: boolean, msg?: string): asserts condition is true
  isFalse(condition: boolean, msg?: string): asserts condition is false
  isDefined   <T>(o: T, msg?: string): asserts o is NonNullable<T>
  isNotDefined<T>(o: T, msg?: string): asserts o is NonNullable<T>
  isNotNull   <T>(o: T, msg?: string): asserts o is NonNullable<T>
  isNull      <T>(o: T, msg?: string): asserts o is NonNullable<T>
  isArray     <T>(o: T[], msg?: string): asserts o is T[]
  nonEmptyString( o: string, msg?: string ): asserts o is string 
}


const Assert: AssertType = {

  isTrue( condition: boolean, msg?: string ): asserts condition is true {
    !condition && _Throw( msg );
  },
  
  isFalse( condition: boolean, msg?: string ): asserts condition is false {
    condition && _Throw( msg );
  },
  
  isDefined<T>( o: T|null|undefined, msg?: string ): asserts o is NonNullable<T> {
    o ?? _Throw( msg );
  },
  
  isNotDefined<T>( o: T|null|undefined, msg?: string ): asserts o is null|undefined {
    if( o !== null && o !== undefined ) _Throw( msg );
  },
  
  isNotNull<T>( o: T|null, msg?: string ): asserts o is NonNullable<T> {
    ( o === null ) && _Throw( msg );
  },
  
  isNull<T>( o: T|null, msg?: string ): asserts o is NonNullable<T> {
    ( o !== null ) && _Throw( msg );
  },
  
  isArray( o: [], msg?: string ): asserts o is [] {
    ( Array.isArray(o) ) && _Throw( msg );
  },

  nonEmptyString( o: string, msg?: string ): asserts o is string {
    Assert.isDefined( o );
    if( typeof o !== 'string' ) _Throw( msg ) 
    if( o.length === 0 ) _Throw( msg ) 
  },
  
}

export default Assert;
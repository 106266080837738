























import Vue from 'vue'
import Component from 'vue-class-component'
import gsap from 'gsap'

import transition from '@/store/modules/transition'

@Component
export default class AppIntro extends Vue {
  show = true

  dispatchSkipIntro() {
    this.show = false
  }

  enter(el: Element, onComplete) {
    const vLines = Array.from(
      el.querySelectorAll('.background-line.background-line--right, .background-line.background-line--left')
    )
    const hLines = Array.from(
      el.querySelectorAll('.background-line.background-line--top, .background-line.background-line--bottom')
    )
    const tl: gsap.core.Timeline = gsap.timeline({ onComplete })
    tl.addLabel('start')
    tl.from(el, { opacity: 0, duration: 0.35 }, 'start')
    tl.from(
      hLines,
      {
        xPercent: (index) => {
          return index % 2 === 0 ? 100 : -100
        },
        stagger: 0.1,
        duration: 0.65,
        ease: 'quint.inOut'
      },
      'start+=0.35'
    )
    tl.from(
      vLines,
      {
        yPercent: (index) => {
          return index % 2 === 0 ? 100 * 3 : -100 * 3
        },
        stagger: 0.1,
        duration: 0.65,
        ease: 'quint.inOut'
      },
      'start+=0.55'
    )
    tl.from(
      this.$refs.logo,
      {
        opacity: 0,
        yPercent: 100,
        duration: 0.65,
        ease: 'quint.out'
      },
      'start+=0.55'
    )
    tl.from(
      this.$refs.body,
      {
        // transformOrigin: '0% 0%',
        rotate: '10deg',
        yPercent: 100,
        opacity: 0,
        duration: 0.65,
        ease: 'quint.out'
      },
      '-=0.5'
    )
    tl.from(
      (<Vue>this.$refs.btnSkip).$el,
      {
        // transformOrigin: '0% 0%',
        rotate: '10deg',
        yPercent: 100,
        opacity: 0,
        duration: 0.65,
        ease: 'quint.inOut'
      },
      '-=0.5'
    )
  }

  leave(el, onComplete) {
    const vLines = Array.from(
      el.querySelectorAll('.background-line.background-line--right, .background-line.background-line--left')
    )
    const hLines = Array.from(
      el.querySelectorAll('.background-line.background-line--top, .background-line.background-line--bottom')
    )
    const tl: gsap.core.Timeline = gsap.timeline({
      onComplete: () => {
        this.$store.dispatch('skipIntro', true)
        onComplete()
      }
    })
    tl.addLabel('start')
    tl.to(
      hLines,
      {
        xPercent: (index) => {
          return index % 2 === 0 ? 100 : -100
        },
        stagger: 0.1,
        duration: 0.65,
        ease: 'quint.in'
      },
      'start'
    )
    tl.to(
      vLines,
      {
        yPercent: (index) => {
          return index % 2 === 0 ? 100 * 3 : -100 * 3
        },
        stagger: 0.1,
        duration: 0.65,
        ease: 'quint.in'
      },
      'start+=0.1'
    )
    tl.to(
      [this.$refs.logo, this.$refs.body, (<Vue>this.$refs.btnSkip).$el],
      {
        opacity: 0,
        duration: 0.85,
        // ease: 'quint.inOut'
      },
      'start'
    )
    // tl.add(() => {
    //   transition.setOpen({ opened: false })
    // })
    // // fake transition, must be of AppTransition duration
    // tl.to(
    //   el,
    //   {
    //     opacity: 0,
    //     duration: 1,
    //     ease: 'none'
    //   }
    // )
  }
}








































































import Vue from 'vue'
import Component from 'vue-class-component'

import ageGate from '@/store/modules/age-gate'
import { AgeGateRule } from '@/store/modules/age-gate'

interface IInputAtts {
  min?: number
  max?: number
  minlength?: number
  maxlength?: number
  pattern?: string
  placeholder?: string
}

@Component
export default class AppAgeGate extends Vue {
  isSubmitted = false

  get ageGateRules(): AgeGateRule[] {
    return ageGate.orderedRules
  }

  get selectedAgeGateRule(): AgeGateRule {
    return ageGate.selectedRule
  }

  get selectedCountryInputType(): string[] {
    return ageGate.selectedCountryInputType
  }

  get hasLegalAge(): boolean {
    return ageGate.hasLegalAge
  }

  get isAllowedCountry(): boolean {
    return ageGate.isAllowedCountry
  }

  get hasFranceCountryCode(): boolean {
    return ageGate.countryCode === 'FR'
  }

  selectCountry(val: AgeGateRule) {
    ageGate.selectCountry((val && val.country_code) || '')
  }

  selectBirthdate(input: string, value: string) {
    const atts = this.getBirthdateAttributes(input)
    const $inputs = Array.from((<HTMLElement>this.$refs.birthdate).querySelectorAll('input'))
    const index = this.selectedCountryInputType.indexOf(input)

    // ensure maxlength is respected
    if (value.length > atts.maxlength) {
      value = value.slice(0, atts.maxlength)
    }
    // ensure value is in range
    if (parseInt(value) > atts.max) {
      value = atts.max.toString()
    }

    // restore input value
    $inputs[index].value = value

    if (this.validateBirthdate(input, value)) {
      const actions = {
        d: 'selectBirthDateDay',
        m: 'selectBirthDateMonth',
        y: 'selectBirthDateYear'
      }
      const action = actions[input]
      action && ageGate[action](parseInt(value))
    }

    // autofocus next input when length is reached
    if (value.length >= atts.maxlength) {
      $inputs[index + 1] && $inputs[index + 1].focus()
    }
  }

  getBirthdateAttributes(input: string): IInputAtts {
    const currentYear: number = new Date().getFullYear()
    const options = {
      d: {
        min: 1,
        max: 31,
        minlength: 1,
        maxlength: 2,
        pattern: '[0-9]{1,2}',
        placeholder: 'DD'
      },
      m: {
        min: 1,
        max: 12,
        minlength: 1,
        maxlength: 2,
        pattern: '[0-9]{1,2}',
        placeholder: 'MM'
      },
      y: {
        min: currentYear - 100,
        max: currentYear,
        minlength: 4,
        maxlength: 4,
        pattern: '[0-9]{4}',
        placeholder: 'YYYY'
      }
    }

    return options[input]
  }

  // getBirthdateValue(input: string): string {
  //   return ageGate.birthdate[input].toString()
  // }

  validateInput($event) {
    if (!/[0-9]/.test($event.key)) {
      if ($event.key !== 'Enter') {
        $event.preventDefault()
      }

      return false
    }

    return true
  }

  validateBirthdate(input: string, value: string) {
    const intValue = parseInt(value)
    const atts = this.getBirthdateAttributes(input)

    const inRange = intValue >= atts.min && intValue <= atts.max
    const hasLength = value.length >= atts.minlength && value.length <= atts.maxlength

    return inRange && hasLength
  }

  validateAge() {
    ageGate.validateLegalAge(this.hasLegalAge && !this.hasFranceCountryCode)
    this.isSubmitted = true

    if (!this.hasLegalAge || this.hasFranceCountryCode) {
      setTimeout(() => {
        window.location.replace(this.$t('age_gate.redirect_url') as string)
      }, 1000)
    }
  }
}
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { Resource } from '@/webgl/assets/Resource'
import FrontResources from '@/core/FrontResources'
import { RouteNames } from '@/views/Routes'
import gsap from 'gsap'
import SplitText from '@/utils/SplitText'
import { AnimationItem } from 'lottie-web'

import transition from '@/store/modules/transition'
import resources from '@/store/modules/resources'

@Component
export default class AppPreloader extends Vue {
  // progress: number = 0
  // total: number = 0
  loaded: boolean = false
  preloadCompleteAnimation: AnimationItem
  preloadingTL: gsap.core.Timeline

  get text(): string {
    const percent: number = (this.progress * 100) / this.total
    return `${percent < 10 ? '0' + percent.toFixed(0) : percent.toFixed(0)}%`
  }

  get progress(): number {
    return resources.progress
  }

  get total(): number {
    return resources.total
  }

  @Watch('progress')
  watchProgress(progress) {
    if (progress >= this.total) {
      this.loaded = true
    }
  }

  @Watch('loaded')
  watchLoaded(loaded) {
    if (loaded && this.preloadingTL && this.preloadingTL.totalProgress() >= 1) {
      this.dispatchLoadComplete()
    }
  }

  beforeDestroy() {
    this.preloadCompleteAnimation && this.preloadCompleteAnimation.destroy()
  }

  mounted() {
    // this.preloadCompleteAnimation = lottie.loadAnimation({
    //   container: this.$refs.completeAnimation as Element,
    //   renderer: 'svg',
    //   autoplay: false,
    //   loop: false,
    //   path: Paths.resolve('./assets/lottie/loading-complete.json')
    // })
    // this.preloadCompleteAnimation.addEventListener('complete', this.dispatchLoadComplete)

    const res = FrontResources.Group.collectResources()

    resources.increaseTotal(res.length + 3)
    res.map((v: Resource) => {
      v.response().then(() => {
        // console.log( 'loaded', v.url )
        this.onResourceLoaded()
      })
    })
  }

  onResourceLoaded() {
    resources.increaseProgress()
  }

  launchPreloadingAnimation() {
    gsap.set(<Element>this.$refs.title, { opacity: 1 })
    const splitTitle = new SplitText(<Element>this.$refs.title, { type: 'lines,words,chars' })
    this.preloadingTL = gsap.timeline({
      // paused: true,
      onComplete: () => {
        if (this.loaded) {
          // this.launchReadyAnimation()
          this.dispatchLoadComplete()
        }
      }
    })
    this.preloadingTL.addLabel('start')
    // this.preloadingTL.fromTo(this.$refs.logo, { opacity: 0 }, { opacity: 1 }, 'start')
    // this.preloadingTL.addLabel('switch', '+=2')
    // this.preloadingTL.fromTo(this.$refs.logo, { opacity: 1 }, { opacity: 0 }, 'switch')
    // this.preloadingTL.fromTo(this.$refs.title, { opacity: 0 }, { opacity: 1 }, 'switch')
    this.preloadingTL.fromTo(this.$refs.title, { opacity: 0 }, { opacity: 1 }, 'start')
    this.preloadingTL.fromTo(
      splitTitle.chars,
      {
        yPercent: 100,
        opacity: 0
      },
      {
        yPercent: 0,
        opacity: 1,
        stagger: 0.03,
        duration: 0.85,
        snap: {
          opacity: 1
        },
        ease: 'quint.out'
      },
      'start'
    )
  }

  launchReadyAnimation() {
    const tl: gsap.core.Timeline = gsap.timeline()
    tl.addLabel('start')
    tl.to(this.$refs.loadingProgress, { opacity: 0, duration: 0.35, ease: 'quint.out' }, 'start')
    tl.to(this.$refs.loadingComplete, { opacity: 1, duration: 0.35, ease: 'quint.out' }, 'start')
    tl.add(() => {
      this.preloadCompleteAnimation.play()
      // this.dispatchLoadComplete()
    }, 'start')
  }

  enter(el: Element, onComplete) {
    const tl: gsap.core.Timeline = gsap.timeline({
      onComplete
    })
    tl.from(el, { opacity: 0 })
  }

  afterEnter() {
    this.launchPreloadingAnimation()
    // this.emulateLoading()
  }

  leave(el: Element, onComplete) {
    const tl: gsap.core.Timeline = gsap.timeline({
      onComplete
    })
    tl.addLabel('start')
    tl.to(this.$refs.bgLogo, { opacity: 0, scale: 0.64, duration: 0.85, ease: 'quint.in' }, 'start')
    tl.to(this.$refs.inner, { opacity: 0, duration: 0.85, ease: 'quint.in' }, 'start')
    tl.add(() => {
      if (this.$store.state.skipIntro) {
        transition.setOpen({ opened: false })
      }
    })
    tl.to(el, { opacity: 0 })
  }

  // emulateLoading() {
  //   const tl: gsap.core.Timeline = gsap.timeline({
  //     onComplete: () => {
  //       this.loaded = true
  //     }
  //   })
  //   tl.fromTo(
  //     this.preloadPercent,
  //     { progress: 0 },
  //     { progress: 100, snap: { progress: 1 }, duration: 6, ease: 'quint.out' }
  //   )
  // }

  dispatchLoadComplete() {
    this.$store.dispatch('onLoad', true)
  }
}

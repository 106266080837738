/*
 * register components globally
 */

import Vue from 'vue'
import UIBtn from './UIBtn/UIBtn.vue'
import UIBtnIcon from './UIBtnIcon/UIBtnIcon.vue'
import UIBtnLink from './UIBtnLink/UIBtnLink.vue'
import UIBtnRound from './UIBtnRound/UIBtnRound.vue'
import UIIcon from './UIIcon/UIIcon.vue'
import UIAudioBtn from './UIAudioBtn/UIAudioBtn.vue'
import UINavBtn from './UINavBtn/UINavBtn.vue'
import UIBackBtn from './UIBackBtn/UIBackBtn.vue'
import UIShareBtn from './UIShareBtn/UIShareBtn.vue'
import UICursor from './UICursor/UICursor.vue'
import UISlider from './UISlider/UISlider.vue'
import UINewsletterSection from './UINewsletterSection/UINewsletterSection.vue'
import UIHint from './UIHint/UIHint.vue'
import UICookieSettingsBtn from './UICookieSettingsBtn/UICookieSettingsBtn.vue'


Vue.component('ui-icon', UIIcon)
Vue.component('ui-btn', UIBtn)
Vue.component('ui-btn-icon', UIBtnIcon)
Vue.component('ui-btn-link', UIBtnLink)
Vue.component('ui-btn-round', UIBtnRound)
Vue.component('ui-audio-btn', UIAudioBtn)
Vue.component('ui-nav-btn', UINavBtn)
Vue.component('ui-back-btn', UIBackBtn)
Vue.component('ui-share-btn', UIShareBtn)
Vue.component('ui-cursor', UICursor)
Vue.component('ui-slider', UISlider)
Vue.component('ui-newsletter-section', UINewsletterSection)
Vue.component('ui-hint', UIHint)
Vue.component('ui-cookie-settings-btn', UICookieSettingsBtn)
